const addressDefaults = {
  street1: '',
  street2: '',
  city: '',
  zip: '',
  state: '',
  shippingMethod: '',
}

const errorDefaults = {
  street1: false,
  street2: false,
  city: false,
  zip: false,
  state: false,
  shippingMethod: false,
}

export { addressDefaults, errorDefaults }

import React, { createContext, useContext, useState } from 'react'

const SiteContext = createContext(undefined)

const initializeSiteState = () => ({
  renderOldFooter: true,
})

export const SiteProvider = ({ children }) => {
  const [siteState, setSiteState] = useState(initializeSiteState())

  const value = { siteState, setSiteState }
  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}

export const useSite = () => {
  const siteData = useContext(SiteContext)

  if (!siteData) {
    throw new Error('useSite must be used inside a SiteProvider')
  }

  return siteData
}

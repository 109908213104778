import React, { Fragment } from 'react'
import { Query } from 'react-apollo'

import { ClientGetSelectedMedicationAndCheckoutDetails, ClientGetResponseData } from '../../graphql/queries'
import BackButton from './BackButton'

const mainHeader = (errorName, ccProcessorError) => {
  let headingText = 'Credit Card Problem'
  if (errorName) {
    headingText = errorName
    return headingText
  }
  if (ccProcessorError === 'true') {
    headingText = 'Whoops, a Technical Error'
    return headingText
  }
  return headingText
}

const subHeader = (sslResultMessage, errorMessage) => {
  let subheadingText =
    'There was a problem with our web site. We regret this inconvenience. Please contact customer service.'
  if (errorMessage) {
    subheadingText = errorMessage + ' Try a different credit card?'
    return subheadingText
  }
  if (sslResultMessage) {
    subheadingText = `Your credit card company says '${sslResultMessage}'. Try a different credit card?`
    return subheadingText
  }
  return subheadingText
}

const PaymentFailed = props => {
  return (
    <div className="order-complete__container">
      <Query query={ClientGetResponseData}>
        {({ data }) => {
          const { ppCcProcessorError, ppCcSslResultMessage, ppCcErrorName, ppCcErrorMessage } = data

          const headingText = mainHeader(ppCcErrorName, ppCcProcessorError)
          const subheadingText = subHeader(ppCcSslResultMessage, ppCcErrorMessage)
          return (
            <Fragment>
              <h2 className="order-complete__heading">{`${headingText}`}</h2>
              <h3 className="order-complete__subheading">{`${subheadingText}`}</h3>
            </Fragment>
          )
        }}
      </Query>
      <br />
      <Query query={ClientGetSelectedMedicationAndCheckoutDetails}>
        {({ data }) => {
          if (data.chkoutSelectedMedication) {
            const mid = data.chkoutSelectedMedication.id
            return <BackButton to={{ pathname: '/checkout/payment_info', search: `?mid=${mid}` }} />
          }
          return <BackButton to="/medications" />
        }}
      </Query>
    </div>
  )
}

export default PaymentFailed

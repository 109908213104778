import React, { Component } from 'react'

const questions = [
  [
    'Do I need a prescription?',
    'You won’t need your prescription information to complete your enrollment today.\nIf you have an existing prescription, we will reach out to your current pharmacy to transfer the prescription to Truepill at no extra charge. This normally takes 1-3 business days. If you need a new prescription we will reach out directly to your doctor to obtain one. If your doctor for some reason does not renew your prescription our customer support team will reach out to let you know.\nIf you have any questions, please call our customer care team at 1-833- 860-1057.',
  ],
  [
    'How do refills work?',
    'We will reach out to you 7-8 days before you run out of medications to make sure you still want your next refill. You can 1-click to confirm and your medication will be shipped out same day. If you have no refills remaining on your prescription we will reach out to your doctor to obtain a new prescription.',
  ],
  [
    'How long will it take to receive my medication?',
    'If you have an existing prescription at another pharmacy we will reach out to transfer it to Truepill. This process typically takes 1-3 business days and we will notify you as soon as we have received it from your pharmacy. Once we have your prescription on file, your order will be shipped same day and we will provide you a tracking URL to follow the progress of the order. We deliver for free using USPS First Class Mail which typically takes 3-5 days and you can always upgrade to Priority Mail.',
  ],
  [
    'Where do you ship?',
    'Truepill is a licensed U.S. pharmacy and ships to all 50 states and Washington DC. We currently do not ship to Puerto Rico and other U.S. territories.',
  ],
]

class MedicationFAQQuestion extends Component {
  state = { expanded: false }

  handleClick = e => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { expanded } = this.state
    const { answer, question } = this.props
    const answers = answer.split('\n').map((answer, i) => (
      <p key={i} className="medication-faq-question__answer-text">
        {answer}
      </p>
    ))

    return (
      <div className={`medication-faq-question__container ${expanded ? 'expanded' : ''}`} onClick={this.handleClick}>
        <div className="medication-faq-question__header-container">
          <h3 className={`medication-faq-question__question-header ${expanded ? 'expanded' : ''}`}>
            <div className="medication-faq-question__question-header-text">{question}</div>
          </h3>
          <div className={`medication-faq-question__header-arrow ${expanded ? 'expanded' : ''}`} />
        </div>
        {expanded ? answers : null}
      </div>
    )
  }
}

const MedicationFAQs = props => (
  <div className="medication-faqs__container">
    <h2 className="medication-faqs__header">FAQs</h2>
    {questions.map((question, i) => (
      <MedicationFAQQuestion key={i} question={question[0]} answer={question[1]} />
    ))}
  </div>
)

export default MedicationFAQs

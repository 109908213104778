import React from 'react'
import styled from 'styled-components'

const ConsentBox = ({ register, errors }) => (
  <ConsentContainer>
    <input {...register('consent')} type="checkbox" id="consent" />
    <ConsentLabel consentError={errors.consent} htmlFor="consent">
      I consent for a fill of the aforementioned medications and copay amount.
    </ConsentLabel>
  </ConsentContainer>
)

const ConsentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem 0;
  padding-left: 2rem;
`

const ConsentLabel = styled.label`
  margin-left: 0.5rem;
  font-family: CircularStd;

  ${({ consentError }) =>
    consentError &&
    `
    color: red;
  `}
`

export default ConsentBox

import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'

import { combineSearchStrings } from '../../lib'

export default ({ children, disabled, handleClick, to, submit }) => {
  const { search } = useLocation()

  return (
    <Button
      className={`${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
      to={{
        ...(typeof to === 'string' ? { pathname: to } : to),
        search: combineSearchStrings(search, to.search),
      }}
    >
      <ButtonText className={`${disabled ? 'disabled' : ''}`}>
        {submit ? <>Submit</> : children || 'Continue'}
      </ButtonText>
    </Button>
  )
}

const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 377px;
  height: 75px;
  border-radius: 55px;
  box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.13);
  background-color: #44bdd0;
  text-align: center;
  outline: none;
  transition: all 0.2s ease-in;
  border: none;
  &:disabled {
    background-color: #2da2b4;
    opacity: 0.8;
  }
  &:not(:disabled):hover {
    background-color: #2da2b4;
    cursor: pointer;
  }
  @media screen and (max-width: 767.99px) {
    width: 232px;
    height: 52px;
  }
`

const ButtonText = styled.span`
  position: relative;
  bottom: 1px;
  font-family: CircularStd;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.4px;
  color: #ffffff;
  &:disabled {
    color: #838383;
    opacity: 0.8;
  }
  @media screen and (max-width: 767.99px) {
    font-size: 22px;
    letter-spacing: -0.3px;
  }
`

import styled from 'styled-components'

export default styled.div`
  margin: 0 auto;
  text-align: center;

  img {
    max-width: 75vw;
  }
`

import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Text, Spacer, TextField } from '@truepill/react-capsule'
import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements } from '@stripe/react-stripe-js'

import { device } from '../../global/vars'
import { parseTextFieldStateForCapsule } from '../../helpers'
import { CheckmarkIcon } from '../../../assets'
import { WebTooltip, MobileTooltip } from '../tooltip'
import { ZipcodeInput } from '../input'

const cvvInfoText = 'For most cards, the CVV is the last three digits on the back of your card.\n\r\n\rFor American Express, it is the 4 digits on the front of your card.'
const PaymentInfoCard = ({
  register,
  errors,
  dirtyFields,
  setValue,
  handleStripeChange,
  handleStripeFocusChange,
  stripeState,
}) => {
  const CARD_FORM_KEYS = {
    CARD_NUMBER_COMPLETED: 'cardNumberCompleted',
    CARD_CVV_COMPLETED: 'cardCvvCompleted',
    CARD_EXPIRY_COMPLETED: 'cardExpiryCompleted',
  }

  const commonStripeStyles = {
    fontSize: '16px',
    fontFamily: 'lato',
    fontWeight: '400',
  }

  const CARD_NUMBER_STYLES = {
    placeholder: '1234 5678 9012 3456',
    style: {
      base: {
        ...commonStripeStyles,
      },
    },
  }

  const CARD_EXPIRATION_STYLES = {
    placeholder: '02/20',
    style: {
      base: {
        ...commonStripeStyles,
      },
    },
  }

  const CARD_CVV_STYLES = {
    placeholder: 'CVV',
    style: {
      base: {
        ...commonStripeStyles,
      },
    },
  }

  const [isCardNumberCompleted, setIsCardNumberCompleted] = useState(false)
  const [isCardCvcCompleted, setIsCardCvcCompleted] = useState(false)
  const [isCardExpiryCompleted, setIsCardExpiryCompleted] = useState(false)
  const elements = useElements()

  useEffect(() => {
    elements.getElement(CardNumberElement).on('change', e => setIsCardNumberCompleted(e.complete))
    elements.getElement(CardCvcElement).on('change', e => setIsCardCvcCompleted(e.complete))
    elements.getElement(CardExpiryElement).on('change', e => setIsCardExpiryCompleted(e.complete))
  }, [elements])

  return (
    <Fragment>
      <PaymentCardInput>
        <Text bold>Card holder</Text>
        <TextField
          type="string"
          placeholder="Cardholder name"
          state={parseTextFieldStateForCapsule(errors.cardholder, dirtyFields.cardholder)}
          {...register('cardholder')}
        />
      </PaymentCardInput>
      <Spacer size="md" axis="vertical" />
      <PaymentCardInput>
        <Text bold>Card number</Text>
        <StripeElementContainer
          isFocus={stripeState.cardNumberFocus}
          error={errors[CARD_FORM_KEYS.CARD_NUMBER_COMPLETED]}
          isComplete={isCardNumberCompleted}
        >
          <StyledField>
            <CardNumberElement
              onChange={e => handleStripeChange(e, CARD_FORM_KEYS.CARD_NUMBER_COMPLETED)}
              onBlur={() => handleStripeFocusChange('cardNumberFocus', false)}
              onFocus={() => handleStripeFocusChange('cardNumberFocus', true)}
              options={CARD_NUMBER_STYLES}
            />
          </StyledField>
          <img alt="card number" src={CheckmarkIcon} />
        </StripeElementContainer>
      </PaymentCardInput>
      <Spacer size="md" axis="vertical" />
      <CardElementContainer>
        <PaymentCardInput>
          <Text bold>Exp. Date</Text>
          <StripeElementContainer
            isFocus={stripeState.cardExpiryFocus}
            error={errors[CARD_FORM_KEYS.CARD_EXPIRY_COMPLETED]}
            isComplete={isCardExpiryCompleted}
          >
            <StyledField>
              <CardExpiryElement
                onChange={e => handleStripeChange(e, CARD_FORM_KEYS.CARD_EXPIRY_COMPLETED)}
                options={CARD_EXPIRATION_STYLES}
                onBlur={() => handleStripeFocusChange('cardExpiryFocus', false)}
                onFocus={() => handleStripeFocusChange('cardExpiryFocus', true)}
                placeholder="05/20"
              />
            </StyledField>
            <img alt="card expiry" src={CheckmarkIcon} />
          </StripeElementContainer>
        </PaymentCardInput>
        <PaymentCardInput>
          <StyledLabelContainer>
            <Text bold>CVV</Text>
            <WebTooltip text={cvvInfoText} position="right" variant="white" />
            <MobileTooltip text={cvvInfoText} />
          </StyledLabelContainer>
          <StripeElementContainer
            isFocus={stripeState.cardCvvFocus}
            error={errors[CARD_FORM_KEYS.CARD_CVV_COMPLETED]}
            isComplete={isCardCvcCompleted}
          >
            <StyledField>
              <CardCvcElement
                onChange={e => handleStripeChange(e, CARD_FORM_KEYS.CARD_CVV_COMPLETED)}
                onBlur={() => handleStripeFocusChange('cardCvvFocus', false)}
                onFocus={() => handleStripeFocusChange('cardCvvFocus', true)}
                options={CARD_CVV_STYLES}
              />
            </StyledField>
            <img alt="card cvv" src={CheckmarkIcon} />
          </StripeElementContainer>
        </PaymentCardInput>
        <PaymentCardInput>
          <Text bold>ZIP code</Text>
          <ZipcodeInput
            placeholder="12345"
            state={parseTextFieldStateForCapsule(errors.zipcode, dirtyFields.zipcode)}
            name="zipcode"
            register={register}
            setValue={setValue}
          />
        </PaymentCardInput>
      </CardElementContainer>
      <Spacer size="md" axis="vertical" />
    </Fragment>
  )
}

const CardElementContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr 5fr;
  grid-column-gap: 0.5rem;

  @media ${device.laptop} {
    grid-column-gap: 1rem;
  }
`

const PaymentCardInput = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0.5rem;
  }

  img {
    height: 1.25rem;
  }
`

const StripeElementContainer = styled.div`
  border: 1px solid hsla(219, 18%, 37%, 1);
  border-radius: 8px;
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding: 1.025rem 0.75rem;
  outline: none;

  > img {
    display: none;
    height: 24px;
    width: 24px;
  }

  ${({ isComplete }) =>
    isComplete &&
    `
    > img {
      display: flex;
    }
  `}

  ${({ error }) =>
    error &&
    `
    border: 1px solid #d42121;
    background: #fff0f0;
  `}

  ${({ isFocus }) =>
    isFocus &&
    `
    border: 3px solid #0076d1;
  `}

  ${({ error, isFocus }) => error && isFocus && 'border: 3px solid #d42121;'}
`
const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`

const StyledField = styled.div`
  width: 100%;
`

export default PaymentInfoCard

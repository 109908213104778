import React, { useContext } from 'react'

import { EmployerContext } from '../../lib'

const renderEmployerEmailPhone = (phone, email, employerName) => {
  const mailToEmail = employerName ? `mailto:${email}?subject=${employerName}` : `mailto:${email}`
  if (phone && email) {
    return (
      <>
        <a href={`tel:${phone}`}>{phone}</a> or <a href={mailToEmail}>{email}</a>
      </>
    )
  }

  if (email) {
    return <a href={mailToEmail}>{email}</a>
  }

  return <a href={`tel:${phone}`}>{phone}</a>
}

const CheckoutQuestions = () => {
  const {
    employer: { email, phone, employerName },
  } = useContext(EmployerContext)

  return (
    <div className="checkout__questions-container">
      <h3 className="checkout__questions-header">Questions?</h3>
      <p className="checkout__questions-text">If you have any questions please contact us</p>
      <p className="checkout__questions-text">at {renderEmployerEmailPhone(phone, email, employerName)}</p>
    </div>
  )
}

export default CheckoutQuestions

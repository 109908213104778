import { createGlobalStyle } from 'styled-components'
// import { breakpoint, color } from './variables'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    line-height: normal;
  }

  body {
    font-family: CircularStd;
  }

  h1 {
    font-size: 42px;
    font-weight: 600;
  }

  h2 {
    font-size: 32px;
    font-weight: 500;
  }
`

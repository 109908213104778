import React from 'react'

const Testimonial = ({ icon, quote, name }) => (
  <React.Fragment>
    <div className="testimonial__container">
      <img className="testimonial__icon" src={icon} alt="" />
      <div className="testimonials__quote-container">
        <div className="testimonials__quote">{quote}</div>
      </div>
    </div>
    <div className="testimonials__name-location">{name}</div>
  </React.Fragment>
)

export default Testimonial

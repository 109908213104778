import React from 'react'
import styled from 'styled-components'

import LoadingSpinner from './LoadingSpinner'

const LoadingOverlayWrapper = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`

/**
 * This blanks out the whole page and displays just a loading spinner. It's a
 * simple but somewhat hacky workaround for branding-still-loading issues.
 *
 * @type {import('react').FunctionComponent<{}>}
 */
const LoadingOverlay = () => (
  <LoadingOverlayWrapper>
    <LoadingSpinner />
  </LoadingOverlayWrapper>
)
LoadingOverlay.displayName = 'LoadingOverlay'
export default LoadingOverlay

export const SECTION_NAMES = {
  PATIENT_IDENTITY: 'patientIdentity',
  RELATIONSHIP_CONFIRMATION: 'relationshipConfirmation',
  PATIENT_INSURANCE: 'patientInsurance',
  PATIENT_UPLOAD: 'patientUpload',
  PATIENT_URAC: 'patientUrac',
  CONFIRM_PRESCRIPTIONS: 'confirmPrescriptions',
  CONFIRM_NO_PRESCRIPTIONS: 'confirmNoPrescriptions',
  PATIENT_SHIPPING: 'patientShipping',
  REVIEW_INSURANCE: 'reviewInsurance',
  PATIENT_PAYMENT: 'patientPayment',
  CONFIRM_ORDER: 'confirmOrder',
  ORDER_CONFIRMATION: 'orderConfirmation',
  ORDER_REJECTED: 'orderRejected',
}

export const PAGE_TITLE = {
  [SECTION_NAMES.PATIENT_IDENTITY]: 'Identity Verification',
  [SECTION_NAMES.RELATIONSHIP_CONFIRMATION]: 'Patient Relationship Confirmation',
  [SECTION_NAMES.PATIENT_INSURANCE]: 'Insurance',
  [SECTION_NAMES.PATIENT_UPLOAD]: 'Government ID Upload',
  [SECTION_NAMES.PATIENT_URAC]: 'Patient Health Survey',
  [SECTION_NAMES.CONFIRM_PRESCRIPTIONS]: 'Select Medications',
  [SECTION_NAMES.CONFIRM_NO_PRESCRIPTIONS]: 'Select Medications',
  [SECTION_NAMES.PATIENT_SHIPPING]: 'Shipping',
  [SECTION_NAMES.REVIEW_INSURANCE]: 'Review Insurance',
  [SECTION_NAMES.PATIENT_PAYMENT]: 'Payment',
  [SECTION_NAMES.CONFIRM_ORDER]: 'Confirm Order',
  [SECTION_NAMES.ORDER_CONFIRMATION]: 'Order Confirmation',
  [SECTION_NAMES.ORDER_REJECTED]: 'Order Rejected',
}

export const SHIPPING_METHODS = {
  // Standard
  USPS_FIRST: 'usps_first',
  USPS_PRIORITY: 'usps_priority',
  UPS_NEXT_DAY_AIR: 'ups_next_day_air',
  UPS_NEXT_DAY_AIR_NON_CONTIGUOUS_STATES: 'ups_next_day_air_non_continguous_state',
  // For Controlled Substance Orders
  USPS_EXPRESS: 'usps_priority_express',
  FEDEX_TWO_DAY: 'fedex_2_day',
}

export const SHIPPING_SPEED = {
  [SHIPPING_METHODS.USPS_FIRST]: '(3-5 business days)',
  [SHIPPING_METHODS.USPS_PRIORITY]: '(1-3 business days)',
  [SHIPPING_METHODS.USPS_EXPRESS]: '(2 business day)',
  [SHIPPING_METHODS.FEDEX_TWO_DAY]: '(2 business day)',
  [SHIPPING_METHODS.UPS_NEXT_DAY_AIR]: '(1 business day)',
  [SHIPPING_METHODS.UPS_NEXT_DAY_AIR_NON_CONTIGUOUS_STATES]: '(1 business day)'
}

export const SHIPPING_PRICE = {
  // Default Shipping
  [SHIPPING_METHODS.USPS_FIRST]: 0,
  [SHIPPING_METHODS.USPS_PRIORITY]: 10,
  // UPS overnight by default is 27, unless it is Hawaii or Alaska
  [SHIPPING_METHODS.UPS_NEXT_DAY_AIR]: 27,
  [SHIPPING_METHODS.UPS_NEXT_DAY_AIR_NON_CONTIGUOUS_STATES]: 50,
  // Controlled substance orders
  [SHIPPING_METHODS.USPS_EXPRESS]: 0,
  [SHIPPING_METHODS.FEDEX_TWO_DAY]: 0,
}

export const SHIPPING_METHOD_NAME = {
  [SHIPPING_METHODS.USPS_FIRST]: 'Standard',
  [SHIPPING_METHODS.USPS_PRIORITY]: 'Expedited',
  [SHIPPING_METHODS.UPS_NEXT_DAY_AIR]: 'Overnight',
  [SHIPPING_METHODS.UPS_NEXT_DAY_AIR_NON_CONTIGUOUS_STATES]: 'Overnight',
  [SHIPPING_METHODS.USPS_EXPRESS]: 'Express',
  [SHIPPING_METHODS.FEDEX_TWO_DAY]: 'Express',
}
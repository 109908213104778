import { useContext } from 'react'
import useSWR from 'swr'

import { API_PATH } from '../config'
import { PBM_IDS, UNIQUE_EMPLOYER_NAMES_BY_PBM } from '../constants'
import EmployerContext from '../lib/Context/EmployerContext'
import useQueryStringValue from './use-query-string-value'

/**
 * @typedef PatientResponse
 * @type {object}
 * @property {object} insurance
 * @property {string=} insurance.cardholder_id
 * @property {string=} insurance.rx_bin
 * @property {string=} insurance.rx_group
 * @property {string=} insurance.rx_pcn
 * @property {object} patient
 * @property {string=} patient.first_name
 * @property {string=} patient.last_name
 * @property {string=} patient.sex
 * @property {string=} patient.dob
 * @property {string=} patient_email
 * @property {string=} patient_phone
 * @property {string=} pharmacy_name
 * @property {string=} pharmacy_phone
 * @property {string=} transfer_medications
 */

/**
 * Fetch the data for the corresponding patient from the patient-api endpoint.
 * With a long debounce time here, we can call this multiple times in the same
 * browser session and get the same response back without multiple calls
 * happening.
 *
 * This is meant to be used generically, even though we only have the RX Savings
 * handling for now. Components can check `isValidating` in the return value
 * to know whether to wait. If `isValidating` is false and `data` is undefined,
 * then either there's no matching data on the backend, or there's no patient
 * data to use in the first place.
 *
 * @returns {import('swr').responseInterface<PatientResponse, any>}
 */
export default function useGetPatientData() {
  const {
    employer: { employerName },
  } = useContext(EmployerContext)
  const rxSavingsPayload = useQueryStringValue('rxssPayload')

  const rxSavingsPatient = useSWR(
    () =>
      rxSavingsPayload && employerName === UNIQUE_EMPLOYER_NAMES_BY_PBM[PBM_IDS.RX_SAVINGS]
        ? `${API_PATH}/patients/rx-savings/${encodeURIComponent(rxSavingsPayload)}`
        : null,
    {
      dedupingInterval: Infinity,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  switch (employerName) {
    case UNIQUE_EMPLOYER_NAMES_BY_PBM[PBM_IDS.RX_SAVINGS]:
      return rxSavingsPatient

    default:
      return { isValidating: false, mutate: () => Promise.resolve(), revalidate: () => Promise.resolve(true) }
  }
}

import React from 'react'
import { useController } from 'react-hook-form'
import styled from 'styled-components'
import { Select } from '@truepill/react-capsule'

import { parseTextFieldStateForCapsule } from '../../helpers'

const SelectInput = ({ options, control, name, placeholder, label, required = false }) => {
  const {
    field: { onChange, value },
    fieldState: { isDirty, error },
  } = useController({
    name,
    control,
  })

  return (
    <SelectContainer>
      <Select
        selectedKey="value"
        options={options}
        placeholder={placeholder}
        onChange={item => onChange(item.value)}
        state={parseTextFieldStateForCapsule(error, isDirty)}
        value={options.find(item => item.value === value)}
        label={label}
        required={required}
      />
    </SelectContainer>
  )
}

const SelectContainer = styled.div``

export default SelectInput

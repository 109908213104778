import React from 'react'
import styled from 'styled-components/macro'

import './WelcomeMessage.scss'

import close from '../../assets/images/close-v2.svg'

const WelcomeMessages = {
  calibrate: name => (
    <>Welcome, {name}! Please confirm you’re ready for your Calibrate medications to ship in 1-3 business days by</>
  ),
}

const AdditionalMessages = {
  calibrate: () => (
    <>
      If you are receiving Wegovy, Ozempic, or Saxenda, these medications must be refrigerated. If you are unable to
      receive them in the next 1-3 business days, do not <MobileWord>tap</MobileWord>
      <DesktopWord>click</DesktopWord> “continue” now. Return to this page when you will be available to receive and
      promptly refrigerate your medication.
    </>
  ),
}

const renderWelcomeMessage = (customWelcomeMessage, name) => {
  switch (customWelcomeMessage) {
    case 'calibrate':
      return WelcomeMessages.calibrate(name)
    default:
      return `Welcome ${name}! Please confirm the medications you would like shipped by`
  }
}

const WelcomeMessage = ({ name, isOpen, handler, customWelcomeMessage }) => {
  return (
    <div className={`welcome-message ${isOpen ? null : 'welcome-message--collapsed'}`}>
      <div className="welcome-message--text-wrapper">
        <div className="welcome-message--text">
          {renderWelcomeMessage(customWelcomeMessage, name)} <span className="welcome-message__instr-lg">clicking</span>
          <span className="welcome-message__instr-mobile">tapping</span> below.
          {customWelcomeMessage === 'calibrate' && <> {AdditionalMessages.calibrate()}</>}
        </div>
      </div>
      <img alt="Close Welcome Message" className="welcome-message--close" src={close} onClick={() => handler()} />
    </div>
  )
}

const MobileWord = styled.span`
  display: inline-block;

  @media (min-width: 769px) {
    display: none;
  }
`

const DesktopWord = styled.span`
  display: none;

  @media (min-width: 769px) {
    display: inline-block;
  }
`

export default WelcomeMessage

import React from 'react'
import currency from 'currency.js'

import './MedicationContainer.scss'

import UncheckedMark from '../../assets/images/mark-unchecked.png'
import CheckedMark from '../../assets/images/mark-checked.png'

import medPlaceholder from '../../assets/images/med-placeholder.jpg'

import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

const MedicationContainer = props => {
  const { details, type, parent, parentIndex, selectable } = props
  const { patient_confirmed } = details
  const toggleMed = () => {
    const currentMeds = [...parent.state.currentMeds]
    currentMeds[parentIndex].patient_confirmed = !patient_confirmed
    parent.setState({ currentMeds })
  }
  return (
    <div
      className={`medication__container ${selectable ? 'selectable' : ''} ${
        patient_confirmed && type ? 'medication__container--mobile-highlight' : null
      }`}
      onClick={selectable && patient_confirmed !== null ? toggleMed : null}
    >
      <div className="medication__img-container">
        <img alt={`Medication ${startCase(toLower(details.medication_name))}`} src={medPlaceholder} />
      </div>
      <div className="medication__details-container">
        <h3>{startCase(toLower(details.medication_name))}</h3>
        {details.copay_price ? <h3>{currency(details.copay_price).format()} (copay price)</h3> : null}
        <ul>
          <li>
            Quantity: {details.quantity} <span className="medication-details--dot-separator">•</span> Days Supply:{' '}
            {details.days_supply}
          </li>
          {type === 'future' ? (
            <li>Refill Available: {new Date(details.next_fill_date).toLocaleDateString('en-US')}</li>
          ) : null}
          <li>Refills left: {details.num_refills_remaining || 0}</li>
        </ul>
      </div>
      {type === 'confirmed' ? (
        <div className="medication__checkbox-container">
          <img
            alt="checkmark"
            className="medication__checkmark-img"
            src={patient_confirmed ? CheckedMark : UncheckedMark}
          />
        </div>
      ) : null}
    </div>
  )
}

export default MedicationContainer

import React, { Component, Fragment } from 'react'
import ReactGA from 'react-ga'
import { Redirect } from 'react-router-dom'

import { WelcomeMessage, CopayStepTracker, ConfirmOrderList, MedicationsList, ShippingPaymentContainer } from './'

import CheckoutCertifications from '../../common/checkout/CheckoutCertifications'
import CheckoutQuestions from '../../common/checkout/CheckoutQuestions'

import { calculateTotal, sortMedications } from './utils'

import { API_PATH } from '../../config'

import './CopayConfirmation.scss'
import { initGA } from '../utils'

initGA()
class CopayConfirmation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmRefills: false,
      confirmOrder: false,
      confirmShipPayment: false,
      currentMeds: [],
      futureMeds: [],
      needsRefills: [],
      url_token: '',
      orderCompletionStatus: null,
      welcomeIsOpen: true,
      isTestOrder: null,
    }
  }

  componentDidMount() {
    const { products, url_token, first_name, is_test } = this.props.data
    const { confirmed, future, out } = sortMedications(products)
    this.setState({
      currentMeds: confirmed,
      futureMeds: future,
      needsRefills: out,
      url_token,
      userName: first_name,
      isTestOrder: !!is_test,
    })
  }

  submitWithNoMeds = async () => {
    const { url_token } = this.state
    await fetch(`${API_PATH}/orders/submit/${url_token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ decline_all: true }),
    }).then(() => {
      this.setState({ orderCompletionStatus: 'no-payment' })
      // TODO - redirect to different page than copay-success (or needs different copy)
    })
  }

  renderBreadcrumbs() {
    return <CopayStepTracker confirmRefills={this.state.confirmRefills} confirmOrder={this.state.confirmOrder} />
  }

  render() {
    const { confirmRefills, confirmOrder, currentMeds, futureMeds, needsRefills, url_token, isTestOrder } = this.state
    const { requiresPatientSurvey, custom_welcome_message } = this.props.data

    if (this.state.orderCompletionStatus) {
      return (
        <Redirect
          to={{
            pathname: '/order-confirmed',
            state: { status: this.state.orderCompletionStatus, name: this.state.userName },
          }}
        />
      )
    }
    return (
      <Fragment>
        <WelcomeMessage
          name={this.state.userName}
          isOpen={this.state.welcomeIsOpen}
          handler={() => this.setState({ welcomeIsOpen: false })}
          customWelcomeMessage={custom_welcome_message}
        />
        <div className="copay-confirmation__container">
          <MedicationsList
            active={!confirmRefills}
            currentMeds={currentMeds}
            futureMeds={futureMeds}
            needsRefills={needsRefills}
            requiresPatientSurvey={requiresPatientSurvey}
            advanceForm={() => {
              // Add products from current meds
              currentMeds.forEach(medication => {
                ReactGA.plugin.execute('ec', 'addProduct', {
                  name: medication.medication_name,
                  quantity: medication.quantity,
                  price: medication.copay_price,
                })
              })
              ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
                step: 2,
              })
              ReactGA.event({
                category: 'checkout',
                action: 'click',
                label: 'medicationSelectComplete',
              })
              this.setState({ confirmRefills: true })
              const header = document.querySelector('.header')
              if (header) {
                header.scrollIntoView({
                  block: 'start',
                  behavior: 'smooth',
                })
              }
            }}
            parent={this}
            breadcrumbs={() => this.renderBreadcrumbs()}
          />
          <ConfirmOrderList
            active={confirmRefills && !confirmOrder}
            currentMeds={currentMeds.filter(med => med.patient_confirmed)}
            requiresPatientSurvey={requiresPatientSurvey}
            advanceForm={() => {
              // Add products from current meds
              currentMeds.forEach(medication => {
                ReactGA.plugin.execute('ec', 'addProduct', {
                  name: medication.medication_name,
                  quantity: medication.quantity,
                  price: medication.copay_price,
                })
              })
              ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
                step: 3,
              })
              ReactGA.event({
                category: 'checkout',
                action: 'click',
                label: 'confirmOrderComplete',
              })
              this.setState({ confirmOrder: true })
              const header = document.querySelector('.header')
              if (header) {
                header.scrollIntoView({
                  block: 'start',
                  behavior: 'smooth',
                })
              }
            }}
            goBack={() => this.setState({ confirmRefills: false })}
            breadcrumbs={() => this.renderBreadcrumbs()}
            submitWithNoMeds={this.submitWithNoMeds}
          />
          <ShippingPaymentContainer
            active={confirmRefills && confirmOrder}
            breadcrumbs={() => this.renderBreadcrumbs()}
            currentMeds={currentMeds}
            isTestOrder={isTestOrder}
            medications={[...currentMeds, ...futureMeds, ...needsRefills]}
            url_token={url_token}
            noPaymentRequired={Number(calculateTotal(currentMeds)) === 0}
            redirectCompletion={() => this.setState({ orderCompletionStatus: 'paid' })}
            goBack={() => this.setState({ confirmOrder: false })}
            requiresPatientSurvey={requiresPatientSurvey}
          />
        </div>
        <CheckoutCertifications />
        <CheckoutQuestions />
      </Fragment>
    )
  }
}

export default CopayConfirmation

import currency from 'currency.js'

import { SHIPPING_PRICE, SHIPPING_METHODS } from '../../../constants'

const mapMedicationToSubmitOrder = medications => {
  return medications.map(med => ({
    patient_confirmed: med.patient_confirmed,
    url_token: med.url_token,
    medication_name: med.medication_name,
    copay_price: med.copay_price,
    quantity: med.quantity,
    days_supply: med.days_supply,
    next_fill_date: med.next_fill_date,
    num_refills_remaining: med.num_refills_remaining,
  }))
}

const parseShippingMethod = shippingSpeed => {
  switch (shippingSpeed) {
    case SHIPPING_METHODS.UPS_NEXT_DAY_AIR_NON_CONTIGUOUS_STATES:
      return SHIPPING_METHODS.UPS_NEXT_DAY_AIR
    default:
      return shippingSpeed
  }
}

export const createSubmitOrderPayload = checkoutState => {
  const { patientConfirmation, patientPayment, patientUrac, patientGovId, patientShipping } = checkoutState
  const orderTotal = currency(patientConfirmation.total_copay).add(SHIPPING_PRICE[patientShipping.shippingSpeed]).value
  const {
    chkoutToggleAllergies,
    chkoutToggleMedicalHistory,
    chkoutToggleMedications,
    chkoutPatientAllergies,
    chkoutPatientMedications,
    chkoutPatientMedicalHistory,
    chkoutTogglePregnancy,
  } = patientUrac

  return {
    payment:
      orderTotal > 0
        ? {
            price: orderTotal,
            url_token: patientPayment?.paymentToken,
          }
        : { price: orderTotal },
    shipping: {
      street1: patientShipping.address,
      street2: patientShipping?.address2,
      city: patientShipping.city,
      state: patientShipping.state,
      zip: patientShipping.zip,
      shippingMethod: parseShippingMethod(patientShipping.shippingSpeed),
    },
    medications: mapMedicationToSubmitOrder(patientConfirmation.confirmedMeds),
    patientSurvey: {
      knownAllergies: (chkoutToggleAllergies === 'true' && chkoutPatientAllergies.join(',')) || '',
      otherMedications: (chkoutToggleMedications === 'true' && chkoutPatientMedications.join(',')) || '',
      medicalHistory: (chkoutToggleMedicalHistory === 'true' && chkoutPatientMedicalHistory.join(',')) || '',
      isPregnant: chkoutTogglePregnancy === 'false' ? false : true,
    },
    // ...(consent && { consent: patientPayment.consent }),
    ...(patientGovId && { id_image: patientGovId.fileBase64 }),
  }
}

export const transformPatientPaymentData = (formData, cardData, paymentToken) => ({
  paymentToken: paymentToken,
  cardholder: formData?.cardholder,
  zipcode: formData?.zipcode,
  cardInfo: {
    last4: cardData?.last4,
    brand: cardData?.brand,
    monthExp: cardData?.exp_month,
    yearExp: cardData?.exp_year,
  },
})

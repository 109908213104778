import styled from 'styled-components'
import { breakpoint, color } from './variables'

export const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: calc(100vh - 60px);
  padding: 1rem;
  p {
    margin-bottom: 5em;
    i {
      border: solid ${color.lightBlue};
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg) scale(3) !important;
      -webkit-transform: rotate(45deg) scale(3) !important;
      :hover {
        cursor: pointer;
      }
    }
  }
  ${breakpoint.sm} {
    height: 100%;
    padding: 3rem;
    p {
      display: none;
    }
  }
  ${breakpoint.short} {
    p {
      margin-bottom: 2em;
    }
  }
`

export const LandingPageHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  ${breakpoint.lg} {
    height: 100%;
  }
`

export const LandingPageHero = styled.div`
  display: flex;
  flex-direction: column;
  img {
    max-width: 60vw;
  }
  h1 {
    font-size: 22px;
    margin: 1rem 0;
  }
  h2 {
    font-size: 16px;
    color: ${color.lightBlue};
  }
  ${breakpoint.sm} {
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
  }
  ${breakpoint.lg} {
    max-width: 991px;
    flex-direction: row;
    img {
      width: 85%;
    }
    h1 {
      margin-top: 0;
      text-align: left;
      font-size: 32px;
    }
    h2 {
      text-align: left;
      font-size: 22px;
      margin: 2rem 0;
    }
    @media (max-width: 1083px) {
      h1 {
        font-size: 27px;
      }
      h2 {
        font-size: 20px;
      }
    }
  }
  ${breakpoint.xl} {
    max-width: 1200px;
    h1 {
      font-size: 42px;
    }
    h2 {
      font-size: 26px;
    }
    @media (max-width: 1270px) {
      h1 {
        font-size: 38px;
      }
    }
  }
`

export const SizedImg = styled.img`
  margin-bottom: 1em;
`

export const LandingPageHeader = styled.div`
  text-align: center;
  ${breakpoint.lg} {
    text-align: left;
    margin: 60px 0 0;
    width: 50%;
  }
`

export const LandingButtonContainer = styled.div`
  height: 60px;
  margin: 1rem 0 2rem;
  a {
    display: inline-block;
  }
  ${breakpoint.short} {
    height: 45px;
    margin: 0.5rem 0 1rem;
    button {
      margin-bottom: 0;
    }
  }
`

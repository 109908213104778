import React, { Component, Fragment } from 'react'
import Fuse from 'fuse.js'
import groupBy from 'lodash/groupBy'

import { GeneralFAQs, OrderingFAQs, ShippingFAQs } from './copy/FAQ'

import { initGAPageView } from '../../core/utils/index'

import FAQGroup from './FAQGroup'

class FrequentlyAskedQuestions extends Component {
  state = {
    data: null,
    searchBarInput: '',
    fuse: null,
  }

  componentWillMount = () => this.resetData()

  componentDidMount() {
    const { pathname, search } = this.props.history.location
    initGAPageView(pathname + search)
    const options = {
      threshold: 0.3,
      maxPatternLength: 32,
      keys: [
        { name: 'title', weight: 0.5 },
        { name: 'searchText', weight: 0.5 },
      ],
    }
    this.setState({ fuse: new Fuse([...GeneralFAQs, ...OrderingFAQs, ...ShippingFAQs], options) })
  }

  handleClick = () => {
    const { fuse, searchBarInput } = this.state
    if (searchBarInput.length > 0) {
      const result = fuse.search(searchBarInput)
      this.setState({ data: result })
    }
  }

  handleChange = e => {
    const { fuse } = this.state
    const { value } = e.target
    this.setState({ searchBarInput: value })
    if (value.length === 0) {
      this.resetData()
    } else {
      const result = fuse.search(value)
      this.setState({ data: result })
    }
  }

  resetData = () => {
    this.setState({
      data: [...GeneralFAQs, ...OrderingFAQs, ...ShippingFAQs],
    })
  }

  render() {
    const { data } = this.state
    const sortData = groupBy(data, 'category')
    return (
      <Fragment>
        <div className="faq__header">
          <h1 className="faq__heading--1">Frequently</h1>
          <h1 className="faq__heading--1">Asked Questions</h1>
        </div>
        <div className="faq__search-bar">
          <div className="faq__search-bar--input-container">
            <input
              onChange={this.handleChange}
              className="faq__search-bar--input"
              type="text"
              placeholder="Type to search ..."
            />
          </div>
        </div>
        <div className="faq__container">
          {Object.keys(sortData).length > 0 ? (
            Object.keys(sortData).map((category, i) => {
              return <FAQGroup data={sortData[category]} name={`${category} FAQs`} key={i} />
            })
          ) : (
            <span className="faq__heading--no-results">No results found</span>
          )}
        </div>
      </Fragment>
    )
  }
}

export default FrequentlyAskedQuestions

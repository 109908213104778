import React from 'react'
import styled from 'styled-components'

import AboutTruepillItem from './AboutTruepillItem'
import { ImageContainer } from '../../styledComponents'
import { LifeRaftIcon, PlaneIcon, BoxIcon, PharmacistImage } from '../../images'
import { breakpoint } from '../../styledComponents/variables'

const iconItems = [
  {
    icon: LifeRaftIcon,
    altkey: 'life-raft-icon',
    title: '24/7 Pharmacist Support',
    subtitle: 'Talk to a pharmacist for all your medication needs.',
  },
  {
    icon: PlaneIcon,
    altkey: 'plane-icon',
    title: 'Free Shipping',
    subtitle: 'Shipping is always free and orders arrive on time.',
  },
  {
    icon: BoxIcon,
    altkey: 'box-icon',
    title: 'Just Pay Your Copays',
    subtitle: 'Just pay your copay and we take care of the rest for you.',
  },
]

const AboutTruepillPharmacy = () => (
  <AboutTruepillPharmacyContainer>
    <AboutTruepillItemContainer>
      {iconItems.map((itemData, i) => (
        <AboutTruepillItem key={`about_truepill_item_${i}`} data={itemData} />
      ))}
    </AboutTruepillItemContainer>
    <ImageContainer>
      <img src={PharmacistImage} alt="pharmacist" />
    </ImageContainer>
  </AboutTruepillPharmacyContainer>
)

const AboutTruepillPharmacyContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column-reverse;
  text-align: center;
  padding: 2rem 0;
  margin: 0 auto;
  max-width: 575px;

  h1 {
    font-size: 26px;
  }
  p {
    font-size: 16px;
    color: rgba(40, 48, 51, 0.65);
  }

  ${breakpoint.lg} {
    max-width: 992px;
    flex-direction: row;
  }

  ${breakpoint.xl} {
    max-width: 1200px;

    h1 {
      font-size: 28px;
    }
    p {
      font-size: 16px;
    }
  }
`

const AboutTruepillItemContainer = styled.div`
  align-self: center;
`

export default AboutTruepillPharmacy

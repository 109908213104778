import React, { useEffect } from 'react'
import { Radio, Text, Spacer } from '@truepill/react-capsule'
import styled from 'styled-components'
import { SHIPPING_METHODS } from '../../../constants'
import { WebTooltip, MobileTooltip } from '../tooltip'

import { useLinkedCheckout } from '../../../state/context'
import { PBM_IDS } from '../../../../../constants'
import './shippingSpeed.css'

const renderOvernightShippingPrice = (state) => {
  switch (state) {
    case 'Alaska':
    case 'Hawaii':
      return '$50.00'
    default:
      return '$27.00'
  }
}

const pickOvernightShipping = (state) => {
  switch (state) {
    case 'Alaska':
    case 'Hawaii':
      return SHIPPING_METHODS.UPS_NEXT_DAY_AIR_NON_CONTIGUOUS_STATES
    default:
      return SHIPPING_METHODS.UPS_NEXT_DAY_AIR
  }
}

const ShippingSpeed = ({ controlledSubstance, setValue, register, watch }) => {
  const { state } = useLinkedCheckout()
  const { orderInfo } = state
  const text =
    'All packages containing controlled substances such as Adderall have a legal requirement for the carrier to obtain a signature to complete the delivery. Our carrier partner typically attempts to complete delivery during working hours (9am - 5pm) on weekdays (Monday through Friday). Please keep this in mind when choosing a shipping address as we can also ship to a work address. If you have any questions, please contact us at 855-792-7113.'
  const watchSelectedShipping = watch('shippingSpeed')
  const watchSelectedState = watch('state')
  const isCircleMedical = orderInfo.pbmId === PBM_IDS.CIRCLE_MEDICAL
  const isSouthernScriptsLC = orderInfo.pbmId === PBM_IDS.SOUTHERN_SCRIPTS_LC
  const controlledShippingMethod = isCircleMedical ? SHIPPING_METHODS.FEDEX_TWO_DAY : SHIPPING_METHODS.USPS_EXPRESS

  const isOvernightShipping = watchSelectedShipping === SHIPPING_METHODS.UPS_NEXT_DAY_AIR || watchSelectedShipping === SHIPPING_METHODS.UPS_NEXT_DAY_AIR_NON_CONTIGUOUS_STATES

  useEffect(() => {
    if (isOvernightShipping) {
      setValue('shippingSpeed', pickOvernightShipping(watchSelectedState))
    }
  }, [watchSelectedState, isOvernightShipping])

  return (
    <>
      {controlledSubstance ? (
        <>
          <ShippingContainer
            style={{
              background: watchSelectedShipping === controlledShippingMethod ? '#F7FCFF' : '#FFF',
              borderColor: watchSelectedShipping === controlledShippingMethod ? '#0173CA' : '#9EACBC',
            }}
            onClick={() => {
              setValue('shippingSpeed', controlledShippingMethod)
            }}
          >
            <Radio
              {...register('shippingSpeed')}
              value={controlledShippingMethod}
              checked={watchSelectedShipping === controlledShippingMethod}
            />
            <Spacer size="sm" axis="horizontal" />
            <Text bold>Express</Text>
            <Spacer size="xs" axis="horizontal" />
            <Text>(2 business days)</Text>
            <TextToRight>
              <Text bold>$0.00</Text>
            </TextToRight>
          </ShippingContainer>
          <Spacer size="lg" axis="vertical" />
          <TextAndIcon>
            <Text>Your signature may be required for delivery</Text>
            <WebTooltip text={text} position="right" variant="white" />
            <MobileTooltip title="Signature may be required" text={text} />
          </TextAndIcon>
        </>
      ) : (
            <>
              <ShippingContainer
                style={{
                  background: watchSelectedShipping === SHIPPING_METHODS.USPS_FIRST ? '#F7FCFF' : '#FFF',
                  borderColor: watchSelectedShipping === SHIPPING_METHODS.USPS_FIRST ? '#0173CA' : '#9EACBC',
                }}
                onClick={() => {
                  setValue('shippingSpeed', SHIPPING_METHODS.USPS_FIRST)
                }}
                >
                <Radio
                  {...register('shippingSpeed')}
                  value={SHIPPING_METHODS.USPS_FIRST}
                  checked={watchSelectedShipping === SHIPPING_METHODS.USPS_FIRST}
                  />
                <Spacer size="sm" axis="horizontal" />
                <Text bold>Standard</Text>
                <Spacer size="xs" axis="horizontal" />
                <Text>(3-5 business days)</Text>
                <TextToRight>
                  <Text bold>$0.00</Text>
                </TextToRight>
              </ShippingContainer>
              <Spacer size="md" axis="vertical" />

              {!isSouthernScriptsLC && (
                <>
                  <ShippingContainer
                    style={{
                      background: watchSelectedShipping === SHIPPING_METHODS.USPS_PRIORITY ? '#F7FCFF' : '#FFF',
                      borderColor: watchSelectedShipping === SHIPPING_METHODS.USPS_PRIORITY ? '#0173CA' : '#9EACBC',
                    }}
                    onClick={() => {
                      setValue('shippingSpeed', SHIPPING_METHODS.USPS_PRIORITY)
                    }}
                  >
                    <Radio
                      {...register('shippingSpeed')}
                      value={SHIPPING_METHODS.USPS_PRIORITY}
                      checked={watchSelectedShipping === SHIPPING_METHODS.USPS_PRIORITY}
                    />
                    <Spacer size="sm" axis="horizontal" />
                    <Text bold>Expedited</Text>
                    <Spacer size="xs" axis="horizontal" />
                    <Text>(1-3 business days)</Text>
                    <TextToRight>
                      <Text bold>$10.00</Text>
                    </TextToRight>
                  </ShippingContainer>
                  <Spacer size="md" axis="vertical" />
                </>
              )}
            </>
          )}
          <ShippingContainer
            style={{
              background: isOvernightShipping ? '#F7FCFF' : '#FFF',
              borderColor: isOvernightShipping ? '#0173CA' : '#9EACBC',
            }}
            onClick={() => {
              setValue('shippingSpeed', pickOvernightShipping(watchSelectedState))
            }}
            >
            <Radio
              {...register('shippingSpeed')}
              value={pickOvernightShipping(watchSelectedState)}
              checked={isOvernightShipping}
              />
            <Spacer size="sm" axis="horizontal" />
            <Text bold>Overnight</Text>
            <Spacer size="xs" axis="horizontal" />
            <Text>(1 business day)</Text>
            <TextToRight>
              <Text bold>{renderOvernightShippingPrice(watchSelectedState)}</Text>
            </TextToRight>
          </ShippingContainer>
          <Spacer size="md" axis="vertical" />
    </>
  )
}

const TextToRight = styled.div`
  margin-left: auto;
`
const ShippingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 16px 16px;

  border: 1px solid #0173ca;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
`

const TextAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default ShippingSpeed

import React, { Fragment } from 'react'

// How do refills work?
// We will reach out to you 7-8 days before you run out of medications to make sure you still want your next refill. You can 1-click to confirm and your medication will be shipped out same day. If you have no refills remaining on your prescription we will reach out ot your doctor to obtain a new prescription.

// How long will it take to receive my medication?
// If you have an existing prescription at another pharmacy we will reach out to transfer it to Truepill. This process typically takes 1-3 business days and we will notify you as soon as we have received it from your pharmacy. Once we have your prescription on file, your order will be shipped same day and we will provide you a tracking URL to follow the progress of the order. We deliver for free using USPS First Class Mail which typically takes 3-5 days and you can always upgrade to Priority Mail.

// Do you take my insurance?
// We accept all major insurance providers and can run your insurance to tell you what your copay will be. Your copays will always be the exact same as what you pay at your current pharmacy.

// We will ask you during your order completion process if you want to use insurance or cash. If you select insurance you can provide your insurance details and we will notify you of your copay pricing. We will also always compare your copay pricing with the cash price of the drug and give you full control to choose how you want to pay for your medication.

// Where do you ship?
// Truepill is a licensed U.S. pharmacy and ships to all 50 states and Washington DC. We currently do not ship to Puerto Rico and other U.S. territories

// const LandingPageFAQs = [
//   {
//     title: 'Do I need a prescription?',
//     body: (
//       <p className='p-text'>
//         You won’t need your prescription to complete your purchase, You will need a valid prescription to get your medication but you won't need one to get your medication.

//         If you have an existing prescription, we will reach out to your existing pharmacy to transfer the prescription to Truepill at no extra charge. This normally takes 1-3 business days. If you need a new prescription we will reach out directly to your doctor to obtain one. If your doctor for some reason does not renew your prescription our customer support team will reach out to let you know.

//         If you have any questions, please call our customer care team at 1 (855) 979-8290.
//       </p>
//     ),
//     searchText: 'Truepill.com is a US-based online pharmacy located in Hayward, California',
//     category: 'General'
//   },
//   {
//     title: 'Which forms of payment do you accept?',
//     body: (
//       <p className='p-text'>
//         We accept all major credit cards, including Mastercard, Visa, American Express, and Discover.
//       </p>
//     ),
//     searchText: 'We accept all major credit cards, including Mastercard, Visa, American Express, and Discover.',
//     category: 'General'
//   },
//   {
//     title: 'Can I return medications?',
//     body: (
//       <p className='p-text'>
//         No, unfortunately by pharmacy law we cannot accept returns for medications. All sales are final.
//       </p>
//     ),
//     searchText: 'No, unfortunately by pharmacy law we cannot accept returns for medications. All sales are final.',
//     category: 'General'
//   }
// ]

const GeneralFAQs = [
  {
    title: 'Where is Truepill.com located?',
    body: <p className="p-text">Truepill.com is a US-based online pharmacy located in Hayward, California</p>,
    searchText: 'Truepill.com is a US-based online pharmacy located in Hayward, California',
    category: 'General',
  },
  {
    title: 'Which forms of payment do you accept?',
    body: (
      <p className="p-text">
        We accept all major credit cards, including Mastercard, Visa, American Express, and Discover.
      </p>
    ),
    searchText: 'We accept all major credit cards, including Mastercard, Visa, American Express, and Discover.',
    category: 'General',
  },
  {
    title: 'Can I return medications?',
    body: (
      <p className="p-text">
        No, unfortunately by pharmacy law we cannot accept returns for medications. All sales are final.
      </p>
    ),
    searchText: 'No, unfortunately by pharmacy law we cannot accept returns for medications. All sales are final.',
    category: 'General',
  },
]

const OrderingFAQs = [
  {
    title: 'Does Truepill.com sell prescription drugs?',
    body: (
      <p className="p-text">
        Yes. Truepill.com is a fully licensed, VIPPS-accredited U.S. pharmacy that's been approved to sell prescription
        drugs in all 50 states. We only stock FDA approved medications from trustworthy manufacturers, so you never have
        to worry about where your prescriptions are coming from.
      </p>
    ),
    searchText:
      "Yes. Truepill.com is a fully licensed, VIPPS-accredited U.S. pharmacy that's been approved to sell prescription drugs in all 50 states. We only stock FDA approved medications from trustworthy manufacturers, so you never have to worry about where your prescriptions are coming from.",
    category: 'Ordering',
  },
  {
    title: 'What does VIPPS mean?',
    body: (
      <Fragment>
        <p className="p-text">
          VIPPS stands for Verified Internet Pharmacy Practice Sites, and is a program of the National Association of
          Boards of Pharmacy (NABP). Online pharmacies that are VIPPS-accredited have been screened and approved by the
          NABP.
        </p>
        <p className="p-text">
          Checking for the VIPPS seal is the fastest way to ensure that your online pharmacy is licensed and following
          the law. However, even if a site has the VIPPS seal, you should consult{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://nabp.pharmacy/">
            the NABP's list of recommended sites
          </a>{' '}
          to verify its certification.
        </p>
      </Fragment>
    ),
    searchText:
      "VIPPS stands for Verified Internet Pharmacy Practice Sites, and is a program of the National Association of Boards of Pharmacy (NABP). Online pharmacies that are VIPPS-accredited have been screened and approved by the NABP. Checking for the VIPPS seal is the fastest way to ensure that your online pharmacy is licensed and following the law. However, even if a site has the VIPPS seal, you should consult the NABP's list of recommended sites to verify its certification.",
    category: 'Ordering',
  },
  {
    title: 'Do I need a prescription from a doctor?',
    body: (
      <p className="p-text">In order to purchase prescription medications, you do need a prescription from a doctor.</p>
    ),
    searchText: 'In order to purchase prescription medications, you do need a prescription from a doctor.',
    category: 'Ordering',
  },
  {
    title: 'How do I send you my prescriptions?',
    body: (
      <Fragment>
        <p className="p-text">
          If your current prescription is already at a Pharmacy, we will reach out to the Pharmacy with the information
          you provided during checkout.
        </p>
        <p className="p-text">
          If your prescription is still with your doctor, we will also reach out to the Doctor with the information you
          provided during checkout. However, it can be helpful to speak to your doctor as well to ensure that
          prescription is sent in a timely manner. You can share the information below:
        </p>
        <ul>
          <li>
            <span className="bold-text">By Fax</span>: 650-332-2758
          </li>
          <li>
            <span className="bold-text">By Phone*</span>: (833) 860-1057
          </li>
        </ul>
        <p className="p-text">
          Future prescriptions can be sent directly from your doctor through SureScripts by having them select the
          Truepill pharmacy (Postmeds Inc.)
        </p>
        <p className="p-text">
          * Our pharmacists are available Monday through Friday, 9 am to 5 pm Pacific Standard Time. Calls placed on
          weekends or after business hours will be returned on the next business day.
        </p>
        <p className="p-text">Note that faxed in prescriptions may need to be verified for origin by Truepill.</p>
      </Fragment>
    ),
    searchText:
      'If your current prescription is already at a Pharmacy, we will reach out to the Pharmacy with the information you provided during checkout. If your prescription is still with your doctor, we will also reach out to the Doctor with the information you provided during checkout.  However, it can be helpful to speak to your doctor as well to ensure that prescription is sent in a timely manner.  You can share the information below: By Fax: 650-332-2758 By Phone*: (833) 860-1057 Future prescriptions can be sent directly from your doctor through SureScripts by having them select the Truepill pharmacy (Postmeds Inc.) * Our pharmacists are available Monday through Friday, 9 am to 5 pm Pacific Standard Time. Calls placed on weekends or after business hours will be returned on the next business day. Note that faxed in prescriptions may need to be verified for origin by Truepill.',
    category: 'Ordering',
  },
  {
    title: 'Why can’t I fax my prescription myself?',
    body: (
      <Fragment>
        <p className="p-text">
          When patients fax a prescription, they’re making a copy while maintaining possession of the original. Because
          the copy and the original could then be filled at two separate pharmacies, most states don’t allow patients to
          fax a prescription themselves. As a fully-licensed pharmacy that serves all 50 states, we’re obligated to
          follow the strictest rule, even if your state does allow a Patient Fax.
        </p>
        <p className="p-text">However, you do have the option of having your doctor fax the prescription themselves.</p>
      </Fragment>
    ),
    searchText:
      'When patients fax a prescription, they’re making a copy while maintaining possession of the original. Because the copy and the original could then be filled at two separate pharmacies, most states don’t allow patients to fax a prescription themselves. As a fully-licensed pharmacy that serves all 50 states, we’re obligated to follow the strictest rule, even if your state does allow a Patient Fax. However, you do have the option of having your doctor fax the prescription themselves.',
    category: 'Ordering',
  },
  {
    title: 'How long does it take to process my prescription?',
    body: (
      <p className="p-text">
        It depends on how quickly we receive your prescription from your doctor or pharmacy. You should expect 3-7 days
        of processing time.
      </p>
    ),
    searchText:
      'It depends on how quickly we receive your prescription from your doctor or pharmacy. You should expect 3-7 days of processing time.',
    category: 'Ordering',
  },
  {
    title: 'Is there a limit on how much of my prescription I can order?',
    body: (
      <p className="p-text">
        We can only fill the amount prescribed by your physician. It’s very important that you ensure that your
        physician includes the correct dosage and quantity for the medication you’d like to order.
      </p>
    ),
    searchText:
      'We can only fill the amount prescribed by your physician. It’s very important that you ensure that your physician includes the correct dosage and quantity for the medication you’d like to order.',
    category: 'Ordering',
  },
  {
    title: 'How do I speak to the pharmacist?',
    body: (
      <p className="p-text">
        To speak with a pharmacist, simply call 855-910-8606 (toll-free).{' '}
        <span className="bold-text">Our pharmacists are available Monday through Friday, 9 am - 5 pm Pacific Time</span>
        . Calls placed on weekends or after business hours will be returned on the next business day.
      </p>
    ),
    searchText:
      'To speak with a pharmacist, simply call 855-910-8606 (toll-free). Our pharmacists are available Monday through Friday, 9 am - 5 pm Pacific Time. Calls placed on weekends or after business hours will be returned on the next business day.',
    category: 'Ordering',
  },
  {
    title: 'What if Truepill.com doesn’t carry the prescription I’m looking for?',
    body: (
      <p className="p-text">
        Email our customer service at mailorder@truepill.com. Though we may not currently carry the product you are
        looking for, we may be able to add it to our active inventory. Our Customer Support will pass your request to
        our Purchasing Team, and we will contact you with availability and pricing information.
      </p>
    ),
    searchText:
      'Email our customer service at mailorder@truepill.com. Though we may not currently carry the product you are looking for, we may be able to add it to our active inventory. Our Customer Support will pass your request to our Purchasing Team, and we will contact you with availability and pricing information.',
    category: 'Ordering',
  },
]

const ShippingFAQs = [
  {
    title: 'How long does it take for orders to ship?',
    body: (
      <p className="p-text">
        <span className="bold-text">For prescription orders</span>: 3-7 business days, though this time may vary
        depending on how quickly we hear back from your prescriber.
      </p>
    ),
    searchText:
      'For prescription orders: 3-7 business days, though this time may vary depending on how quickly we hear back from your prescriber.',
    category: 'Shipping',
  },
  {
    title: 'Do you ship internationally?',
    body: <p className="p-text">As a licensed U.S. pharmacy, we can only ship within the United States.</p>,
    searchText: 'As a licensed U.S. pharmacy, we can only ship within the United States.',
    category: 'Shipping',
  },
  {
    title: 'What are your shipping rates?',
    body: (
      <Fragment>
        <p className="p-text">Please add an additional 3-7 business days for processing of all orders.</p>
        <ul>
          <li>
            <span className="bold-text">USPS Standard Ground Shipping</span> – FREE– 2-8 business days
          </li>
          <li>
            <span className="bold-text">USPS Priority</span> – $10 – 1-3 business days
          </li>
        </ul>
      </Fragment>
    ),
    searchText:
      'Please add an additional 3-7 business days for processing of all orders. USPS Standard Ground Shipping – FREE– 2-8 business days USPS Priority – $10 – 1-3 business days',
    category: 'Shipping',
  },
]

export { GeneralFAQs, OrderingFAQs, ShippingFAQs }

import React from 'react'
import styled from 'styled-components/macro'

const Survey = ({ patientSurvey, setSurvey, choices, setChoice }) => (
  <Container>
    <form>
      <h2>Additional Info</h2>
      <Row>
        <p>
          Any allergies?{' '}
          {choices.knownAllergies === '' && (
            <Required>
              <sub>*required</sub>
            </Required>
          )}
        </p>
        <RadioInputs>
          <div>
            <label htmlFor="yesAllergies">Yes</label>
            <input
              type="radio"
              id="yesAllergies"
              name="allergies"
              onChange={() => setChoice({ ...choices, knownAllergies: true })}
              value={choices.knownAllergies}
              required
            />
          </div>
          <div>
            <label htmlFor="noAllergies">No</label>
            <input
              type="radio"
              id="noAllergies"
              name="allergies"
              onChange={() => {
                setChoice({ ...choices, knownAllergies: false })
                setSurvey({ ...patientSurvey, knownAllergies: null })
              }}
              value={choices.knownAllergies}
              required
            />
          </div>
        </RadioInputs>
        {choices.knownAllergies && (
          <TextArea
            placeholder="Please, enter all of your allergies separated by comma"
            value={patientSurvey.knownAllergies}
            onChange={event => setSurvey({ ...patientSurvey, knownAllergies: event.target.value })}
            required
          ></TextArea>
        )}
      </Row>
      <Row>
        <p>
          Current Medications?{' '}
          {choices.otherMedications === '' && (
            <Required>
              <sub>*required</sub>
            </Required>
          )}
        </p>
        <RadioInputs>
          <div>
            <label htmlFor="currentMedsYes">Yes</label>
            <input
              type="radio"
              id="currentMedsYes"
              name="currentMeds"
              onChange={() => setChoice({ ...choices, otherMedications: true })}
              value={choices.otherMedications}
              required
            />
          </div>
          <div>
            <label htmlFor="currentMedsNo">No</label>
            <input
              type="radio"
              id="currentMedsNo"
              name="currentMeds"
              onChange={() => {
                setChoice({ ...choices, otherMedications: false })
                setSurvey({ ...patientSurvey, otherMedications: null })
              }}
              value={choices.otherMedications}
              required
            />
          </div>
        </RadioInputs>
        {choices.otherMedications && (
          <TextArea
            placeholder="Please, enter all of your medications separated by comma"
            value={patientSurvey.otherMedications}
            onChange={event => setSurvey({ ...patientSurvey, otherMedications: event.target.value })}
            required
          ></TextArea>
        )}
      </Row>
      <Row>
        <p>
          Any medical conditions or history to share?{' '}
          {choices.medicalHistory === '' && (
            <Required>
              <sub>*required</sub>
            </Required>
          )}
        </p>
        <RadioInputs>
          <div>
            <label htmlFor="medHistoryYes">Yes</label>
            <input
              type="radio"
              id="medHistoryYes"
              name="medsHistory"
              onChange={() => setChoice({ ...choices, medicalHistory: true })}
              value={choices.medicalHistory}
              required
            />
          </div>
          <div>
            <label htmlFor="medHistoryNo">No</label>
            <input
              type="radio"
              id="medHistoryNo"
              name="medsHistory"
              onChange={() => {
                setChoice({ ...choices, medicalHistory: false })
                setSurvey({ ...patientSurvey, medicalHistory: null })
              }}
              value={choices.medicalHistory}
              required
            />
          </div>
        </RadioInputs>
        {choices.medicalHistory && (
          <TextArea
            placeholder="Please, enter all of your medical conditions separated by comma"
            value={patientSurvey.medicalHistory}
            onChange={event => setSurvey({ ...patientSurvey, medicalHistory: event.target.value })}
            required
          ></TextArea>
        )}
      </Row>
    </form>
  </Container>
)

const Container = styled.div`
  display: flex;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 7px;
  margin-top: 60px;
  margin-bottom: 40px;

  form {
    width: 100%;
    max-width: 856px;
  }

  h2 {
    margin-bottom: 1.5rem;
  }
`

const Row = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  > p {
    margin-bottom: 1rem;
    width: 100%;
    font-size: 1rem;
    color: rgba(57, 66, 72, 0.5);
  }

  textarea {
    margin-top: 1rem;
  }

  @media (min-width: 768px) {
    > p {
      margin-bottom: 0;
      width: auto;
    }
  }
`

const Required = styled.span`
  font-size: 0.8rem;
  color: #e1656f;
`

const RadioInputs = styled.div`
  display: flex;

  input {
    margin: 0;
  }

  label {
    margin-right: 0.3rem;
    color: rgba(57, 66, 72, 0.5);
  }

  div {
    margin-right: 0.9rem;
    display: flex;
    align-content: center;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  height: 90px;
  color: rgba(57, 66, 72, 0.5);
  letter-spacing: 0.3px;
  padding-left: 1rem;
  font-size: 1rem;
  font-family: CircularStd;
  border-radius: 3px;
  padding: 1rem;
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  outline: none;

  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }

  &:focus {
    border: 2px solid #43bdcf;
  }
`

export default Survey

import React, { useState, useEffect, useContext, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Select from 'react-select'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import HowTruepillWorks from './HowTruepillWorksSection/HowTruepillWorks'
import MedicationFAQs from './FAQSection/MedicationFAQs'
import Testimonials from './TestimonySection/Testimonials'
import RefillExperience from './RefillExperienceSection/RefillExperience'
import TruepillMap from './TruepillMapSection/TruepillMap'
import AboutTruepillPharmacy from './AboutTruepillSection/AboutTruepillPharmacy'
import { LandingImage } from '../images'
import { Button, ImageContainer } from '../styledComponents'
import { initGAPageView } from '../../core/utils'
import { breakpoint } from '../styledComponents/variables'
import EmployerContext from '../../lib/Context/EmployerContext'
import { useSite } from '../../lib/Context/SiteContext'
import {
  LandingPageContainer,
  LandingPageHeroContainer,
  LandingPageHero,
  SizedImg,
  LandingPageHeader,
  LandingButtonContainer,
} from '../styledComponents/landingPage'
import { useGetEmployers } from '../../hooks'
import { DEFAULT_EMPLOYER_DETAILS } from '../../constants'

const LandingPage = props => {
  const { data: employers } = useGetEmployers()
  useEffect(() => {
    initGAPageView(props.match.path)
  }, [props.match.path])
  const { setEmployerName } = useContext(EmployerContext)
  const { setSiteState } = useSite()
  const [employerSelectVal, setEmployerSelectVal] = useState('')

  // We do this once on load to reset the employer if you navigate back to the
  // index page.
  useLayoutEffect(() => {
    setEmployerName(DEFAULT_EMPLOYER_DETAILS.employerName)
    setSiteState(prevState => ({ ...prevState, renderOldFooter: true }))
  }, [setEmployerName])

  return (
    <>
      <LandingPageContainer>
        <LandingPageHeroContainer>
          <LandingPageHero>
            <LandingPageHeader>
              <h1>
                Welcome to Your Preferred
                <br />
                Home Delivery Program
              </h1>
              <h2>Never wait in line at a pharmacy again</h2>
              <StyledSelect
                autoFocus
                value={employerSelectVal}
                onChange={selected => {
                  setEmployerSelectVal(selected)
                }}
                options={(employers ?? [])
                  .filter(emp => !emp.hide_from_landing_page)
                  .map(({ name }) => ({
                    label: name,
                    value: name,
                  }))}
                placeholder={'Enter your employer'}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#44bdd0',
                  },
                })}
              />
              <LandingButtonContainer>
                <Button
                  as={Link}
                  to={`/employers/${encodeURIComponent(employerSelectVal.value)}`}
                  disabled={!employerSelectVal}
                  data-testid="enroll-button"
                >
                  Enroll Today
                </Button>
              </LandingButtonContainer>
            </LandingPageHeader>
            <ImageContainer>
              <SizedImg src={LandingImage} alt="pbm-experience" />
            </ImageContainer>
          </LandingPageHero>
        </LandingPageHeroContainer>
        <StyledSmoothScrollLink href="#fold">
          <p>
            <i />
          </p>
        </StyledSmoothScrollLink>
      </LandingPageContainer>
      <div id={'fold'} />
      <HowTruepillWorks />
      <AboutTruepillPharmacy />
      <TruepillMap />
      <Testimonials />
      <RefillExperience />
      <MedicationFAQs />
    </>
  )
}

const StyledSelect = styled(Select)`
  width: 300px;
  margin: 1em auto;
  text-align: left;
  ${breakpoint.lg} {
    margin: 1em 0;
    transform: scale(1.2);
    transform-origin: top left;
  }
`

const StyledSmoothScrollLink = styled(AnchorLink)`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`

export default LandingPage

import React from 'react'

import { AvatarOne, AvatarTwo, AvatarThree } from '../../images'
import Testimonial from './Testimonial'

const testimonials = [
  {
    icon: AvatarThree,
    quote:
      'When I ran out of refills, Truepill reached out directly to my doctor to get  me a new prescription. I would have forgotten to do this and would have run out of medications without Truepill’s pharmacy team looking out for me.',
    name: '- Margaret T., Denver, CO',
  },
  {
    icon: AvatarOne,
    quote:
      'I have a high deductible insurance plan now and was paying nearly 3 times as much for my medication. Someone from the Truepill pharmacy team called me to explain to me that I would be better off paying cash for this drug and over the course of 12 months have saved over $400.',
    name: '- William N., Dallas, TX',
  },
  {
    icon: AvatarTwo,
    quote:
      'Using Truepill was as easy as buying stuff on Amazon. The 1-click refill confirmation experience is amazing and makes it so easy for me to receive all my medications from Truepill.',
    name: '- Diane R., Ft. Lauderdale, FL',
  },
]

const Testimonials = props => {
  return (
    <div className="testimonials__container">
      <h2 className="testimonials__header">Testimonials</h2>
      {testimonials.map(testimonial => (
        <Testimonial key={testimonial.quote.sub(0, 9)} {...testimonial} />
      ))}
    </div>
  )
}

export default Testimonials

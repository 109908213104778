import React, { useContext, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import useSWR from 'swr'
import LoadingOverlay from '../../common/LoadingOverlay'

import { API_PATH } from '../../config/index'
import { UNIQUE_EMPLOYER_NAMES_BY_PBM } from '../../constants'
import EmployerContext from '../../lib/Context/EmployerContext'
import { useSite } from '../../lib/Context/SiteContext'

import CopayConfirmation from './CopayConfirmation'
import LinkedCheckout from '../linked-checkout'
import { OrderAlreadyProcessed } from './OrderAlreadyProcessed'

const LoadingSpinnerWrapper = styled.div`
  top: 4rem;
`

const CopayConfirmContainer = () => {
  const { orderUrlToken } = useParams()
  const { setSiteState } = useSite()

  const {
    employer: { employerName, phone },
    setEmployerName,
  } = useContext(EmployerContext)

  const { data, error } = useSWR(`${API_PATH}/orders/${encodeURIComponent(orderUrlToken)}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const linkedCheckout = data?.linked_checkout?.enabled

  // If the order has a PBM ID that corresponds to a single employer, we set
  // that employer context here.

  const PROCCESSED_ORDER_MSG = 'Order has already been processed'

  useLayoutEffect(() => {
    if (data?.message === PROCCESSED_ORDER_MSG) {
      setSiteState(prevState => ({ ...prevState, renderOldFooter: false }))
    }

    if ((data?.pbm_id ?? null) === null || !UNIQUE_EMPLOYER_NAMES_BY_PBM[data.pbm_id]) {
      return
    }
    if (linkedCheckout) {
      setSiteState(prevState => ({ ...prevState, renderOldFooter: false }))
    }

    setEmployerName(UNIQUE_EMPLOYER_NAMES_BY_PBM[data.pbm_id])
  }, [data, setEmployerName, linkedCheckout, setSiteState])

  // This prevents a visible flicker of branding changing.
  if (
    (data?.pbm_id ?? null) !== null &&
    UNIQUE_EMPLOYER_NAMES_BY_PBM[data.pbm_id] &&
    employerName !== UNIQUE_EMPLOYER_NAMES_BY_PBM[data.pbm_id]
  ) {
    return <LoadingOverlay />
  }

  if (error) {
    return (
      <div className="copay-confirmation__container--status">
        <p>
          Sorry, there was an internal error!
          <br />
          <br />
          Please contact the our support at <a href={`tel:${phone}`}>{phone}</a>.
        </p>
      </div>
    )
  }

  if (!data) {
    return (
      <div className="copay-confirmation__container--status">
        <LoadingSpinnerWrapper>
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </LoadingSpinnerWrapper>
      </div>
    )
  }

  if (data.message) {
    if (data.message === PROCCESSED_ORDER_MSG) {
      return <OrderAlreadyProcessed phone={phone} />
    } else
      return (
        <div className="copay-confirmation__container--status">
          <p>{data.message}</p>
        </div>
      )
  }

  if (linkedCheckout) return <LinkedCheckout data={data} />

  return <CopayConfirmation data={data} />
}

export default CopayConfirmContainer

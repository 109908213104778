module.exports = {
  breakpoint: {
    sm: '@media (min-width: 576px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 992px)',
    xl: '@media (min-width: 1200px)',
    short: '@media (max-height: 568px)',
  },
  color: {
    lightBlue: '#44bdd0',
  },
}

import React from 'react'
import { Switch } from 'react-router-dom'

import RouteWithSubRoutes from './RouteWithSubRoutes'

const RouteConfig = props => {
  const { data } = props
  return (
    <Switch>
      {props.routes.map((route, i) => {
        return <RouteWithSubRoutes key={i} data={data} state={props} {...route} />
      })}
    </Switch>
  )
}

export default RouteConfig

import React, { Component, Fragment } from 'react'
import { withApollo } from 'react-apollo'
// import { MixpanelConsumer } from 'react-mixpanel'
import { AsYouType } from 'libphonenumber-js'
import pick from 'lodash/pick'

// import CheckoutFormInput from './CheckoutFormInput'
import BackButton from './NewBackButton'
import NextButton from './NextButton'
import PatientInfoToggleSection from './PatientInfoToggleSection'
// import { Days, Months, Years } from './utils'
import { validateField, validateFields } from './validation'
import { initGAPageView } from '../../core/utils'
// import DropdownSelect from '../DropdownSelect'

class AdditionalInfo extends Component {
  state = {
    input: {
      chkoutToggleAllergies: null,
      chkoutToggleMedications: null,
      chkoutToggleMedicalHistory: null,
      chkoutPatientAllergies: '',
      chkoutPatientMedications: '',
      chkoutPatientMedicalHistory: '',
    },
    error: {
      chkoutToggleAllergies: null,
      chkoutToggleMedications: null,
      chkoutToggleMedicalHistory: null,
      chkoutPatientAllergies: null,
      chkoutPatientMedications: null,
      chkoutPatientMedicalHistory: null,
    },
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    initGAPageView(this.props.match.path)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.path !== this.props.match.path) {
      initGAPageView(this.props.match.path)
    }
  }

  handleClick = e => {
    this.props.state.saveInput(this.state.input, 'additionalInfo')

    const isValid = this.validateAllFields()
    if (isValid) {
      e.preventDefault()
    }
  }

  handleInputChange = inputGroup => e => {
    let { name, type, value } = e.target
    if (type === 'checkbox') value = value === 'false' ? true : !value
    if (name === 'chkoutCustomerPhone' && value.length > 9) value = new AsYouType('US').input(value)
    if (name === 'chkoutPatientDob') {
      if ([3, 6].includes(value.length) && value[value.length - 1] !== '/') {
        value = `${value.slice(0, value.length - 1)}/${value[value.length - 1]}`
      }
    }
    this.setState(prevState => ({
      [inputGroup]: {
        ...prevState[inputGroup],
        [name]: value,
      },
    }))
  }

  handleToggle = e => {
    const {
      target: { name, value },
    } = e
    this.setState(prevState => ({
      input: {
        ...prevState.input,
        [name]: value === 'true',
      },
    }))
  }

  handleValidation = e => {
    const { name, value } = e.target
    const { input } = this.state
    const validatonValue = !input[name] ? value : input[name]
    this.setState(prevState => ({
      error: {
        ...prevState.error,
        [name]: validateField(name, validatonValue),
      },
    }))
  }

  validateAllFields = () => {
    const { input } = this.state
    const filterFields = []
    if (input.chkoutToggleAllergies === false) filterFields.push('chkoutPatientAllergies')
    if (input.chkoutToggleMedications === false) filterFields.push('chkoutPatientMedications')
    if (input.chkoutToggleMedicalHistory === false) filterFields.push('chkoutPatientMedicalHistory')
    if ([false, 'false'].includes(input.chkoutPatientDifferentThanCustomer))
      filterFields.push('chkoutPatientFirstName', 'chkoutPatientLastName')
    const fieldsToValidate = {
      ...pick(
        input,
        Object.keys(input).filter(key => !filterFields.includes(key))
      ),
    }
    const { results, hasErrors } = validateFields(fieldsToValidate)
    this.setState(prevState => ({
      error: {
        ...prevState.error,
        ...results,
      },
    }))
    return hasErrors
  }

  render() {
    const {
      chkoutPatientAllergies,
      chkoutPatientMedications,
      chkoutPatientMedicalHistory,
      chkoutToggleAllergies,
      chkoutToggleMedications,
      chkoutToggleMedicalHistory,
    } = this.state.input
    return (
      <Fragment>
        <div className="additional-info__header">Additional Info</div>

        <div className="checkout__label-row patient-customer-checkbox"></div>

        <div className="checkout__survey-fields-container">
          <PatientInfoToggleSection
            {...this.props}
            parent={this}
            errorBorder={this.state.error.chkoutPatientAllergies}
            handleInputChange={this.handleInputChange('input')}
            handleToggle={this.handleToggle}
            handleValidation={this.handleValidation}
            inputName="chkoutPatientAllergies"
            inputValue={chkoutPatientAllergies}
            label="Any Allergies?"
            placeholder="Please enter all of your allergies separated by a comma."
            showInput={chkoutToggleAllergies}
            toggleName="chkoutToggleAllergies"
          />
          <PatientInfoToggleSection
            {...this.props}
            parent={this}
            errorBorder={this.state.error.chkoutPatientMedications}
            handleInputChange={this.handleInputChange('input')}
            handleToggle={this.handleToggle}
            handleValidation={this.handleValidation}
            inputName="chkoutPatientMedications"
            inputValue={chkoutPatientMedications}
            label="Current Medications?"
            placeholder="Please enter all of your medications separated by a comma."
            showInput={chkoutToggleMedications}
            toggleName="chkoutToggleMedications"
          />
          <PatientInfoToggleSection
            {...this.props}
            parent={this}
            errorBorder={this.state.error.chkoutPatientMedicalHistory}
            handleInputChange={this.handleInputChange('input')}
            handleToggle={this.handleToggle}
            handleValidation={this.handleValidation}
            inputName="chkoutPatientMedicalHistory"
            inputValue={chkoutPatientMedicalHistory}
            label="Any medical conditions or history to share?"
            placeholder="Please enter all of your medical conditions separated by a comma."
            showInput={chkoutToggleMedicalHistory}
            toggleName="chkoutToggleMedicalHistory"
          />
        </div>
        <div className="checkout__form--footer">
          <div className="checkout__form-button-group">
            <BackButton goBack={this.props.history.goBack} />
            <NextButton to="/enroll/pharmacy_info/" handleClick={this.handleClick} />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withApollo(AdditionalInfo)

import React, { useState, useContext } from 'react'
import currency from 'currency.js'
import pick from 'lodash/pick'

import { validateFields } from './validation'
import { addressDefaults, errorDefaults } from './copayShippingDefaults'
import { calculateTotal } from './utils'

import { ShippingInfo, StripePaymentForm } from './'

import Survey from './Survey'
import EstimatedDelivery from './EstimatedDelivery'

import './ShippingPaymentContainer.scss'
import { EmployerContext } from '../../lib'

const ShippingPaymentContainer = props => {
  const { active, currentMeds, breadcrumbs, noPaymentRequired, requiresPatientSurvey } = props
  const {
    employer: { employerName },
  } = useContext(EmployerContext)
  const isCircleMedical = employerName === 'Circle Medical'
  const [shippingState, setShippingState] = useState(addressDefaults)
  const [consent, setConsent] = useState(false)
  const [consentError, setConsentError] = useState(false)
  const [name, updateName] = useState('')
  const { shippingMethod } = shippingState
  const shippingCost = shippingMethod === 'usps_priority' ? 5 : 0
  const orderTotal = calculateTotal(currentMeds, shippingCost)
  const [shippingErrorState, setShippingErrorState] = useState(errorDefaults)
  const [patientSurvey, setSurvey] = useState({
    knownAllergies: '',
    otherMedications: '',
    medicalHistory: '',
  })
  const [choices, setChoice] = useState({
    knownAllergies: '',
    otherMedications: '',
    medicalHistory: '',
  })

  const validateAllFields = () => {
    const fieldsToValidate = pick(
      shippingState,
      Object.keys(shippingState).filter(key => key !== 'street2')
    )

    const moreFields = requiresPatientSurvey
      ? {
          name,
          ...choices,
        }
      : {
          name,
        }

    const { results, hasErrors } = validateFields({
      ...fieldsToValidate,
      ...(!noPaymentRequired ? moreFields : {}),
      ...(isCircleMedical && { consent }),
    })
    setShippingErrorState({
      ...results,
    })

    if (results.consent) {
      setConsentError(true)
    }
    return hasErrors
  }

  if (!active) return null

  return (
    <div className="shipping-payment__container">
      <h1>Total {currency(orderTotal).format()}</h1>
      <EstimatedDelivery currentMeds={currentMeds} requiresPatientSurvey={requiresPatientSurvey} />
      {breadcrumbs()}
      {requiresPatientSurvey && (
        <Survey patientSurvey={patientSurvey} setSurvey={setSurvey} choices={choices} setChoice={setChoice} />
      )}
      <ShippingInfo
        enableSubmit={noPaymentRequired && shippingCost === 0}
        orderTotal={orderTotal}
        shippingState={shippingState}
        setShippingState={setShippingState}
        shippingErrorState={shippingErrorState}
        setShippingErrorState={setShippingErrorState}
        showConsentBox={isCircleMedical}
        setConsent={setConsent}
        consentError={!consent && consentError}
        consent={consent}
        validateAllFields={validateAllFields}
        showPatientSurvey={requiresPatientSurvey || isCircleMedical}
        {...props}
      />
      <StripePaymentForm
        disabled={noPaymentRequired && shippingCost === 0}
        orderTotal={orderTotal}
        shippingInfo={shippingState}
        validateAllFields={validateAllFields}
        patientSurvey={patientSurvey}
        name={name}
        updateName={updateName}
        consent={consent}
        {...props}
      />
    </div>
  )
}

export default ShippingPaymentContainer

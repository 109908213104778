import React from 'react'
import styled from 'styled-components'
import { Header, Card, Text } from '@truepill/react-capsule'

const InsurancePage = ({ insurance }) => {
  return (
    <Card padding="lg" width="auto" color="gray">
      <InsuranceDetails>
        <Header bold variant="2xl" className="cap-typography-dark">
          {insurance.insuranceName}
        </Header>
        <DetailBlock>
          <Text bold>Member ID:&nbsp;</Text>
          <Text>{insurance.cardholderId}</Text>
        </DetailBlock>
        <DetailBlock>
          <Text bold>RxBin:&nbsp;</Text>
          <Text>{insurance.rxBin}</Text>
        </DetailBlock>
        <DetailBlock>
          <Text bold>RxGroup:&nbsp;</Text>
          <Text>{insurance.rxGroup}</Text>
        </DetailBlock>
      </InsuranceDetails>
    </Card>
  )
}

const InsuranceDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const DetailBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

export default InsurancePage

import React from 'react'
import styled from 'styled-components'

const Input = ({ register, name, placeholder, error }) => {
  return (
    <InputContainer>
      <StyledInput error={error} placeholder={placeholder} {...register(name)} />
    </InputContainer>
  )
}

const InputContainer = styled.div`
  margin: 1rem 0;
`

const StyledInput = styled.input`
  width: 100%;
  padding: 1rem;
  font-size: 16px;
  font-family: CircularStd;
  border: 1px solid rgba(175, 188, 198, 0.55);
  ${({ error }) => error && 'border: 1px solid red;'}

  ::placeholder {
    color: rgba(57, 66, 72, 0.5);
  }
`

export default Input

import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  h1,h2,h3,p {
    margin: 0;
  }
  h1 {
    font-size: 28px;
    color: #2f2f2f;
    font-weight: 500;
  }

  h2 {
    font-size: 20px;
    color: #2f2f2f;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 18px;
    color: #2f2f2f;
  }

  p {
    font-size: 16px;
    color: #2f2f2f;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 42px;
    }

    h2 {
      font-size: 26px;
    }

    h3 {
      font-size: 22px;
    }

    p {
      font-size: 18px;
    }

  }
`

export default GlobalStyle

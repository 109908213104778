import React, { Fragment, useState } from 'react'
import ReactGA from 'react-ga'
import {
  StripeProvider,
  injectStripe,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements'

import ShippingPaymentButtons from './ShippingPaymentButtons'

import { STRIPE_PK } from '../../config'

import { createPaymentMethod, submitOrder } from './utils'

const submitHandler = (
  stripe,
  name,
  url_token,
  shippingInfo,
  medications,
  validateAllFields,
  orderTotal,
  redirectSuccess,
  patientSurvey,
  updateErrorMessage,
  disableComplete,
  consent
) => async e => {
  e.preventDefault()
  disableComplete(true)
  if (validateAllFields()) {
    disableComplete(false)
    return null
  }
  const { error, token } = await stripe.createToken({ name })

  if (error) {
    const { message } = error
    updateErrorMessage(message)
    disableComplete(false)
  } else {
    await createPaymentMethod(token, url_token)
      .then(async res => {
        const { messages, url_token: paymentToken } = await res.json()
        if (res.status === 402) {
          disableComplete(false)
          updateErrorMessage(messages[0])
        } else {
          const orderDetails = JSON.stringify({
            payment: {
              url_token: paymentToken,
              price: Number(orderTotal),
            },
            shipping: { ...shippingInfo },
            medications,
            patientSurvey: Object.values(patientSurvey).every(value => !value) ? null : patientSurvey,
            ...(consent && { consent }),
          })
          await submitOrder(orderDetails, url_token)
            .then(r => r.json())
            .then(response => {
              if (response.message === 'Successfully submitted order.') {
                medications.forEach(medication => {
                  ReactGA.plugin.execute('ec', 'addProduct', {
                    name: medication.medication_name,
                    quantity: medication.quantity,
                    price: medication.copay_price,
                  })
                })
                ReactGA.plugin.execute('ec', 'setAction', 'purchase')
                ReactGA.event({
                  category: 'checkout',
                  action: 'success',
                  label: 'checkoutSuccess',
                })
                redirectSuccess(name)
              } else {
                ReactGA.event({
                  category: 'checkout',
                  action: 'error',
                  label: 'checkoutError',
                })
                disableComplete(false)
                updateErrorMessage(response.messages[0])
              }
            })
        }
      })
      .catch(error => {
        disableComplete(false)
        console.log(error)
      })
  }
}

const cardNumberElementStyles = {
  base: {
    fontSize: '18px',
    // lineHeight: '50px',
    '::placeholder': {
      color: 'rgba(57, 66, 72, 0.5)',
    },
  },
}

const CardForm = props => {
  const {
    medications,
    stripe,
    name,
    updateName,
    shippingInfo,
    url_token,
    validateAllFields,
    redirectCompletion,
    orderTotal,
    goBack,
    isTestOrder,
    patientSurvey,
    consent,
  } = props
  const [errorMessage, updateErrorMessage] = useState(null)
  const [isSubmitting, disableComplete] = useState(false)
  const submitForm = submitHandler(
    stripe,
    name,
    url_token,
    shippingInfo,
    medications,
    validateAllFields,
    orderTotal,
    redirectCompletion,
    patientSurvey,
    updateErrorMessage,
    disableComplete,
    consent
  )
  return (
    <form onSubmit={isTestOrder ? () => redirectCompletion(name) : submitForm}>
      <div className="stripe__container">
        <div className="stripe__card-element-container">
          <h2 className="stripe-card__header">Payment Info</h2>
          <div className="stripe-card__card-number stripe-card--input-styling">
            <div className="stripe-card--spacing-wrapper">
              <CardNumberElement style={cardNumberElementStyles} />
            </div>
          </div>
          <div className="stripe-card__cardholder-name-div">
            <input
              className="cardholder-name--input"
              type="text"
              placeholder="Cardholder Name"
              value={name}
              onChange={e => updateName(e.target.value)}
            />
          </div>
          <div className="stripe-card__expiry stripe-card--input-styling">
            <div className="stripe-card--spacing-wrapper">
              <CardExpiryElement style={cardNumberElementStyles} />
            </div>
          </div>
          <div className="stripe-card__cvc stripe-card--input-styling">
            <div className="stripe-card--spacing-wrapper">
              <CardCVCElement style={cardNumberElementStyles} />
            </div>
          </div>
          <div className="stripe-card--payment-error-message">{errorMessage || ''}</div>
        </div>
      </div>
      <ShippingPaymentButtons completeDisabled={isSubmitting} goBack={goBack} />
    </form>
  )
}

const StripeCardForm = injectStripe(CardForm)

const StripePaymentForm = props => {
  if (props.disabled) return null
  return (
    <Fragment>
      <StripeProvider apiKey={STRIPE_PK}>
        <Elements>
          <StripeCardForm {...props} />
        </Elements>
      </StripeProvider>
    </Fragment>
  )
}

export default StripePaymentForm

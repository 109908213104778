import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { Header, Button, Checkbox, ProgressBar, Spacer, Toast, LoadingSpinner, Text } from '@truepill/react-capsule'
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js'
import { yupResolver } from '@hookform/resolvers/yup'

import { CapsuleButtonContainer, ProgressBarContainer } from '../components/containers'
import { MedicationConfirmationList } from '../components/layout'
import { colors } from '../global/vars'
import { PatientPaymentValidation } from '../validation'
import PaymentInfoCard from '../components/card/PaymentInfoCard'
import { NEXT_PAGE, UPDATE_PAYMENT } from '../../state/reducer/actions'
import { useLinkedCheckout } from '../../state/context'
import { transformPatientPaymentData } from '../../state/api/utils'
import { createPaymentMethod, submitOrder } from '../../state/api'
import { getStepProgress } from '../helpers'
import { SECTION_NAMES, SHIPPING_PRICE } from '../../constants'
import { breakpoint } from '../../../../common/styledComponents/variables'

const PaymentProcessingScreen = () => {
  return (
    <PaymentProcessingContainer>
      <PaymentProcessingHeader variant="4xl"> Processing your payment...</PaymentProcessingHeader>
      <PaymentProcessingMessage>
        <PaymentProcessingText>This won’t take long, you’ll receive a confirmation shortly.</PaymentProcessingText>
        <PaymentProcessingText>Please do not close or refresh this window.</PaymentProcessingText>
      </PaymentProcessingMessage>
      <LoadingSpinner size="sm" />
    </PaymentProcessingContainer>
  )
}

const PatientPay = () => {
  const { state, dispatch } = useLinkedCheckout()
  const {
    patientConfirmation: { confirmedMeds, total_copay },
    patientPayment,
    orderInfo: { orderToken, patientImageExists, showCouponAppliedIcon },
    currentPage,
    patientShipping: { shippingSpeed },
    patientOrderInsurances,
    hideInsuranceScreen,
  } = state

  const {
    handleSubmit,
    register,
    setError,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: total_copay > 0 ? yupResolver(PatientPaymentValidation) : undefined,
    mode: 'onChange',
    defaultValues: state.patientPayment,
  })

  const [hasError, setHasError] = useState(false)
  const [stripeCardError, setStripeErrorMsg] = useState('')
  const [consent, setConsent] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [cardAdded, setCardAdded] = useState(false)
  const [stripeState, setStripeState] = useState({
    cardNumberFocus: false,
    cardExpiryFocus: false,
    cardCvvFocus: false,
  })
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const hideSpinnerAndLoadNext = ({ hasErrorOccurred }) => {
    setTimeout(() => {
      setIsPaymentProcessing(false)
      if (!hasErrorOccurred) {
        dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.ORDER_CONFIRMATION })
      }
    }, 1000)
  }

  useEffect(() => {
    async function finalizeSubmitOrder() {
      // We need to actually submit the order after verifying that the card works
      const { status: submitOrderStatus, data: submitOrderData } = await submitOrder(state)

      // Something went wrong with submitting the entire order
      if (submitOrderStatus !== 202) {
        // show error screen here
        hideSpinnerAndLoadNext({ hasErrorOccurred: true })
        setCardAdded(false)
        setIsSubmitting(false)
        return
      }

      ReactGA.event({
        category: 'linkedCheckout',
        action: 'Completed Order',
        label: 'completedPaymentInfo',
      })
      hideSpinnerAndLoadNext({ hasErrorOccurred: false })
    }
    if(total_copay > 0){
      register('cardNumberCompleted')
      register('cardCvvCompleted')
      register('cardExpiryCompleted')
      register('cardRequired')

      setValue('cardRequired', true)
    }

    if (cardAdded) {
      finalizeSubmitOrder()
    }

    if(total_copay > 0){
      const { cardholder, cardNumberCompleted, cardCvvCompleted, zipcode, cardExpiryCompleted } = errors

      if (cardholder || cardNumberCompleted || cardCvvCompleted || zipcode || cardExpiryCompleted) {
        setHasError(true)
      }
    }
  }, [register, setValue, cardAdded, errors])

  const handleStripeChange = (e, key) => {
    if (e.complete) {
      setValue(key, e.complete)
      clearErrors(key)
    } else if (e.error) {
      setValue(key, false)
      setError(key, { message: e.error })
    }
  }

  const handleStripeFocusChange = (key, booleanValue) => {
    setStripeState(prevState => ({ ...prevState, [key]: booleanValue }))
  }

  const onSubmit = async formData => {
    // Required Payment or Stripe hasn't loaded yet, check for it.
    if (total_copay > 0 && ((!patientPayment && !consent) || !stripe || !elements)) {
      return
    }

    setStripeErrorMsg('')

    if(total_copay === 0){
      setCardAdded(true)
      setIsSubmitting(true)
      return
    }

    const cardElement = elements.getElement(CardNumberElement)
    const { error: stripeError, token: stripeToken } = await stripe.createToken(cardElement, {
      name: formData.cardholder,
      address_zip: formData.zipcode,
    })
    setIsPaymentProcessing(true)
    if (stripeError) {
      hideSpinnerAndLoadNext({ hasErrorOccurred: true })
      setStripeErrorMsg(stripeError.message)
      return
    }

    const { data, status } = await createPaymentMethod(orderToken, stripeToken)
    if (status !== 200) {
      hideSpinnerAndLoadNext({ hasErrorOccurred: true })
      switch (status) {
        // 402 indicates a stripe error related to the card
        case 402: {
          setStripeErrorMsg(data.messages[0])
          break
        }
        default:
          setStripeErrorMsg(data?.message ?? 'An error ocurred')
      }
      return
    }
    const { card } = stripeToken
    const { url_token: paymentToken } = data
    const paymentData = transformPatientPaymentData(formData, card, paymentToken)
    dispatch({ type: UPDATE_PAYMENT, payload: paymentData })
    ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
      step: 3,
    })
    setCardAdded(true)
    setIsSubmitting(true)
  }

  const handleback = () => {
    const previousPage = hideInsuranceScreen
      ? SECTION_NAMES.PATIENT_SHIPPING
      : SECTION_NAMES.REVIEW_INSURANCE

    dispatch({ type: NEXT_PAGE, payload: previousPage })
  }

  if (isPaymentProcessing) {
    return <PaymentProcessingScreen />
  }

  return (
    <PatientPayContainer>
      <ProgressBarContainer>
        <ProgressBar value={getStepProgress(currentPage, patientImageExists)} />
      </ProgressBarContainer>
      <Header variant="4xl"> {total_copay > 0 ? 'How will you pay for your medication?' : 'Confirm your order'}</Header>
      <Spacer size="md" axis="vertical" />
      <form onSubmit={handleSubmit(onSubmit)}>
        {total_copay > 0 && (
          <>
            <Header bold variant="xl">
              Payment method
            </Header>
            <Spacer size="md" axis="vertical" />
            <PaymentInfoCard
              errors={errors}
              register={register}
              setValue={setValue}
              dirtyFields={dirtyFields}
              handleStripeChange={handleStripeChange}
              handleStripeFocusChange={handleStripeFocusChange}
              stripeState={stripeState}
            />
            <Spacer size="md" axis="vertical" />
          </>
        )}
        <Header bold variant="xl">
          Order summary
        </Header>
        <Spacer size="md" axis="vertical" />
        <MedicationConfirmationList
          confirmedMeds={confirmedMeds}
          shippingPrice={SHIPPING_PRICE[shippingSpeed]}
          couponApplied={patientOrderInsurances?.length > 1}
          showCouponAppliedIcon={showCouponAppliedIcon}
        />

        <Spacer size="md" axis="vertical" />
        <Checkbox
          checked={consent}
          label="I consent to Truepill filling my prescription"
          onChange={() => setConsent(!consent)}
        />
        <Spacer size="md" axis="vertical" />
        <StyledAcceptTerms>
          <span>By clicking the place order button, you confirm that you have read, understand, and agree to our </span>
          <a href="/terms-of-use?isLinkedCheckout=true" target="_blank">
            Terms of Use
          </a>
          <span>, </span>
          <a href="/privacy-policy?isLinkedCheckout=true" target="_blank">
            Privacy Policy
          </a>
          <span>, and </span>
          <a href="/notice-of-privacy-practices" target="_blank">
            Notice of Privacy Policy
          </a>
          <span>.</span>
        </StyledAcceptTerms>
        <Spacer size="md" axis="vertical" />
        <Spacer size="md" axis="vertical" />
        <CapsuleButtonContainer>
          <Button type="button" onClick={handleback} variant="primary-text">
            Back
          </Button>
          <Button type="submit" disabled={(total_copay > 0 && !patientPayment && !consent) || isSubmitting}>
            Place order
          </Button>
        </CapsuleButtonContainer>
      </form>
      <Toast
        className="toast-message"
        onDismiss={() => (hasError ? setHasError(false) : stripeCardError ? setStripeErrorMsg('') : null)}
        position={{ vertical: 'top', horizontal: 'center' }}
        state={'error'}
        visible={stripeCardError || hasError}
        timeout={6000}
        onTimeout={() => (hasError ? setHasError(false) : stripeCardError ? setStripeErrorMsg('') : null)}
      >
        <ErrorMessageContainer>
          {hasError ? 'Please complete all required fields.' : stripeCardError}
        </ErrorMessageContainer>
      </Toast>
    </PatientPayContainer>
  )
}

const ErrorMessageContainer = styled.div``

const PatientPayContainer = styled.div``

const StyledAcceptTerms = styled.div`
  color: ${colors.tpGrey};
  line-height: 1.5rem;

  > a {
    color: ${colors.tpGrey};
    cursor: pointer;
    text-decoration: underline;
  }
`
const PaymentProcessingContainer = styled.div`
  margin: 64px 16px;
  text-align: center;
  font-family: Lato;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -0.5px;

  ${breakpoint.md} {
    margin-top: 32px;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.55px;
  }
`
const PaymentProcessingHeader = styled(Header)`
  color: var(--cap-typography-dark);
`

const PaymentProcessingMessage = styled.p`
  margin-top: 16px;
  margin-bottom: 48px;
`
const PaymentProcessingText = styled(Text)`
  && {
    color: var(--cap-typography-medium);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`

export default PatientPay

import React, { useRef } from 'react'
import { Button, Spacer } from '@truepill/react-capsule'
import styled from 'styled-components'
import Webcam from 'react-webcam'

import { device } from '../../global/vars'
import { CameraIcon, UploadIcon, PlaceholderId } from '../../../assets'

const videoConstraints = {
  width: 300,
  height: 300,
  facingMode: 'user',
}

const UploadBox = ({ webCamState, webcamRef, setWebCamState, capturePhoto, image, handleFileUpload }) => {
  const fileUploadRef = useRef(null)

  const handleFileClick = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click()
    }
  }

  return (
    <UploadBoxContainer>
      <input
        accept="image/png, image/jpeg"
        hidden
        onChange={e => handleFileUpload(e.target.files[0])}
        type="file"
        ref={fileUploadRef}
        id="photoUpload"
      />
      <GovernmentImageContainer>
        {webCamState ? (
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            ref={webcamRef}
            videoConstraints={videoConstraints}
            minScreenshotHeight={1000}
            minScreenshotWidth={1000}
          />
        ) : (
          <ImageHolder alt="Government ID" src={image?.fileBase64 ?? PlaceholderId} />
        )}
      </GovernmentImageContainer>
      <Spacer size="md" axis="vertical" />
      {webCamState ? (
        <TakePhotoContainer>
          <PhotoButton onClick={capturePhoto} size="sm">
            Take photo
            <Spacer size="sm" axis="horizontal" />
            <img src={CameraIcon} alt="Choose Capture Option" />
          </PhotoButton>
        </TakePhotoContainer>
      ) : (
        <PhotoButtonContainer>
          <PhotoButton onClick={handleFileClick} size="sm" variant="primary-outline">
            {`${image ? 'Reupload' : 'Upload'} Photo`}
            <Spacer size="sm" axis="horizontal" />
            <img src={UploadIcon} alt="Upload" />
          </PhotoButton>
          <PhotoButton onClick={() => setWebCamState(true)} size="sm">
            {`${image ? 'Retake' : 'Take'} Photo`}
            <Spacer size="sm" axis="horizontal" />
            <img src={CameraIcon} alt="Capture" />
          </PhotoButton>
        </PhotoButtonContainer>
      )}
    </UploadBoxContainer>
  )
}

const UploadBoxContainer = styled.div``

const PhotoButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;
  }
`

const TakePhotoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const PhotoButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const GovernmentImageContainer = styled.div`
  text-align: center;

  @media ${device.laptop} {
    border: 1px solid #9eacbc;
    border-radius: 8px;
    padding: 1rem 0;
  }
`

const ImageHolder = styled.img`
  max-width: 280px;
`

export default UploadBox

import React, { createContext, useState } from 'react'

export const MultiSectionsContext = createContext()

export const MultiSectionsProvider = ({ children }) => {
  const [_activeSection, _setActiveSection] = useState(0)

  const [MultiSectionElementCounter, _setMultiSectionElementCounter] = useState(0)

  const openSection = newActiveSection => {
    newActiveSection = Math.max(newActiveSection, 0)
    newActiveSection = Math.min(newActiveSection, MultiSectionElementCounter - 1)
    _setActiveSection(newActiveSection)
  }

  const openPreviousSection = () => {
    const newActiveSection = _activeSection - 1 < 0 ? 0 : _activeSection - 1
    openSection(newActiveSection)
  }

  const openNextSection = () => {
    const newActiveSection = _activeSection + 1 >= MultiSectionElementCounter ? _activeSection : _activeSection + 1
    openSection(newActiveSection)
  }

  return (
    <MultiSectionsContext.Provider
      value={{
        _activeSection,
        _setActiveSection,
        _setMultiSectionElementCounter,
        openPreviousSection,
        openNextSection,
        openSection,
      }}
    >
      {children}
    </MultiSectionsContext.Provider>
  )
}

import React, { Fragment, Component } from 'react'
import { Redirect } from 'react-router-dom'
import { withApollo } from 'react-apollo'

import { LoadingSpinner } from '../../common'

import { API_PATH } from '../../config'

const LoadingMessage = () => {
  return (
    <Fragment>
      <LoadingSpinner />
      <div className="order-processing__message">
        <div className="order-processing__text">
          Setting you up with home delivery,
          <br />
          this will only take a moment!
        </div>
      </div>
    </Fragment>
  )
}

class OrderProcessingPage extends Component {
  state = {
    redirect: false,
    path: '/error?eid=3e80b413fe39a553',
  }

  redirect = () => {
    return <Redirect to={this.state.path} />
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.path) {
      this.props.history.push(this.state.path)
    }
  }

  handleSubmit = body => {
    return window
      .fetch(`${API_PATH}/enroll`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
      .then(response => {
        if (response.status !== 201) {
          this.setState({
            path: '/order_incomplete',
          })
        } else if (body.noDoctorAndNoPharmacy) {
          this.setState({
            path: '/enrollment_complete',
          })
        } else {
          this.setState({
            path: '/order_complete',
          })
        }
      })
      .catch(err => {
        this.props.history.push('/order_incomplete')
        this.setState({
          path: '/order_incomplete',
        })
      })
  }

  componentDidMount() {
    const body = {
      ...this.props.state.formBody,
    }
    this.handleSubmit(body)
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.path} />
    }
    return <LoadingMessage />
  }
}

export default withApollo(OrderProcessingPage)

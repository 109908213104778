export default {
  defaults: {
    ppCcProcessorApproved: '',
    ppCcProcessorError: '',
    ppCcSslResultMessage: '',
    ppCcErrorName: '',
    ppCcErrorMessage: '',
  },
  resolvers: {
    Mutation: {
      clearResponseData: (_, args, { cache }) => {
        cache.writeData({
          data: {
            ppCcProcessorApproved: '',
            ppCcProcessorError: '',
            ppCcSslResultMessage: '',
            ppCcErrorName: '',
            ppCcErrorMessage: '',
          },
        })
        return null
      },
      updateResponseData: (_, { approved, error, fields }, { cache }) => {
        cache.writeData({
          data: {
            ppCcProcessorApproved: approved,
            ppCcProcessorError: error,
            ppCcSslResultMessage: fields.ssl_result_message,
            ppCcErrorName: fields.errorName,
            ppCcErrorMessage: fields.errorMessage,
          },
        })
        return null
      },
    },
  },
}

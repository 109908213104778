import React, { Fragment } from 'react'
import { withApollo } from 'react-apollo'
import { MixpanelConsumer } from 'react-mixpanel'

import { handleInputChange } from '../../core/utils'

import BackButton from './BackButton'
import NextButton from './NextButton'
import CertLogoGroup from './CertLogoGroup'

// REGAL-TODO: remove this page
const PrescriptionLocationForm = props => {
  const { data, client } = props
  const path =
    data.chkoutPrescriptionPresent === 'no_script'
      ? '/error?eid=517533b83d14d108'
      : `/checkout/pharmacy_info?mid=${data.mid}`
  return (
    <MixpanelConsumer>
      {mixpanel => {
        mixpanel.track('SHOW prescription location form')
        return (
          <Fragment>
            <h3 className="checkout__form--heading">Prescription Info</h3>
            <div className="checkout__form--radio-row">
              <label className="checkout__form--radio-container">
                {' '}
                I have a prescription
                <input
                  checked={data.chkoutPrescriptionPresent === 'retail_pharmacy'}
                  onChange={handleInputChange(client)}
                  value="retail_pharmacy"
                  type="radio"
                  name="chkoutPrescriptionPresent"
                />
                <span className="checkout__form--radio-checkmark" />
              </label>
            </div>
            <div className="checkout__form--radio-row">
              <label className="checkout__form--radio-container">
                {' '}
                I do not have a prescription
                <input
                  checked={data.chkoutPrescriptionPresent === 'no_script'}
                  onChange={handleInputChange(client)}
                  value="no_script"
                  type="radio"
                  name="chkoutPrescriptionPresent"
                />
                <span className="checkout__form--radio-checkmark" />
              </label>
            </div>
            <div className="checkout__form--footer">
              <div className="checkout__form-button-group">
                <BackButton to={{ pathname: '/checkout/patient_info', search: `?mid=${data.mid}` }} />
                <NextButton to={path} />
              </div>
              <CertLogoGroup />
            </div>
          </Fragment>
        )
      }}
    </MixpanelConsumer>
  )
}

const PrescriptionLocation = withApollo(PrescriptionLocationForm)

export default PrescriptionLocation

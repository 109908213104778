import React from 'react'
import styled from 'styled-components'
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'

import { BaseShippingPaymentContainer, ErrorContainer } from '../containers'
import { Input } from '../input'

const commonStripeStyles = {
  fontSize: '16px',
  '::placeholder': {
    color: 'rgba(57, 66, 72, 0.5)',
  },
}

const CARD_NUMBER_STYLES = {
  placeholder: 'Card number',
  style: {
    base: {
      ...commonStripeStyles,
    },
  },
}

const CARD_CVC_STYLES = {
  placeholder: 'CVC',
  style: {
    base: {
      ...commonStripeStyles,
    },
  },
}

const CARD_EXPIRATION_STYLES = {
  placeholder: 'Expiration (MM/YY)',
  style: {
    base: {
      ...commonStripeStyles,
    },
  },
}

const CARD_FORM_KEYS = {
  CARD_NUMBER_COMPLETED: 'cardNumberCompleted',
  CARD_CVC_COMPLETED: 'cardCvcCompleted',
  CARD_EXPIRY_COMPLETED: 'cardExpiryCompleted',
}

const StripeCard = ({ register, errors, setValue, setError, clearErrors, stripeCardError }) => {
  const handleStripeChange = (e, key) => {
    if (e.complete) {
      setValue(key, e.complete)
      clearErrors(key)
    } else if (e.error) {
      setValue(key, false)
      setError(key, { message: e.error })
    }
  }

  return (
    <StripeCardContainer>
      <h2>Payment Info</h2>
      <StripeElementContainer error={errors[CARD_FORM_KEYS.CARD_NUMBER_COMPLETED]}>
        <CardNumberElement
          onChange={e => handleStripeChange(e, CARD_FORM_KEYS.CARD_NUMBER_COMPLETED)}
          options={CARD_NUMBER_STYLES}
        />
      </StripeElementContainer>
      <CardElementContainer>
        <CardNameContainer>
          <Input name="cardholder" placeholder="Cardholder name" register={register} error={errors.cardholder} />
        </CardNameContainer>
        <StripeElementContainer error={errors[CARD_FORM_KEYS.CARD_EXPIRY_COMPLETED]}>
          <CardExpiryElement
            onChange={e => handleStripeChange(e, CARD_FORM_KEYS.CARD_EXPIRY_COMPLETED)}
            options={CARD_EXPIRATION_STYLES}
          />
        </StripeElementContainer>
        <StripeElementContainer error={errors[CARD_FORM_KEYS.CARD_CVC_COMPLETED]}>
          <CardCvcElement
            onChange={e => handleStripeChange(e, CARD_FORM_KEYS.CARD_CVC_COMPLETED)}
            options={CARD_CVC_STYLES}
          />
        </StripeElementContainer>
      </CardElementContainer>
      {stripeCardError && <ErrorContainer>{stripeCardError}</ErrorContainer>}
    </StripeCardContainer>
  )
}

const StripeCardContainer = styled(BaseShippingPaymentContainer)`
  h2 {
    font-size: 21px;
    margin: 1rem 0;
  }
`

const StripeElementContainer = styled.div`
  padding: 1.025rem 1rem;
  border: 1px solid rgba(175, 188, 198, 0.55);

  ${({ error }) => error && 'border: 1px solid red;'}
`

const CardElementContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  > div {
    margin-top: 1rem;
  }

  @media (min-width: 768px) {
    grid-template-columns: 5fr 5fr 2fr;
    grid-column-gap: 1rem;
    align-items: center;
  }
`

const CardNameContainer = styled.div`
  > div {
    margin: 0;
  }
`

export default StripeCard

import React from 'react'
import ReactGA from 'react-ga'
import currency from 'currency.js'

import { calculateTotal } from './utils'
import { MedicationContainer } from './'

import EstimatedDelivery from './EstimatedDelivery'

import './MedicationsList.scss'

const MedicationsList = props => {
  const { active, advanceForm, breadcrumbs, currentMeds, futureMeds, needsRefills, parent, requiresPatientSurvey } =
    props
  if (!active) return null
  const orderTotal = calculateTotal(currentMeds)

  ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
    step: 1,
  })
  currentMeds.forEach(medication => {
    ReactGA.plugin.execute('ec', 'addProduct', {
      name: medication.medication_name,
      quantity: medication.quantity,
      price: medication.price,
    })
  })
  ReactGA.pageview(window.location.pathname + window.location.search)
  return (
    <div className="prescription-list__container">
      <h1>Total {currency(orderTotal).format()}</h1>
      <EstimatedDelivery currentMeds={currentMeds} requiresPatientSurvey={requiresPatientSurvey} />
      {breadcrumbs()}
      <MedicationSectionList type="confirmed" medications={currentMeds} parent={parent} />
      <MedicationSectionList type="future" medications={futureMeds} />
      <MedicationSectionList type="out-of-refills" medications={needsRefills} />
      <div className="buttons__container">
        <button className="medications__continue-button" onClick={advanceForm}>
          Continue
        </button>
      </div>
    </div>
  )
}

const MedicationSectionList = props => {
  const { medications, type, parent } = props
  if (medications.length === 0) return null
  let sectionHeader, sectionDescription
  switch (type) {
    case 'confirmed':
      sectionHeader = 'Confirmed Prescriptions'
      sectionDescription = ''
      break
    case 'future':
      sectionHeader = 'Future Prescriptions'
      sectionDescription =
        'The following medication(s) are not currently available to refill. We will notify you when they are.'
      break
    case 'out-of-refills':
      sectionHeader = 'Out of refills'
      sectionDescription =
        'The following medication(s) are out of refills. We have contacted your prescriber to get an updated prescription.'
      break
    default:
      break
  }
  return (
    <div className="prescription-section__container">
      <h2>{`${sectionHeader}:`}</h2>
      <p>{`${sectionDescription}`}</p>
      {medications.map((med, i) => (
        <MedicationContainer
          details={med}
          key={`${type}-med-item-${i}}`}
          type={type}
          parent={parent}
          parentIndex={i}
          selectable={type === 'confirmed'}
        />
      ))}
    </div>
  )
}

export default MedicationsList

import React from 'react'
import styled from 'styled-components'

import { breakpoint } from '../../../../common/styledComponents/variables'
import { colors } from '../global/vars'

const LinkedCheckoutFooter = ({
  isPrivacyPolicy = false,
  isTermsOfUse = false
}) => {
  return (
    <StyledFooter>
      <div>
        <span>© 2021 Truepill, Inc. All rights reserved.</span>
      </div>
      <StyledFooterLinks>
        <ul>
          {!isPrivacyPolicy && (
            <li>
              <a href='/privacy-policy?isLinkedCheckout=true' target="_blank">
                Privacy Policy
              </a>
            </li>
          )}
          <li>
            <a href='/notice-of-privacy-practices' target="_blank">
              HIPAA Policy
            </a>
          </li>
          {!isTermsOfUse && (
            <li>
              <a href='/terms-of-use?isLinkedCheckout=true' target="_blank">
                Terms of Use
              </a>
            </li>
          )}
        </ul>
      </StyledFooterLinks>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  align-items: center;
  background-color: #f9fafb;
  color: ${colors.tpGrey};
  display: flex;
  flex-direction: column;
  height: 8.5rem;
  justify-content: center;
  width: 100%;

  > div {
    order: 1;
  }

  ${breakpoint.md} {
    flex-direction: row;
    height: 6rem;
    justify-content: space-between;
    padding: 0 4rem;

    > div {
      order: 0;
    }
  }
`

const StyledFooterLinks = styled.nav`
  padding-bottom: 1.5rem;

  > ul {
    display: flex;
    list-style: none;

    > li {
      margin: 0 2rem;

      > a {
        color: ${colors.tpGrey};
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }

  ${breakpoint.md} {
    padding: 0;
  }
`

export default LinkedCheckoutFooter

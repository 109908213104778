import React, { Component, Fragment } from 'react'
import { withApollo } from 'react-apollo'
import Select from 'react-select'
import pick from 'lodash/pick'

import BackButton from './NewBackButton'
import CheckoutFormInput from './CheckoutFormInput'
import NextButton from './NextButton'
import {
  // States,
  // StatesShort,
  StateOptions,
  // ShippingMethods,
  ShippingMethodOptions,
} from './utils'
import { validateField, validateFields } from './validation'
// import { initGAPageView } from '../../core/utils/index'
// import DropdownSelect from '../DropdownSelect'
// import { validateOperation } from 'apollo-link/lib/linkUtils';

class ShippingAddressInfo extends Component {
  state = {
    input: {
      chkoutAddressTo1: '',
      chkoutAddressTo2: '',
      chkoutAddressToCity: '',
      chkoutAddressToState: '',
      chkoutAddressToZip: '',
      chkoutShippingMethod: '',
    },
    error: {
      chkoutAddressTo1: null,
      chkoutAddressTo2: null,
      chkoutAddressToCity: null,
      chkoutAddressToState: null,
      chkoutAddressToZip: null,
      chkoutShippingMethod: null,
    },
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  // handleSubmit = async () => {
  //   await this.props.state.saveInput(this.state.input, 'paymentInfo')
  // }

  handleClick = e => {
    if (this.validateAllFields()) {
      e.preventDefault()
    }
    // this.handleSubmit()
    this.props.state.saveInput(this.state.input, 'shippingInfo')
    this.props.client.writeData({
      data: { ...this.state.input },
    })
  }

  handleSelect = (e, meta) => {
    const { name } = meta
    const { value } = e
    // this.handleSelect(e, meta)
    this.setState(prevState => {
      return {
        input: {
          ...prevState.input,
          [name]: value,
        },
      }
    })
  }

  handleInputChange = inputGroup => e => {
    const { name, value } = e.target
    if (name === 'chkoutShippingMethod') {
      const shippingPrices = {
        'USPS First Class': 0.0,
        'USPS Priority (+$5)': 5.0,
      }
      this.props.client.writeData({
        data: {
          chkoutShippingSurcharge: shippingPrices[value],
        },
      })
    }
    this.setState(prevState => ({
      [inputGroup]: {
        ...prevState[inputGroup],
        [name]: value,
      },
    }))
  }

  handleBlur = (e, meta) => {
    const { value } = e.target
    const name = meta
    const { input } = this.state
    const validationValue = !input[name] ? value : input[name]
    this.setState(prevState => ({
      error: {
        ...prevState.error,
        [name]: validateField(name, validationValue),
      },
    }))
  }

  handleValidation = (e, meta) => {
    const { name, value } = e.target
    const { input } = this.state
    const validationValue = !input[name] ? value : input[name]
    this.setState(prevState => ({
      error: {
        ...prevState.error,
        [name]: validateField(name, validationValue),
      },
    }))
  }

  validateAllFields = e => {
    const { input } = this.state
    const fieldsToValidate = pick(
      input,
      Object.keys(input).filter(key => key !== 'chkoutAddressTo2')
    )
    const { results, hasErrors } = validateFields(fieldsToValidate)
    this.setState(prevState => ({
      error: {
        ...prevState.error,
        ...results,
      },
    }))
    return hasErrors
  }

  render() {
    const {
      chkoutAddressTo1,
      chkoutAddressTo2,
      chkoutAddressToCity,
      chkoutAddressToZip,
      // chkoutAddressToState,
      // chkoutShippingMethod
    } = this.state.input
    return (
      <Fragment>
        <CheckoutFormInput
          error={this.state.error.chkoutAddressTo1}
          handleInputChange={this.handleInputChange('input')}
          handleValidation={this.handleValidation}
          name="chkoutAddressTo1"
          placeholder="Shipping Address"
          value={chkoutAddressTo1}
        />
        <CheckoutFormInput
          error={this.state.error.chkoutAddressTo2}
          handleInputChange={this.handleInputChange('input')}
          name="chkoutAddressTo2"
          placeholder="Apt, Suite, etc..."
          value={chkoutAddressTo2}
        />
        <div className="checkout__city-state-row">
          <CheckoutFormInput
            error={this.state.error.chkoutAddressToCity}
            handleInputChange={this.handleInputChange('input')}
            handleValidation={this.handleValidation}
            name="chkoutAddressToCity"
            placeholder="City"
            value={chkoutAddressToCity}
          />

          <Select
            className={'dropdown-select__state'}
            onChange={(e, meta) => this.handleSelect(e, meta)}
            name="chkoutAddressToState"
            onBlur={e => this.handleBlur(e, 'chkoutAddressToState')}
            options={StateOptions}
            placeholder={'State'}
            classNamePrefix={'dropdown-select__state'}
            styles={{
              control: (base, state) => ({
                ...base,
                border: this.state.error.chkoutAddressToState
                  ? '1px solid #e1656f'
                  : 'solid 1px rgba(175, 188, 198, 0.55)',
              }),
            }}
          />
        </div>
        <CheckoutFormInput
          error={this.state.error.chkoutAddressToZip}
          handleInputChange={this.handleInputChange('input')}
          handleValidation={this.handleValidation}
          name="chkoutAddressToZip"
          placeholder="ZIP Code"
          value={chkoutAddressToZip}
        />

        <Select
          // className={'dropdown-select__shipping'}
          onChange={(e, meta) => this.handleSelect(e, meta)}
          name="chkoutShippingMethod"
          options={ShippingMethodOptions}
          onBlur={e => this.handleBlur(e, 'chkoutShippingMethod')}
          placeholder={'Preferred Shipping Method'}
          classNamePrefix={'dropdown-select__shipping-error'}
          styles={{
            control: (base, state) => ({
              ...base,
              border: this.state.error.chkoutShippingMethod
                ? '1px solid #e1656f'
                : 'solid 1px rgba(175, 188, 198, 0.55)',
            }),
          }}
        />
        <div className="checkout__form--footer">
          <div className="checkout__form-button-group">
            <BackButton goBack={this.props.history.goBack} />
            <NextButton to="/enroll/order_processing" handleClick={this.handleClick} submit />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withApollo(ShippingAddressInfo)

import './PharmacyInfo.scss'

import React, { useCallback, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useHistory, useRouteMatch } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import { AsYouType } from 'libphonenumber-js'
import isNil from 'lodash/isNil'
import mapValues from 'lodash/mapValues'

import BackButton from './NewBackButton'
import NextButton from './NextButton'
import { validateField } from './validation'
import { initGAPageView } from '../../core/utils'
import { useGetPatientData } from '../../hooks'

import BackArrow from '../../assets/images/back-arrow.svg'
import RedirectWithQueryString from '../RedirectWithQueryString'

/**
 * @type {import('react').FunctionComponent<{ state: { saveInput: (values: object, label: string) => void } }>}
 */
const PharmacyInfo = ({ state }) => {
  const history = useHistory()
  const match = useRouteMatch()

  const { data: patientData, isValidating: patientDataValidating } = useGetPatientData()

  const [input, setInput] = useState({
    chkoutPharmacyName: null,
    chkoutPharmacyPhone: null,
    chkoutTransferAll: null,
    chkoutTransferMedications: null,
    chkoutPrescriberName: null,
    chkoutPrescriberPhone: null,
  })

  const [dirty, setDirty] = useState({
    chkoutPharmacyName: false,
    chkoutPharmacyPhone: false,
    chkoutTransferAll: false,
    chkoutTransferMedications: false,
    chkoutPrescriberName: false,
    chkoutPrescriberPhone: false,
  })

  const [chkoutRxAtPharmacy, setChkoutRxAtPharmacy] = useState(patientData?.pharmacy_name ? true : null)
  const [chkoutRxByDoctor, setChkoutRxByDoctor] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    initGAPageView(match.path)
  }, [match.path])

  /**
   * @type {import('react').ChangeEventHandler<HTMLInputElement>}
   */
  const handleMaterialChange = useCallback(
    ({ target: { name, type, value } }) => {
      setInput(input => ({
        ...input,
        [name]:
          type === 'radio'
            ? value === 'true'
            : (name === 'chkoutPharmacyPhone' || name === 'chkoutPrescriberPhone') && value.length > 9
            ? new AsYouType('US').input(value || '')
            : value,
      }))
      setDirty(dirty => ({
        ...dirty,
        [name]: true,
      }))
    },
    [setInput]
  )

  const defaultedInput = {
    chkoutPharmacyName: input.chkoutPharmacyName ?? patientData?.pharmacy_name ?? '',
    chkoutPharmacyPhone:
      input.chkoutPharmacyPhone ??
      (patientData?.pharmacy_phone && new AsYouType('US').input(patientData?.pharmacy_phone)) ??
      '',
    // chkoutTransferAll: input.chkoutTransferAll ?? isNil(patientData?.transfer_medications),
    chkoutTransferAll: false,
    chkoutTransferMedications: input.chkoutTransferMedications ?? patientData?.transfer_medications ?? '',
    chkoutPrescriberName: input.chkoutPrescriberName ?? '',
    chkoutPrescriberPhone: input.chkoutPrescriberPhone ?? '',
  }

  /**
   * @type {{ [key in keyof typeof defaultedInput]: boolean }}
   */
  const errors = Object.fromEntries(
    Object.entries(defaultedInput)
      .filter(([key]) => {
        if (chkoutRxAtPharmacy === null && chkoutRxByDoctor === null) {
          return true
        }

        if (chkoutRxAtPharmacy !== true && (key === 'chkoutPharmacyName' || key === 'chkoutPharmacyPhone')) {
          return false
        }

        if (chkoutRxByDoctor !== true && (key === 'chkoutPrescriberName' || key === 'chkoutPrescriberPhone')) {
          return false
        }

        if (defaultedInput.chkoutTransferAll === true && key === 'chkoutTransferMedications') {
          return false
        }

        return true
      })
      .map(([key, value]) => [key, validateField(key, value)])
      .filter(([, isError]) => isError === true)
  )

  if (patientDataValidating) {
    return null
  }

  if (chkoutRxAtPharmacy === null && chkoutRxByDoctor === null) {
    return (
      <>
        <div className="prescription-info_container">
          <p className="checkout__question-text">Do you have an existing prescription at another pharmacy?</p>
          <div className="checkout__question-button-container">
            <button className="checkout__question-button yes" onClick={() => setChkoutRxAtPharmacy(true)}>
              Yes
            </button>
            <button className="checkout__question-button no" onClick={() => setChkoutRxAtPharmacy(false)}>
              No
            </button>
          </div>
          <div className="checkout__question-button-container">
            <button className="checkout__question-button back" onClick={history.goBack}>
              <img src={BackArrow} className="checkout__back-button-arrow" alt="Go Back" />
            </button>
          </div>
        </div>
      </>
    )
  }

  if (chkoutRxAtPharmacy === false && chkoutRxByDoctor === null) {
    return (
      <>
        <div className="prescription-info_container">
          <p className="checkout__question-text">Do you have an open prescription from your doctor?</p>
          <div className="checkout__question-button-container">
            <button className="checkout__question-button yes" onClick={() => setChkoutRxByDoctor(true)}>
              Yes
            </button>
            <button className="checkout__question-button no" onClick={() => setChkoutRxByDoctor(false)}>
              No
            </button>
          </div>
          <div className="checkout__question-button-container">
            <button className="checkout__question-button back" onClick={() => setChkoutRxAtPharmacy(null)}>
              <img src={BackArrow} className="checkout__back-button-arrow" alt="Go Back" />
            </button>
          </div>
        </div>
      </>
    )
  }

  if ((!chkoutRxAtPharmacy && !chkoutRxByDoctor) || (chkoutRxAtPharmacy === false && chkoutRxByDoctor === false)) {
    return <RedirectWithQueryString to="/enroll/payment_info" />
  }

  return (
    <>
      {chkoutRxAtPharmacy ? (
        <>
          <TextField
            error={dirty.chkoutPharmacyName && errors.chkoutPharmacyName}
            onChange={handleMaterialChange}
            name="chkoutPharmacyName"
            placeholder="Current Pharmacy"
            label="Current Pharmacy"
            variant="outlined"
            value={defaultedInput.chkoutPharmacyName}
            disabled={!isNil(patientData?.pharmacy_name)}
          />
          <TextField
            error={dirty.chkoutPharmacyPhone && errors.chkoutPharmacyPhone}
            onChange={handleMaterialChange}
            name="chkoutPharmacyPhone"
            placeholder="Pharmacy Phone Number"
            label="Pharmacy Phone Number"
            variant="outlined"
            value={defaultedInput.chkoutPharmacyPhone}
            disabled={!isNil(patientData?.pharmacy_phone)}
          />
        </>
      ) : (
        <>
          <TextField
            error={dirty.chkoutPrescriberName && errors.chkoutPrescriberName}
            onChange={handleMaterialChange}
            name="chkoutPrescriberName"
            placeholder="Doctor Name"
            label="Doctor Name"
            variant="outlined"
            value={defaultedInput.chkoutPrescriberName}
          />
          <TextField
            error={dirty.chkoutPrescriberPhone && errors.chkoutPrescriberPhone}
            onChange={handleMaterialChange}
            name="chkoutPrescriberPhone"
            placeholder="Doctor Phone Number"
            label="Doctor Phone Number"
            variant="outlined"
            value={defaultedInput.chkoutPrescriberPhone}
          />
        </>
      )}

      {false && (
        // {isNil(patientData?.transfer_medications) && (
        // ATTENTION: this is being intentionally hidden for now.
        // More info at: https://truepill.atlassian.net/browse/VP-1153

        // If we're given the transfer_medications prefill param, we hide these
        // two options entirely, passing along `false` and the given value
        // respectively.
        <>
          <div className="checkout__transfer-all-rx-container">
            <label className="checkout__flow--input-label">
              Would you like to transfer all your current prescriptions?
            </label>
            <div className="checkout__radio-button-container">
              <label className="checkout__flow--input-label">Yes</label>
              <input
                checked={defaultedInput.chkoutTransferAll === true}
                onChange={handleMaterialChange}
                type="radio"
                value="true"
                name="chkoutTransferAll"
                disabled={!isNil(patientData?.transfer_medications)}
              />
              <label className="checkout__flow--input-label">No</label>
              <input
                checked={defaultedInput.chkoutTransferAll === false}
                onChange={handleMaterialChange}
                type="radio"
                value="false"
                name="chkoutTransferAll"
                disabled={!isNil(patientData?.transfer_medications)}
              />
            </div>
          </div>
        </>
      )}

      {!defaultedInput.chkoutTransferAll && (
        <TextField
          error={dirty.chkoutTransferMedications && errors.chkoutTransferMedications}
          onChange={handleMaterialChange}
          name="chkoutTransferMedications"
          placeholder="Please enter the medication(s) you wish to transfer separated by commas."
          variant="outlined"
          value={defaultedInput.chkoutTransferMedications}
          disabled={!isNil(patientData?.transfer_medications)}
          className="chkoutTransferMedicationsMultiline"
          multiline
        />
      )}

      <div className="checkout__form--footer">
        <div className="checkout__form-button-group">
          {isNil(patientData?.transfer_medications) && (
            <BackButton goBack={() => (chkoutRxAtPharmacy ? setChkoutRxAtPharmacy(null) : setChkoutRxByDoctor(null))} />
          )}
          <NextButton
            to="/enroll/payment_info"
            handleClick={e => {
              if (Object.keys(errors).length > 0) {
                e.preventDefault()
              }

              setDirty(dirty => mapValues(dirty, () => true))
              state.saveInput(defaultedInput, 'pharmacyInfo')
            }}
          />
        </div>
      </div>
    </>
  )
}

export default withApollo(PharmacyInfo)

import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Button, ProgressBar, Spacer, Text } from '@truepill/react-capsule'
import ReactGA from 'react-ga'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useLinkedCheckout } from '../../state/context'
import { UPDATE_URAC, NEXT_PAGE } from '../../state/reducer/actions'

import { RadioInput } from '../components/input'
import { CapsuleButtonContainer, ProgressBarContainer } from '../components/containers'
import { MultiSections, MultiSectionsProvider, MultiSectionsContext } from '../components/multiSections'
import { PatientUracAllergies, PatientUracConditions, PatientUracMedications, PatientUracPregnant } from '../validation'
import { getStepProgress } from '../helpers'
import { SECTION_NAMES } from '../../constants'

const defaultUracSections = [
  {
    uracKey: 'allergies',
    question: 'Do you have any allergies?',
    questionContext: 'Please list out the allergies.',
    questionToggleKey: 'chkoutToggleAllergies',
    questionAnswersKey: 'chkoutPatientAllergies',
    questionAnswersLabel: 'Allergies',
    questionAnswersPlaceholder: 'i.e. latex',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    needAdditionalAnswers: true
  },
  {
    uracKey: 'conditions',
    question: 'Do you have any health or medical conditions?',
    questionContext: 'Please list out your health conditions.',
    questionToggleKey: 'chkoutToggleMedicalHistory',
    questionAnswersKey: 'chkoutPatientMedicalHistory',
    questionAnswersLabel: 'Health or medical conditions',
    questionAnswersPlaceholder: 'i.e. high blood pressure',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    needAdditionalAnswers: true
  },
  {
    uracKey: 'medications',
    question: 'Are you taking any medications, vitamins, or supplements?',
    questionContext: 'Please list out any medications, vitamins, or supplements you are taking.',
    questionToggleKey: 'chkoutToggleMedications',
    questionAnswersKey: 'chkoutPatientMedications',
    questionAnswersLabel: 'Medications, vitamins, or supplements',
    questionAnswersPlaceholder: 'i.e. Vitamin C',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    needAdditionalAnswers: true
  },
  {
    uracKey: 'pregnancy',
    question: 'Are you pregnant, lactating, or planning to become pregnant in the next 90 days?',
    questionContext:
      'We use this information to ensure that mothers and their babies avoid adverse reactions to any prescribed medication.',
    questionToggleKey: 'chkoutTogglePregnancy',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
      { label: "I'm not sure", value: "I'm not sure" },
    ],
    needAdditionalAnswers: false
  },
]

const PatientUrac_ = () => {
  const { state, dispatch } = useLinkedCheckout()
  const {
    patientUrac,
    orderInfo,
    currentPage,
    orderInfo: { patientImageExists },
  } = state
  const { latestUracSection, uracFlow, isUracSurveyAnsweredBefore } = patientUrac
  const currentSectionIndex = uracFlow.findIndex(sectionKey => sectionKey === latestUracSection)
  const { openSection } = useContext(MultiSectionsContext)
  const [indexOnEdit, setIndexOnEdit] = useState(-1)
  const headerPage = isUracSurveyAnsweredBefore
    ? 'Has your health changed since your last refill?'
    : 'Before you checkout, we need more information about you.'
  const [indexFinishedSection, setIndexFinishedSection] = useState(currentSectionIndex === 0 ? -1 : currentSectionIndex)
  const uracSections = defaultUracSections.slice(0, uracFlow.length)

  const mapValidationToUracSection = () => {
    switch (latestUracSection) {
      case 'allergies':
        return PatientUracAllergies
      case 'conditions':
        return PatientUracConditions
      case 'medications':
        return PatientUracMedications
      case 'pregnancy':
        return PatientUracPregnant
    }
  }

  const isSectionValid = index => {
    if (!uracSections[index].questionAnswersKey) {
      return !!patientUrac[uracSections[index].questionToggleKey]
    }

    if (patientUrac[uracSections[index].questionToggleKey] === 'false') {
      return true
    } else {
      return patientUrac[uracSections[index].questionAnswersKey].length !== 0
    }
  }

  const {
    register,
    control,
    setValue,
    unregister,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(mapValidationToUracSection(latestUracSection)),
    defaultValues: patientUrac,
    shouldUnregister: true,
  })

  const onSubmit = openNextSection => {
    // Last URAC Section, Render Next Section

    const data = getValues()
    if (currentSectionIndex === uracFlow.length - 1) {
      dispatch({ type: UPDATE_URAC, payload: { ...data, latestUracSection } })
    } else {
      if (openNextSection) {
        const nextUracSection = uracFlow[currentSectionIndex + 1]
        dispatch({ type: UPDATE_URAC, payload: { ...data, latestUracSection: nextUracSection } })
        openSection(currentSectionIndex + 1)
      } else {
        dispatch({ type: UPDATE_URAC, payload: { ...data, latestUracSection } })
      }
    }
  }

  const handleContinueSection = () => {
    onSubmit(true)
    setIndexOnEdit(-1)
    setIndexFinishedSection(currentSectionIndex)
  }

  const handleProceed = () => {
    let isValid = true
    uracSections.map((section, index) => (isValid = isValid && isSectionValid(index)))

    if (currentSectionIndex === uracFlow.length - 1 && isValid) {
      ReactGA.event({
        category: 'linkedCheckout',
        action: 'Completed URAC Questions',
        label: 'completedUracQuestions',
      })

      dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.PATIENT_SHIPPING })
    }
  }

  const handleSetValue = (key, value) => {
    setValue(key, value)
    if ((key.includes('Toggle') && value !== 'true') || !uracSections[currentSectionIndex].needAdditionalAnswers) {
      onSubmit(true)
      setIndexFinishedSection(currentSectionIndex)
      setIndexOnEdit(-1)
    } else {
      onSubmit(false)
    }
  }

  const handleEditSection = index => {
    if (indexOnEdit >= 0 && !isSectionValid(indexOnEdit)) {
      return
    }

    setIndexOnEdit(index)
    const nextUracSection = uracFlow[index]
    dispatch({ type: UPDATE_URAC, payload: { ...patientUrac, latestUracSection: nextUracSection } })
  }

  const handleBack = () => {
    // If patient hasnt submitted a photo before and the order is controlled substance than show government ID upload
    if (!orderInfo?.patientImageExists && orderInfo?.orderHasControlledSubstance) {
      dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.PATIENT_UPLOAD })
    } else {
      dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.CONFIRM_PRESCRIPTIONS })
    }
  }

  useEffect(() => {
    if (indexOnEdit < 0) {
      return
    }
    openSection(indexOnEdit)
  }, [indexOnEdit])

  const renderUracSection = () => {
    return uracSections.map((urac, index) => {
      const isFinished = index <= indexFinishedSection
      return {
        title: urac.question,
        section: (
          <RadioInput
            key={urac.uracKey}
            register={register}
            control={control}
            radioValue={patientUrac[urac.questionToggleKey]}
            answers={patientUrac[urac.questionAnswersKey]}
            setValue={handleSetValue}
            unregister={unregister}
            errorToggle={errors[urac.questionToggleKey]}
            errorTextAnswers={urac.questionAnswersKey && errors[urac.questionAnswersKey]}
            isOpenResponse={!!urac.questionAnswersKey}
            onContinue={handleContinueSection}
            isOnEdit={indexOnEdit === index}
            isFinished={isFinished}
            {...urac}
          />
        ),
        trigger: isFinished ? (
          <Text
            underline
            color="primary"
            onClick={() => {
              handleEditSection(index)
            }}
          >
            Edit
          </Text>
        ) : null,
        autoToggle: false,
        alwaysOpen: isFinished,
        checked: isFinished,
      }
    })
  }

  return (
    <PatientUracContainer>
      <ProgressBarContainer>
        <ProgressBar value={getStepProgress(currentPage, patientImageExists)} />
      </ProgressBarContainer>
      <PatientUracFormStyles>
        <Text style={{ fontSize: '1.5rem' }} bold>
          {headerPage}
        </Text>
        <Spacer />
        <Text>We use this information to ensure that your medications are safe for you to take.</Text>
        <Spacer />
        <MultiSections defaultOpen={0} sections={renderUracSection()} />
      </PatientUracFormStyles>
      <Spacer size="md" axis="vertical" />
      <CapsuleButtonContainer>
        <Button type="button" variant="primary-text" onClick={handleBack}>
          Back
        </Button>
        <Button onClick={() => handleProceed()}>Continue</Button>
      </CapsuleButtonContainer>
    </PatientUracContainer>
  )
}

const PatientUrac = () => (
  <MultiSectionsProvider>
    <PatientUrac_ />
  </MultiSectionsProvider>
)
const PatientUracContainer = styled.div``

const PatientUracFormStyles = styled.div``

export default PatientUrac

export const TestSectionComponent = () => {
  const { openNextSection, openPreviousSection } = useContext(MultiSectionsContext)
  return (
    <div>
      <Button onClick={openPreviousSection}>Previous</Button>
      <Button onClick={openNextSection}>Next</Button>
    </div>
  )
}

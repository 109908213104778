import React from 'react'
import { Header, Spacer, Text, Button } from '@truepill/react-capsule'

import { CapsuleButtonContainer } from '../containers'

const InactivityModalContent = ({ title, description, minutes, seconds, onModalDismiss }) => (
  <>
    <Header bold variant="4xl">
      {title}
    </Header>
    <Spacer />
    <Text>{description}</Text>
    <Spacer size="lg" />
    <Text as="span">You will be signed out in:</Text>
    <Text bold as="span">
      {` ${minutes}:${seconds}.`}
    </Text>
    <Spacer size="lg" />
    <Text bold>Click Continue to stay signed in.</Text>
    <CapsuleButtonContainer>
      <Button size="sm" onClick={onModalDismiss}>
        Continue
      </Button>
    </CapsuleButtonContainer>
  </>
)

export default InactivityModalContent

import React from "react"

export const CheckIcon = () => {
  return (
    <div style={{ marginRight: '8px' }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
        width={20}
        height={20}
      >
        <circle cx={10} cy={10} r={10} fill="#127132" />
        <path
          d="M14 7.25l-5.5 5.5-2.5-2.5"
          fill="none"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

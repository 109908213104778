import React from 'react'
import styled from 'styled-components'

import BackArrow from '../../assets/images/back-arrow.svg'

const BackButton = ({ goBack }) => {
  const scrollToTop = () =>
    document.querySelector('#header').scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })

  const handleClick = () => {
    goBack()
    scrollToTop()
  }

  return (
    <Button onClick={handleClick}>
      <img src={BackArrow} alt="Go Back" />
    </Button>
  )
}

const Button = styled.div`
  background-color: #afbcc6;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 16px;

  &:hover {
    background-color: darken(#afbcc6, 10%);
    cursor: pointer;
  }

  img {
    width: 33px;
  }

  @media screen and (max-width: 767.99px) {
    width: 52px;
    height: 52px;

    img {
      border-radius: 30px;
      width: 15px;
    }
  }
`

export default BackButton

import React, { useEffect } from 'react'
import { Theme, Header, Spacer } from '@truepill/react-capsule'
import styled from 'styled-components'
import UracSpecialityLogo from '../../assets/images/urac-specialty-logo.png'
import UracMailLogo from '../../assets/images/urac-mail-logo.png'
import NabpLogo from '../../assets/images/nabp-logo.png'
import { breakpoint } from '../styledComponents/variables'
import { useSite } from '../../lib/Context/SiteContext'
import LinkedCheckoutFooter from '../../core/linked-checkout/common/footer'

import '@truepill/react-capsule/dist/default-theme.css'

const Container = styled.div`
  width: 100%;
  color: var(--cap-typography-dark);
  padding-bottom: 24px;
`

const HeaderContainer = styled.div`
  background-color: var(--cap-primary-300);
  height: 166px;
  font-size: 30px;
  padding: 0 30px;
  font-weight: bold;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  ${breakpoint.md} {
    height: 286px;
    font-size: 60px;
  }
`

const ContentContainer = styled.div`
  padding: 0 24px;
  overflow-wrap: break-word;
  margin: 48px auto;
  line-height: 24px;
  font-weight: 700px;
  font-style: normal;

  ${breakpoint.md} {
    max-width: 599px;
    margin: 88px auto;
    line-height: 26px;
    padding: 0;
  }
`

const Section = styled.div`
  margin-bottom: 32px;
`

const StyledSectionTitle = styled(Header)`
  color: var(--cap-primary-700);
`

const StyledList = styled.ul`
  list-style-type: disc;
  margin-left: 29px;
`

const ImageContainer = styled.div`
  margin-top: 90px;
  text-align: center;

  img {
    max-width: 20vh;
    margin-top: 20px;
    padding: 5px;
  }
`
const Paragraph = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: ${({ bold }) => bold ? 700 : 400};

  ${breakpoint.md} {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.25px;
  }
`

const ItalicParagraph = styled(Paragraph)`
  font-style: italic;
`

const SectionTitle = ({ children }) => (
  <>
    <StyledSectionTitle variant="3xl">{children}</StyledSectionTitle>
    <Spacer size="lg" />
  </>
)

const List = ({ children, ...props }) => <StyledList {...props}>{children}</StyledList>

const ListItem = ({ children }) => (
  <li>
    <Paragraph>{children}</Paragraph>
  </li>
)

const Link = styled.a`
  color: #d82184;
`

const NoticeOfPrivacyPolicyPage = () => {
  const { setSiteState } = useSite()

  useEffect(() => {
    setSiteState(prevState => ({ ...prevState, renderOldFooter: false }))
  }, [])

  return (
    <Theme>
      <Container>
        <HeaderContainer>Notice of HIPAA Privacy Policy</HeaderContainer>
        <ContentContainer>
          <Section>
            <SectionTitle>Your information. Your rights. Your responsibilities.</SectionTitle>
            <Paragraph>
              This notice describes how medical information about you may be used and disclosed and how you can get
              access to this information. Please review it carefully.
            </Paragraph>
          </Section>
          <Section>
            <SectionTitle>Overview of Your Rights</SectionTitle>
            <Paragraph bold>You have the right to:</Paragraph>
            <Spacer />
            <List>
              <ListItem>Get a copy of your paper or electronic medical record</ListItem>
              <ListItem>Request a correction to your paper or electronic medical record</ListItem>
              <ListItem>Request confidential communication</ListItem>
              <ListItem>Ask us to limit the information we share</ListItem>
              <ListItem>Get a list of those with whom we’ve shared your information</ListItem>
              <ListItem>Get a copy of this privacy notice</ListItem>
              <ListItem>Choose someone to act for you</ListItem>
              <ListItem>File a complaint if you believe your privacy rights have been violated</ListItem>
            </List>
          </Section>
          <Section>
            <SectionTitle>Overview of Your Choices</SectionTitle>
            <Paragraph bold>You have some choices in the way that we use and share information as we:</Paragraph>
            <Spacer />
            <List>
              <ListItem>Provide disaster relief</ListItem>
              <ListItem>Provide mental health care</ListItem>
              <ListItem>Market our services and sell your information</ListItem>
              <ListItem>Tell family and friends about your condition</ListItem>
              <ListItem>Raise funds</ListItem>
            </List>
          </Section>
          <Section>
            <SectionTitle>Overview of Our Uses and Disclosures</SectionTitle>
            <Paragraph italic bold>
              We may use and share your information as we:
            </Paragraph>
            <Spacer />
            <List>
              <ListItem>Treat you</ListItem>
              <ListItem>Run our organization</ListItem>
              <ListItem>Bill for your services</ListItem>
              <ListItem>Help with public health and safety issues</ListItem>
              <ListItem>Do research</ListItem>
              <ListItem>Comply with the law</ListItem>
              <ListItem>Respond to organ and tissue donation requests</ListItem>
              <ListItem>Work with a medical examiner or funeral director</ListItem>
              <ListItem>Address workers’ compensation, law enforcement, and other government requests</ListItem>
              <ListItem>Respond to lawsuits and legal actions</ListItem>
            </List>
          </Section>
          <Section>
            <SectionTitle>Your Rights in Detail</SectionTitle>
            <Paragraph>
              When it comes to your health information, you have certain rights. This section explains your rights and
              some of our responsibilities to help you.
            </Paragraph>
            <Spacer />
            <Paragraph bold>Get an electronic or paper copy of your medical record</Paragraph>
            <Spacer />
            <List>
              <ListItem>
                You can ask to see or get an electronic or paper copy of your medical record and other health
                information we have about you. Ask us how to do this.
              </ListItem>
              <ListItem>
                We will provide a copy or a summary of your health information, usually within 30 days of your request.
                We may charge a reasonable, cost-based fee.
              </ListItem>
            </List>
            <Spacer />
            <Paragraph bold>Ask us to correct your medical record</Paragraph>
            <Spacer />
            <List>
              <ListItem>
                You can ask us to correct health information about you that you think is incorrect or incomplete. Ask us
                how to do this.
              </ListItem>
              <ListItem>We may say “no” to your request, but we’ll tell you why in writing within 60 days.</ListItem>
            </List>
            <Spacer />
            <Paragraph bold>Request confidential communications</Paragraph>
            <Spacer />
            <List>
              <ListItem>
                You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a
                different address.
              </ListItem>
              <ListItem>We will say “yes” to all reasonable requests.</ListItem>
            </List>
            <Spacer />
            <Paragraph bold>Ask us to limit what we use or share</Paragraph>
            <Spacer />
            <List>
              <ListItem>
                You can ask us not to use or share certain health information for treatment, payment, or our operations.
                We are not required to agree to your request, and we may say “no” if it would affect your care.
              </ListItem>
              <ListItem>
                If you pay for a service or health care item out-of-pocket in full, you can ask us not to share that
                information for the purpose of payment or our operations with your health insurer. We will say “yes”
                unless a law requires us to share that information.
              </ListItem>
            </List>
            <Spacer />
            <Paragraph bold>Get a list of those with whom we’ve shared information</Paragraph>
            <Spacer />
            <List>
              <ListItem>
                You can ask for a list (accounting) of the times we’ve shared your health information for six years
                prior to the date you ask, who we shared it with, and why.
              </ListItem>
              <ListItem>
                We will include all the disclosures except for those about treatment, payment, and health care
                operations, and certain other disclosures (such as any you asked us to make). We’ll provide one
                accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one
                within 12 months.
              </ListItem>
            </List>
            <Spacer />
            <Paragraph bold>Get a copy of this privacy notice</Paragraph>
            <Spacer />
            <List>
              <ListItem>
                You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice
                electronically. We will provide you with a paper copy promptly.
              </ListItem>
            </List>
            <Spacer />
            <Paragraph bold>Choose someone to act for you</Paragraph>
            <Spacer />
            <List>
              <ListItem>
                If you have given someone medical power of attorney or if someone is your legal guardian, that person
                can exercise your rights and make choices about your health information.
              </ListItem>
              <ListItem>
                We will make sure the person has this authority and can act for you before we take any action.
              </ListItem>
            </List>
            <Spacer />
            <Paragraph bold>File a complaint if you feel your rights are violated</Paragraph>
            <Spacer />
            <List>
              <ListItem>
                You can complain if you feel we have violated your rights by contacting us at Privacy Officer at{' '}
                <Link href="mailto:complianceHL@truepill.com">ComplianceHL@truepill.com</Link> or{' '}
                <Link href="tel:+18335321368">(833)532-1368</Link>
              </ListItem>
              <ListItem>
                You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights
                by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling
                <Link href="tel:+18776966775"> 1-877-696-6775 </Link>, or visiting{' '}
                <Link href="https://www.hhs.gov/ocr/privacy/hipaa/complaints/" target="_blank">
                  www.hhs.gov/ocr/privacy/hipaa/complaints/
                </Link>
                .
              </ListItem>
              <ListItem>We will not retaliate against you for filing a complaint.</ListItem>
            </List>
          </Section>
          <Section>
            <SectionTitle>Your Choices in Detail</SectionTitle>
            <Paragraph>
              For certain health information, you can tell us your choices about what we share. If you have a clear
              preference for how we share your information in the situations described below, talk to us. Tell us what
              you want us to do, and we will follow your instructions.
            </Paragraph>
            <Spacer />
            <Paragraph bold>In these cases, you have both the right and choice to tell us to:</Paragraph>
            <Spacer />
            <List>
              <ListItem>Share information with your family, close friends, or others involved in your care</ListItem>
              <ListItem>Share information in a disaster relief situation</ListItem>
            </List>
            <Spacer />
            <ItalicParagraph>
              If you are not able to tell us your preference, for example if you are unconscious, we may go ahead and
              share your information if we believe it is in your best interest. We may also share your information when
              needed to lessen a serious and imminent threat to health or safety.
            </ItalicParagraph>
            <Spacer />
            <Paragraph bold>
              In these cases we never share your information unless you give us written permission:
            </Paragraph>
            <Spacer />
            <List>
              <ListItem>Marketing purposes</ListItem>
              <ListItem>Sale of your information</ListItem>
              <ListItem>Most sharing of psychotherapy notes</ListItem>
            </List>
            <Spacer />
            <Paragraph bold>In the case of fundraising:</Paragraph>
            <Spacer />
            <List>
              <ListItem>
                We may contact you for fundraising efforts, but you can tell us not to contact you again.
              </ListItem>
            </List>
          </Section>
          <Section>
            <SectionTitle>Our Uses and Disclosures in Detail</SectionTitle>
            <Paragraph bold>How do we typically use or share your health information?</Paragraph>
            <Spacer />
            <Paragraph>We typically use or share your health information in the following ways.</Paragraph>
            <Spacer />
            <Paragraph bold>Treat you</Paragraph>
            <Spacer />
            <Paragraph>
              We can use your health information and share it with other professionals who are treating you.
            </Paragraph>
            <Spacer />
            <ItalicParagraph>
              Example: A doctor treating you for an injury asks another doctor about your overall health condition.
            </ItalicParagraph>
            <Spacer />
            <Paragraph bold>Run our organization</Paragraph>
            <Spacer />
            <Paragraph>
              We can use and share your health information to run our practice, improve your care, and contact you when
              necessary.
            </Paragraph>
            <Spacer />
            <ItalicParagraph>
              Example: We use health information about you to manage your treatment and services.
            </ItalicParagraph>
            <Spacer />
            <Paragraph bold>Bill for your services</Paragraph>
            <Spacer />
            <Paragraph>
              We can use and share your health information to bill and get payment from health plans or other entities.
            </Paragraph>
            <Spacer />
            <ItalicParagraph>
              Example: We give information about you to your health insurance plan so it will pay for your services.
            </ItalicParagraph>
            <Spacer />
            <Paragraph bold>How else can we use or share your health information?</Paragraph>
            <Spacer />
            <Paragraph>
              We are allowed or required to share your information in other ways – usually in ways that contribute to
              the public good, such as public health and research. We have to meet many conditions in the law before we
              can share your information for these purposes. For more information see:{' '}
              <Link href="https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html" target="_blank">
                www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html
              </Link>
              .
            </Paragraph>
            <Spacer />
            <Paragraph bold>Help with public health and safety issues</Paragraph>
            <Spacer />
            <Paragraph>We can share health information about you for certain situations such as:</Paragraph>
            <Spacer />
            <List>
              <ListItem>Preventing disease</ListItem>
              <ListItem>Helping with product recalls</ListItem>
              <ListItem>Reporting adverse reactions to medications</ListItem>
              <ListItem>Reporting suspected abuse, neglect, or domestic violence</ListItem>
              <ListItem>Preventing or reducing a serious threat to anyone’s health or safety</ListItem>
            </List>
            <Spacer />
            <Paragraph bold>Do research</Paragraph>
            <Spacer />
            <Paragraph>We can use or share your information for health research.</Paragraph>
            <Spacer />
            <Paragraph bold>Comply with the law</Paragraph>
            <Spacer />
            <Paragraph>
              We will share information about you if state or federal laws require it, including with the Department of
              Health and Human Services if it wants to see that we’re complying with federal privacy law.
            </Paragraph>
            <Spacer />
            <Paragraph bold>Respond to organ and tissue donation requests</Paragraph>
            <Spacer />
            <Paragraph>We can share health information about you with organ procurement organizations.</Paragraph>
            <Spacer />
            <Paragraph bold>Work with a medical examiner or funeral director</Paragraph>
            <Spacer />
            <Paragraph>
              We can share health information with a coroner, medical examiner, or funeral director when an individual
              dies.
            </Paragraph>
            <Spacer />
            <Paragraph bold>Address workers’ compensation, law enforcement, and other government requests</Paragraph>
            <Spacer />
            <Paragraph>We can use or share health information about you:</Paragraph>
            <Spacer />
            <List>
              <ListItem>For workers’ compensation claims</ListItem>
              <ListItem>For law enforcement purposes or with a law enforcement official</ListItem>
              <ListItem>With health oversight agencies for activities authorized by law</ListItem>
              <ListItem>
                For special government functions such as military, national security, and presidential protective
                services
              </ListItem>
            </List>
            <Spacer />
            <Paragraph bold>Respond to lawsuits and legal actions</Paragraph>
            <Spacer />
            <Paragraph>
              We can share health information about you in response to a court or administrative order, or in response
              to a subpoena.
            </Paragraph>
            <Spacer />
            <Paragraph bold>Our Responsibilities</Paragraph>
            <Spacer />
            <List>
              <ListItem>
                We are required by law to maintain the privacy and security of your protected health information.
              </ListItem>
              <ListItem>
                We will let you know promptly if a breach occurs that may have compromised the privacy or security of
                your information.
              </ListItem>
              <ListItem>
                We must follow the duties and privacy practices described in this notice and give you a copy of it.
              </ListItem>
              <ListItem>
                We will not use or share your information other than as described here unless you tell us we can in
                writing. If you tell us we can, you may change your mind at any time. Let us know in writing if you
                change your mind.
              </ListItem>
            </List>
            <Spacer />
            <Paragraph>For more information see:</Paragraph>
            <Spacer />
            <Paragraph>
              <Link href="https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html" target="_blank">
                www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html
              </Link>
            </Paragraph>
          </Section>
          <Section>
            <SectionTitle>Changes to the Terms of this Notice</SectionTitle>
            <Paragraph>
              We can change the terms of this notice, and the changes will apply to all information we have about you.
              The new notice will be available upon request, in our office, and on our web site.
            </Paragraph>
          </Section>
          <Section>
            <SectionTitle>Other instructions for this notice</SectionTitle>
            <List>
              <ListItem>This notice is effective as of: June 24th, 2021</ListItem>
              <ListItem>
                If you have questions about these laws please contact the privacy officer at{' '}
                <Link href="mailto: ComplianceHL@truepill.com">ComplianceHL@truepill.com</Link> or{' '}
                <Link href="tel:+18335321368">(833) 532-1368</Link>
              </ListItem>
              <ListItem>We never market or sell personal information.</ListItem>
              <ListItem>
                If one of our providers believes that a patient/client is threatening serious bodily harm to another,
                the provider is required to take protective actions. These actions may include notifying the potential
                victim, contacting the police, and/or seeking hospitalization for the patient/client.
              </ListItem>
              <ListItem>
                If a patient/client presents a serious and credible threat to harm themselves, our providers may be
                obligated to seek hospitalization for them or to contact family members or others who can help provide
                protection.
              </ListItem>
              <ListItem>
                In most cases, if a patient/client is under the age of 18, our staff must have the consent of the
                parent/s or guardian/s to provide mental health treatment barring circumstances in which obtaining the
                guardian’s consent may cause significant harm, in which case we reserve the right to refer to
                alternative, specialized agencies. In cases which only one parent/guardian has custody or power for
                medical decision-making, we may need documentation substantiating such. It is the policy of our
                organization that parents are involved in treatment, with the caveat that an adolescent is able to
                express what they want this clinician to share. This will not always direct treatment, but it is at the
                discretion of the provider to keep information confidential with an adolescent to ensure the creation of
                a safe space for the younger individual and improve their treatment outcomes. However, if there is an
                immediate safety concern, the parents will be promptly notified.
              </ListItem>
              <ListItem>
                If applicable, insurance companies are made aware of diagnoses, visit types, lab results and other
                clinically necessary information for continued provision of services. Sometimes, insurance companies may
                contact the patient/client by mail, telephone or other means. In circumstances in which a patient/client
                is concerned about their confidentiality as it relates to their insurance company, it would be prudent
                for the patient/client to pay privately and not utilize their insurance benefits.
              </ListItem>
              <ListItem>
                If we are contacted by emergency room providers who disclose to us your incapacity or emergency
                circumstances, we will first reach out to you and your emergency contact on our file to verify the
                authenticity of such claim. If we are unsuccessful in this outreach, we may disclose protected health
                information (PHI) to the hospital personnel based on a determination using our professional judgment
                disclosing only information that is directly relevant to the outside contact’s involvement in your
                health care. We would not, however, disclose information unrelated to your emergency care. We will also
                use our professional judgment and our experience with common practice to make reasonable inferences of
                your best interest in allowing a person to pick up filled prescriptions, medical supplies, x-rays, or
                other similar forms of PHI.
              </ListItem>
              <ListItem>
                Appointment reminders: We may use or disclose your name and basic appointment information in providing
                you with appointment reminders (such as voice mails, e-mails, postcards, SMS messages, or letters) that
                may reference the practices of medicine or mental health services.
              </ListItem>
            </List>
          </Section>
          <ImageContainer>
            <a href="https://accreditnet2.urac.org//uracportal/Directory/CompanyView/12174" target="_blank">
              <img src={UracSpecialityLogo} />
            </a>
            <a href="https://accreditnet2.urac.org//uracportal/Directory/CompanyView/12173" target="_blank">
              <img src={UracMailLogo} />
            </a>
            <a
              href="https://nabp.pharmacy/programs/accreditations-inspections/digital-pharmacy/accredited-digital-pharmacies/"
              target="_blank"
            >
              <img src={NabpLogo} />
            </a>
          </ImageContainer>
        </ContentContainer>
      </Container>
      <LinkedCheckoutFooter />
    </Theme>
  )
}

export default NoticeOfPrivacyPolicyPage

import React from 'react'
import styled from 'styled-components'
import { TextField } from '@truepill/react-capsule'

export const DayInput = styled(TextField)`
padding-right: ${props => (props.state !== 'default' ? '36px' : 'auto')};
&:focus {
  padding-right: ${props => (props.state !== 'default' ? '36px' : 'auto')};
}
`

export const MonthInput = styled(TextField)`
padding-right: ${props => (props.state !== 'default' ? '36px' : 'auto')};
&:focus {
  padding-right: ${props => (props.state !== 'default' ? '36px' : 'auto')};
}
`
export const YearInput = styled(TextField)`
padding-right: ${props => (props.state !== 'default' ? '36px' : 'auto')};
&:focus {
  padding-right: ${props => (props.state !== 'default' ? '36px' : 'auto')};
}
`

import React, { useState } from 'react'
import { Header, Text, TextField, Button, ProgressBar, Toast } from '@truepill/react-capsule'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'

import { useLinkedCheckout } from '../../state/context'
import { UPDATE_PATIENT_IDENTITY, NEXT_PAGE, UPDATE_URAC, UPDATE_INSURANCES } from '../../state/reducer/actions'
import { validatePatientIdentity } from '../../state/api'

import { CapsuleButtonContainer, ProgressBarContainer } from '../components/containers'
import { YearInput, MonthInput, DayInput } from '../components/input'

import { PatientIdentityValidation } from '../validation'
import { device } from '../global/vars'
import { parseTextFieldStateForCapsule, getStepProgress } from '../helpers'
import { SECTION_NAMES } from '../../constants'


const DobVerification = () => {
  const { state, dispatch } = useLinkedCheckout()
  const [verificationError, setVerificationError] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    setFocus,
    setValue,
  } = useForm({
    resolver: yupResolver(PatientIdentityValidation),
    defaultValues: state?.patientIdentity,
    mode: 'onChange',
  })

  const {
    orderInfo: { orderToken, patientImageExists },
    patientUrac: { uracFlow },
    currentPage,
  } = state

  const onSubmit = async formState => {
    setVerificationError(false)
    const { month, year, day } = formState
    const normalizedMonth = parseInt(month) < 10 ? `0${parseInt(month)}` : month
    const normalizedDay = parseInt(day) < 10 ? `0${parseInt(day)}` : day

    // Format patient input into MM/DD/YYYY
    const patientDob = `${normalizedMonth}/${normalizedDay}/${year}`

    const patientInformation = {
      dob: patientDob,
      firstName: formState.firstName,
      lastName: formState.lastName,
    }

    const result = await validatePatientIdentity(orderToken, patientInformation)
    if (result.valid) {
      ReactGA.event({
        category: 'linkedCheckout',
        action: 'Confirmed Patient Verification',
        label: 'confirmedPatientVerification',
      })
      dispatch({
        type: UPDATE_PATIENT_IDENTITY,
        payload: {
          firstName: formState.firstName,
          lastName: formState.lastName,
          middleInitial: formState.middleInitial,
          dob: patientDob,
          month: normalizedMonth,
          day: normalizedDay,
          year,
          patient_verified: true,
        },
      })
      if (result.insurances) {
        dispatch({ type: UPDATE_INSURANCES, payload: result.insurances })
      }

      if (!_.isEmpty(result.patientSurvey)) {
        const { is_pregnant, known_allergies, medical_history, other_medications } = result.patientSurvey

        const chkoutToggleAllergies = known_allergies?.length > 0 ? 'true' : 'false'
        const chkoutPatientAllergies = known_allergies?.length > 0 ? known_allergies.split(',') : []
        const chkoutToggleMedicalHistory = medical_history?.length > 0 ? 'true' : 'false'
        const chkoutPatientMedicalHistory = medical_history?.length > 0 ? medical_history.split(',') : []

        const chkoutToggleMedications = other_medications?.length > 0 ? 'true' : 'false'
        const chkoutPatientMedications = other_medications?.length > 0 ? other_medications.split(',') : []
        const chkoutTogglePregnancy = is_pregnant ? 'true' : 'false'

        dispatch({
          type: UPDATE_URAC,
          payload: {
            chkoutToggleAllergies,
            chkoutPatientAllergies,
            chkoutToggleMedicalHistory,
            chkoutPatientMedicalHistory,
            chkoutToggleMedications,
            chkoutPatientMedications,
            chkoutTogglePregnancy,
            latestUracSection: uracFlow[uracFlow.length - 1],
            isUracSurveyAnsweredBefore: true,
          },
        })
      }

      dispatch({
        type: NEXT_PAGE,
        payload: SECTION_NAMES.CONFIRM_PRESCRIPTIONS
        })

    } else {
      setVerificationError(true)
    }
  }

  const monthRegister = register('month')
  const dayRegister = register('day')
  const yearRegister = register('year')

  return (
    <DobContainer>
      <ProgressBarContainer>
        <ProgressBar value={getStepProgress(currentPage, patientImageExists)} />
      </ProgressBarContainer>
      <Header variant="4xl">Please verify patient identity</Header>
      <Text>
        By verifying the patient’s legal name and date of birth, you’re helping us keep the patient's prescription information private.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <Text bold>Full legal name</Text>
          <NameWrapper>
            <FirstInitialWrap>
              <TextField
                label="First name"
                {...register('firstName')}
                required
                state={parseTextFieldStateForCapsule(errors.firstName, dirtyFields.firstName)}
              />
              <TextField
                label="Middle initial"
                {...register('middleInitial')}
                state={parseTextFieldStateForCapsule(errors.middleInitial, dirtyFields.middleInitial)}
              />
            </FirstInitialWrap>
            <TextField
              label="Last name"
              {...register('lastName')}
              required
              state={parseTextFieldStateForCapsule(errors.lastName, dirtyFields.lastName)}
            />
          </NameWrapper>
          <Text bold>Date of birth</Text>
          <DobSelector>

            <MonthInput
              label="Month"
              type="number"
              placeholder="MM"
              required
              {...monthRegister}
              state={parseTextFieldStateForCapsule(errors.month, dirtyFields.month)}
              pattern="\d*"
              onChange={(e) => {
                const monthValue = e.target.value.slice(0, 2)
                setValue('month', monthValue)
                const isMonthValid = parseInt(monthValue) > 0 && parseInt(monthValue) < 13
                const hasEnteredTwoDigits = monthValue.length === 2
                monthRegister.onChange(e)
                hasEnteredTwoDigits && isMonthValid && setFocus('day')
              }}
              min={1}
              max={12}
            />

            <DayInput
              label="Day"
              placeholder="DD"
              type="number"
              required
              {...dayRegister}
              state={parseTextFieldStateForCapsule(errors.day, dirtyFields.day)}
              pattern="\d*"
              onChange={(e) => {
                const dayValue = e.target.value.slice(0, 2)
                setValue('day', dayValue);
                const isValidDay = parseInt(dayValue) > 0 && parseInt(dayValue) < 32
                const hasEnteredTwoDigits = dayValue.length === 2
                dayRegister.onChange(e)
                hasEnteredTwoDigits && isValidDay && setFocus('year')
              }}
              min={1}
              max={31}
            />

            <YearInput
              label="Year"
              placeholder="YYYY"
              type="number"
              required
              {...yearRegister}
              state={parseTextFieldStateForCapsule(errors.year, dirtyFields.year)}
              pattern="\d*"
              onChange={(e) => {
                const yearValue = e.target.value.slice(0, 4)
                setValue('year', yearValue)
                yearRegister.onChange(e)
              }}
              min={1900}
              max={new Date().getFullYear()}
            />
          </DobSelector>
          <CapsuleButtonContainer>
            <Button type="submit">Continue</Button>
          </CapsuleButtonContainer>
        </FormContainer>
      </form>
      <Toast
        className="toast-message"
        onDismiss={() => setVerificationError(false)}
        position={{ vertical: 'top', horizontal: 'center' }}
        state={'error'}
        visible={verificationError}
        timeout={6000}
        onTimeout={() => setVerificationError(false)}
      >
        <ErrorMessageContainer>
          Unfortunately the information submitted could not be verified. Please try again or contact customer service
          (FPO)
        </ErrorMessageContainer>
      </Toast>
    </DobContainer>
  )
}

const DobContainer = styled.div`
  margin: 0 auto;
  p {
    margin: 1rem 0;
  }
`

const DobSelector = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  margin-bottom: 1rem;
`

const ErrorMessageContainer = styled.div``

const FormContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  input {
    margin: 1rem 0;
  }
`

const NameWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }
`

const FirstInitialWrap = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 1rem;
`

export default DobVerification

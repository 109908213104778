import React, { useState, useRef, useCallback } from 'react'
import { Header, Text, Button, ProgressBar, Spacer } from '@truepill/react-capsule'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import { useLinkedCheckout } from '../../state/context'
import { UPDATE_GOV_ID, NEXT_PAGE } from '../../state/reducer/actions'

import { CheckmarkIcon } from '../../assets/'

import { CapsuleButtonContainer, ProgressBarContainer } from '../components/containers'
import UploadBox from '../components/upload/UploadBox'

import { processFile } from '../../../../common/checkout/helpers'
import { getStepProgress } from '../helpers'
import { SECTION_NAMES } from '../../constants'

const infoBulletPoints = [
  'The ID is valid and unexpired',
  'You are providing proof of a driver’s license, passport, military ID, or permanent resident card',
  'Your name, date of birth, and photograph are clear and legible',
]

const PatientUpload = () => {
  const { state, dispatch } = useLinkedCheckout()
  const { patientGovId, currentPage, orderInfo: { patientImageExists } } = state

  const [webCamState, setWebCamState] = useState(false)
  const [showError, setShowError] = useState(false)
  const webcamRef = useRef(null)

  const capturePhoto = useCallback(() => {
    if (webcamRef.current) {
      const image = webcamRef.current.getScreenshot()
      if (image) {
        dispatch({ type: UPDATE_GOV_ID, payload: { fileBase64: image, fileName: 'governmentId.jpg' } })
      }
      setWebCamState(false)
    }
  }, [webcamRef, dispatch])

  const handleFileUpload = async file => {
    const newFile = await processFile(file)
    dispatch({ type: UPDATE_GOV_ID, payload: { fileBase64: newFile, fileName: file.name } })
  }

  const handleDelete = () => dispatch({ type: UPDATE_GOV_ID, payload: null })

  const handleSubmit = () => {
    if (!patientGovId) {
      setShowError(true)
    } else {
      setShowError(false)
      ReactGA.event({
        category: 'linkedCheckout',
        action: 'Completed Government ID Upload',
        label: 'completedGovIdUpload',
      })
      dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.PATIENT_URAC })
    }
  }

  const handleBack = () => {
    dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.CONFIRM_PRESCRIPTIONS })
  }

  return (
    <PatientUploadContainer>
      <ProgressBarContainer>
        <ProgressBar value={getStepProgress(currentPage, patientImageExists)} />
      </ProgressBarContainer>
      <Header variant="4xl">Government ID</Header>
      <Spacer size="md" axis="vertical" />
      <Text>
        We require a clear photo of your U.S. government-issued ID to verify your identity when dispensing controlled
        substances.
      </Text>
      <Spacer size="md" axis="vertical" />
      <UploadBox
        webCamState={webCamState}
        webcamRef={webcamRef}
        setWebCamState={setWebCamState}
        handleFileUpload={handleFileUpload}
        handleDelete={handleDelete}
        capturePhoto={capturePhoto}
        image={patientGovId}
      />
      <Spacer size="md" axis="vertical" />
      <Header variant="xl">Before we continue, please ensure:</Header>
      <Spacer size="md" axis="vertical" />
      <PatientUploadContent>
        {infoBulletPoints.map((info, index) => (
          <PatientImageInfo key={`image-info-${index}`}>
            <img src={CheckmarkIcon} alt="Government ID Info" />
            <Spacer size="xs" axis="horizontal" />
            <Text>{info}</Text>
            <Spacer size="sm" axis="vertical" />
          </PatientImageInfo>
        ))}
      </PatientUploadContent>
      <Spacer size="lg" axis="vertical" />
      <Text bold>Your ID will be stored in a secure manner and won't be used for any other purposes.</Text>
      <Spacer size="lg" axis="vertical" />
      <CapsuleButtonContainer>
        <Button variant="primary-text" onClick={handleBack}>
          Back
        </Button>
        <Button disabled={patientGovId === null} onClick={handleSubmit}>
          Continue
        </Button>
      </CapsuleButtonContainer>
      <Spacer size="md" axis="vertical" />
    </PatientUploadContainer>
  )
}

const PatientUploadContainer = styled.div``

const PatientUploadContent = styled.section``

const PatientImageInfo = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 0.5rem;
  }
`

export default PatientUpload

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useWatch } from 'react-hook-form'
import { Header, Text, Button, Spacer, TextField, Chip, Grid, GridItem } from '@truepill/react-capsule'

const pregnancyAnswers = {
  true: 'I am pregnant, lactating, or planning to become pregnant in the next 90 days',
  false: 'I am not pregnant, lactating, or planning to become pregnant in the next 90 days',
  "I'm not sure": 'I am unsure if i’ll be pregnant, lactating, or planning to become pregnant in the next 90 days',
}
const RadioInput = props => {
  const {
    register,
    unregister,
    control,
    radioValue,
    setValue,
    answers,
    isOpenResponse,
    errorTextAnswers,
    question,
    questionContext,
    questionToggleKey,
    questionAnswersKey,
    questionAnswersLabel,
    questionAnswersPlaceholder,
    options,
    onContinue,
    isFinished,
    isOnEdit,
  } = props
  const [textSearch, setTextSearch] = useState('')

  useEffect(() => {
    register(questionToggleKey)
    if (isOpenResponse) {
      register(questionAnswersKey)
    }
  }, [register, questionToggleKey, questionAnswersKey, isOpenResponse])

  const toggleValue = useWatch({
    name: questionToggleKey,
    control,
  })

  const answersValue = useWatch({
      name: questionAnswersKey,
      control,
    }) || [];

  const handleAdd = () => {
    if (textSearch) {
      setValue(questionAnswersKey, [...answersValue, textSearch])
      setTextSearch('')
    }
  }

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      handleAdd()
    }
  }

  const removeAnswer = targetIndex => {
    const filteredAnswers = answersValue.filter((answer, index) => targetIndex !== index)
    setValue(questionAnswersKey, filteredAnswers)
  }

  return (
    <>
      <Container hidden={!(isFinished && !isOnEdit)}>
        <AnswerContaner>
          <Text bold style={{ whiteSpace: 'nowrap' }}>
            {radioValue === 'true' ? 'Yes' : radioValue === 'false' ? 'No' : 'Not sure'}&nbsp;
          </Text>

          {questionToggleKey === 'chkoutTogglePregnancy' ? (
            <Text>{`- ${pregnancyAnswers[radioValue]}`}</Text>
          ) : (
            <>{radioValue === 'true' && <Text>{`- ${answers.join(', ')}`}</Text>}</>
          )}
        </AnswerContaner>
      </Container>
      <Container hidden={isFinished && !isOnEdit}>
        <RadioQuestionContainer>
          <RadioContainer>
            {options.map(({ label, value }) => (
              <Button
                type="button"
                key={value}
                onClick={() => setValue(questionToggleKey, value)}
                variant={toggleValue === value ? 'primary' : 'primary-outline'}
              >
                {label}
              </Button>
            ))}
          </RadioContainer>
        </RadioQuestionContainer>
        {toggleValue === 'true' && isOpenResponse && (
          <>
            <Spacer size="lg" axis="vertical" />
            <Text bold>{questionContext}</Text>
            <Text variant="body-sm">Click add after each entry</Text>
            <Spacer size="md" axis="vertical" />
            <Grid>
              <GridItem mobile={4} tablet={6} desktop={9}>
                <TextField
                  value={textSearch}
                  onKeyUp={handleKeyUp}
                  required
                  label={questionAnswersLabel}
                  type="search"
                  onChange={e => setTextSearch(e.target.value)}
                  placeholder={questionAnswersPlaceholder}
                  state={errorTextAnswers && answersValue.length === 0 ? 'error' : ''}
                />
              </GridItem>
              <GridItem mobile={4} tablet={2} desktop={3}>
                <AddBtnWrapper>
                  <Button className="full-width" onClick={handleAdd}>
                    Add
                  </Button>
                </AddBtnWrapper>
              </GridItem>
            </Grid>
            <Spacer size="md" axis="vertical" />
            {answersValue.map((answer, index) => (
              <Chip onDismiss={() => removeAnswer(index)} key={`chip-answer-${index}`}>
                {answer}
              </Chip>
            ))}
            <Spacer size="md" axis="vertical" />
            <SaveBtnWrapper>
              <Button
                onClick={() => {
                  onContinue()
                }}
                variant="primary"
                disabled={answersValue.length === 0}
              >
                Save & continue
              </Button>
            </SaveBtnWrapper>
          </>
        )}
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`

const RadioQuestionContainer = styled.div`
  width: 100%;
`

const RadioContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-content: space-around;
  grid-gap: 1rem;
  width: 100%;
`

const AnswerContaner = styled.div`
  display: flex;
  flex-direction: row;
`

const AddBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`

const SaveBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`

export default RadioInput

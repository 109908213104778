import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import initial from 'lodash/initial'
import isNil from 'lodash/isNil'
import last from 'lodash/last'
import mapValues from 'lodash/mapValues'
import { AsYouType } from 'libphonenumber-js'
import moment from 'moment'
import React, { useCallback, useState, useEffect, useContext } from 'react'
import { withApollo } from 'react-apollo'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import BackButton from './NewBackButton'
import NextButton from './NextButton'
import { validateField } from './validation'
import { initGAPageView } from '../../core/utils'
import { useGetPatientData } from '../../hooks'
import { EmployerContext } from '../../lib'
import RadioQuestion from './RadioQuestion'

/**
 * @type {import('react').FunctionComponent<{ state: { saveInput: (values: object, label: string) => void } }>}
 */
const PatientInfo = ({ state }) => {
  const { employer } = useContext(EmployerContext)
  const history = useHistory()
  const match = useRouteMatch()
  const { data: patientData, isValidating: patientDataValidating } = useGetPatientData()

  const isCircleMedical = employer.employerName === 'Circle Medical'

  const [input, setInput] = useState({
    chkoutCustomerFirstName: null,
    chkoutCustomerLastName: null,
    chkoutCustomerEmail: null,
    chkoutCustomerPhone: null,
    chkoutCustomerZip: null,
    chkoutPatientDob: null,
    chkoutPatientSex: null,
    ...(isCircleMedical && {
      chkoutToggleAllergies: null,
      chkoutToggleMedications: null,
      chkoutToggleMedicalHistory: null,
      chkoutPatientAllergies: null,
      chkoutPatientMedications: null,
      chkoutPatientMedicalHistory: null,
    }),
  })

  const [dirty, setDirty] = useState({
    chkoutCustomerFirstName: false,
    chkoutCustomerLastName: false,
    chkoutCustomerEmail: false,
    chkoutCustomerPhone: false,
    chkoutCustomerZip: false,
    chkoutPatientDob: false,
    chkoutPatientSex: false,
    ...(isCircleMedical && {
      chkoutToggleAllergies: null,
      chkoutToggleMedications: null,
      chkoutToggleMedicalHistory: null,
      chkoutPatientAllergies: null,
      chkoutPatientMedications: null,
      chkoutPatientMedicalHistory: null,
    }),
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    initGAPageView(match.path)
  }, [match.path])

  /**
   * @type {import('react').ChangeEventHandler<HTMLInputElement>}
   */
  const handleInputChange = useCallback(
    ({ target: { name, value } }) => {
      const uracKeys = ['chkoutToggleAllergies', 'chkoutToggleMedications', 'chkoutToggleMedicalHistory']
      setInput(input => ({
        ...input,
        [name]:
          name === 'chkoutCustomerPhone' && value.length > 9
            ? new AsYouType('US').input(value)
            : name === 'chkoutPatientDob' && value.length === 3 && value.split('/').length < 2
            ? `${initial(value).join('')}/${last(value)}`
            : name === 'chkoutPatientDob' && value.length === 6 && value.split('/').length < 3
            ? `${initial(value).join('')}/${last(value)}`
            : uracKeys.includes(name)
            ? value === 'true'
            : value,
      }))
      setDirty(dirty => ({
        ...dirty,
        [name]: true,
      }))
    },
    [setDirty, setInput]
  )

  if (patientDataValidating) {
    return null
  }

  const defaultedInput = {
    chkoutCustomerFirstName: input.chkoutCustomerFirstName ?? patientData?.patient?.first_name ?? '',
    chkoutCustomerLastName: input.chkoutCustomerLastName ?? patientData?.patient?.last_name ?? '',
    chkoutCustomerEmail: input.chkoutCustomerEmail ?? patientData?.patient_email ?? '',
    chkoutCustomerPhone: input.chkoutCustomerPhone ?? new AsYouType('US').input(patientData?.patient_phone || '') ?? '',
    chkoutCustomerZip: input.chkoutCustomerZip ?? '',
    chkoutPatientDob:
      input.chkoutPatientDob ??
      (typeof patientData?.patient?.dob !== 'undefined' ? moment(patientData.patient.dob).format('MM/DD/YYYY') : ''),
    chkoutPatientSex: input.chkoutPatientSex ?? patientData?.patient?.sex ?? '',
    ...(isCircleMedical && {
      chkoutToggleAllergies: input.chkoutToggleAllergies ?? '',
      chkoutToggleMedications: input.chkoutToggleMedications ?? '',
      chkoutToggleMedicalHistory: input.chkoutToggleMedicalHistory ?? '',
      ...(input.chkoutToggleAllergies && { chkoutPatientAllergies: input.chkoutPatientAllergies ?? '' }),
      ...(input.chkoutToggleMedications && { chkoutPatientMedications: input.chkoutPatientMedications ?? '' }),
      ...(input.chkoutToggleMedicalHistory && { chkoutPatientMedicalHistory: input.chkoutPatientMedicalHistory ?? '' }),
    }),
  }

  /**
   * @type {{ [key in keyof typeof defaultedInput]: boolean }}
   */
  const errors = Object.fromEntries(
    Object.entries(defaultedInput)
      .map(([key, value]) => [key, validateField(key, value)])
      .filter(([, isError]) => isError === true)
  )

  return (
    <>
      <TwoColumnRow>
        <TextField
          error={dirty.chkoutCustomerFirstName && errors.chkoutCustomerFirstName}
          name="chkoutCustomerFirstName"
          onChange={handleInputChange}
          value={defaultedInput.chkoutCustomerFirstName}
          disabled={!isNil(patientData?.patient?.first_name)}
          label="First Name"
          variant="outlined"
        />
        <TextField
          error={dirty.chkoutCustomerLastName && errors.chkoutCustomerLastName}
          onChange={handleInputChange}
          name="chkoutCustomerLastName"
          label="Last Name"
          variant="outlined"
          value={defaultedInput.chkoutCustomerLastName}
          disabled={!isNil(patientData?.patient?.last_name)}
        />
      </TwoColumnRow>
      <TextField
        error={dirty.chkoutCustomerEmail && errors.chkoutCustomerEmail}
        onChange={handleInputChange}
        name="chkoutCustomerEmail"
        value={defaultedInput.chkoutCustomerEmail}
        disabled={!isNil(patientData?.patient_email)}
        label="Email"
        variant="outlined"
        fullWidth
      />
      <TwoColumnRow>
        <TextField
          error={dirty.chkoutCustomerPhone && errors.chkoutCustomerPhone}
          onChange={handleInputChange}
          name="chkoutCustomerPhone"
          type="tel"
          label="Phone Number"
          value={defaultedInput.chkoutCustomerPhone}
          disabled={!isNil(patientData?.patient_phone)}
          variant="outlined"
        />
        <TextField
          error={dirty.chkoutCustomerZip && errors.chkoutCustomerZip}
          onChange={handleInputChange}
          name="chkoutCustomerZip"
          label="Zip Code"
          value={defaultedInput.chkoutCustomerZip}
          variant="outlined"
          type="tel"
        />
      </TwoColumnRow>
      <TextField
        error={dirty.chkoutPatientDob && errors.chkoutPatientDob}
        onChange={handleInputChange}
        name="chkoutPatientDob"
        label="D.O.B. (mm/dd/yyyy)"
        value={defaultedInput.chkoutPatientDob}
        disabled={!isNil(patientData?.patient?.dob)}
        variant="outlined"
      />
      <SexDropDown variant="outlined">
        <InputLabel htmlFor="sex-select">Sex</InputLabel>
        <Select
          name="chkoutPatientSex"
          error={dirty.chkoutPatientSex && errors.chkoutPatientSex}
          value={defaultedInput.chkoutPatientSex}
          disabled={!isNil(patientData?.patient?.sex)}
          onChange={handleInputChange}
          input={<OutlinedInput labelWidth={25} id="sex-select" />}
          variant="outlined"
        >
          <MenuItem value="" disabled>
            Select Sex
          </MenuItem>
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
        </Select>
      </SexDropDown>
      {isCircleMedical && (
        <UracContainer>
          <RadioQuestion
            questionText="Any Allergies?"
            errorToggle={errors.chkoutToggleAllergies}
            toggleName="chkoutToggleAllergies"
            textAreaInputName="chkoutPatientAllergies"
            textAreaValue={defaultedInput.chkoutPatientAllergies}
            textAreaPlaceholder="Please enter all of your allergies separated by a comma."
            errorTextArea={errors.chkoutPatientAllergies && dirty.chkoutPatientAllergies}
            toggleValue={defaultedInput.chkoutToggleAllergies}
            handleInputChange={handleInputChange}
          />
          <RadioQuestion
            questionText="Current Medications?"
            errorToggle={errors.chkoutToggleMedications}
            toggleName="chkoutToggleMedications"
            textAreaInputName="chkoutPatientMedications"
            textAreaPlaceholder="Please enter all of your medications separated by a comma."
            textAreaValue={defaultedInput.chkoutPatientMedications}
            errorTextArea={errors.chkoutPatientMedications && dirty.chkoutPatientMedications}
            toggleValue={defaultedInput.chkoutToggleMedications}
            handleInputChange={handleInputChange}
          />
          <RadioQuestion
            questionText="Any medical conditions or history to share?"
            errorToggle={errors.chkoutToggleMedicalHistory}
            toggleName="chkoutToggleMedicalHistory"
            textAreaInputName="chkoutPatientMedicalHistory"
            textAreaPlaceholder="Please enter all of your medical conditions separated by a comma."
            textAreaValue={defaultedInput.chkoutPatientMedicalHistory}
            errorTextArea={errors.chkoutPatientMedicalHistory && dirty.chkoutPatientMedicalHistory}
            toggleValue={defaultedInput.chkoutToggleMedicalHistory}
            handleInputChange={handleInputChange}
          />
        </UracContainer>
      )}
      <PBuffer>
        Your information is used to match your prescription(s) on file and to send you updates on the status of your
        order, including emails and/or text messages. We will never share or sell your information. By using this
        service, you are opting in to receive communications regarding your order.
      </PBuffer>
      <CheckoutFooter>
        <ButtonWrapper>
          <BackButton goBack={history.goBack} />
          <NextButton
            to={isCircleMedical ? '/enroll/patient_photo' : '/enroll/additional_info/'}
            handleClick={e => {
              if (Object.keys(errors).length > 0) {
                e.preventDefault()
              }

              setDirty(dirty => mapValues(dirty, () => true))
              state.saveInput(defaultedInput, 'patientInfo')
            }}
          />
        </ButtonWrapper>
      </CheckoutFooter>
    </>
  )
}

const PBuffer = styled.p`
  /* This is an arbitrary value that lets the text fit neatly on three lines. */
  font-size: 95%;
  margin-top: 1em;
`

const TwoColumnRow = styled.div`
  display: flex;
  justify-content: space-between;
  .MuiTextField-root {
    width: 48.75%;
  }
`

const CheckoutFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const SexDropDown = styled(FormControl)`
  margin-top: 1rem !important;
  fieldset {
    z-index: 1;
  }
`

const ButtonWrapper = styled.div`
  margin: 51.6px 0px 28px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

const UracContainer = styled.div`
  margin-top: 1rem;
`

export default withApollo(PatientInfo)

export default {
  resolvers: {
    Mutation: {
      handleInput: (_, { targetName, value }, { cache }) => {
        const data = { [targetName]: value }
        cache.writeData({ data })
        return null
      },
      toggleCheckbox: (_, { targetName, value }, { cache }) => {
        const data = { [targetName]: value }
        cache.writeData({ data })
        return null
      },
    },
  },
}

import styled from 'styled-components'

import { breakpoint } from '../styledComponents/variables'

export default styled.button`
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.13);
  background-color: ${props => (props.disabled ? 'lightgrey' : '#44bdd0')};
  display: ${props => (props.disabled ? 'none !important' : 'block')};
  padding: 1em 4em;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  margin: ${props => (props.centered ? '0 auto' : '0')};
  font-size: 20px;
  font-family: CircularStd;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  user-select: none;

  :hover {
    background-color: #2da2b4;
  }

  ${breakpoint.short} {
    height: 45px;
    font-size: 16px;
  }
`

import React, { useContext } from 'react'

import OrderCompleteCheckmark from '../../assets/images/order-complete-check.svg'
import CheckoutCertifications from '../../common/checkout/CheckoutCertifications'
import CheckoutQuestions from '../../common/checkout/CheckoutQuestions'
import { EmployerContext } from '../../lib'

import './CopaySuccess.scss'

const CopaySuccess = props => {
  const {
    employer: { email },
  } = useContext(EmployerContext)

  function mailSupport() {
    window.location.href = `mailto:${email}?subject=Question About My Order`
  }

  const statusMessage = () => {
    if (props.location.state.status === 'paid') {
      return <h2>Your prescription is on the way</h2>
    }
    if (props.location.state.status === 'no-payment') {
      return (
        <h3>
          You have confirmed that you do not want any of your medications at this time. We will reach out to when your
          refills are ready.
        </h3>
      )
    }
  }
  window.scrollTo(0, 0)
  return (
    <div className="copay-success">
      <img alt="Order Complete!" className="copay-success__checkmark-img" src={OrderCompleteCheckmark} />
      <h1>Thanks, {props.location.state.name}!</h1>
      {statusMessage()}
      <h3>Have a question?</h3>
      <button onClick={mailSupport}>Contact Support</button>
      <CheckoutCertifications />
      <CheckoutQuestions />
    </div>
  )
}

export default CopaySuccess

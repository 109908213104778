import React from 'react'
import styled from 'styled-components'

import { RefillImage } from '../../images'
import { breakpoint } from '../../styledComponents/variables'

const RefillExperience = () => {
  return (
    <RefillExperienceContainer>
      <h1>A 1-Click Refill Experience</h1>
      <h2>We will notify you each month to confirm your medication delivery</h2>
      <img src={RefillImage} alt="refill-prescription" />
    </RefillExperienceContainer>
  )
}

const RefillExperienceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding: 1rem 1rem 0 1rem;
  max-width: 575px;

  h1 {
    font-size: 26px;
    margin: 1rem 0;
  }
  h2 {
    font-size: 14px;
    color: rgba(40, 48, 51, 0.65);
    margin-bottom: 1rem;
    line-height: 1.25em;
  }
  img {
    max-width: 95vw;
  }

  ${breakpoint.md} {
    max-width: 767px;

    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 16px;
    }
    img {
      max-width: 70vw;
    }
  }

  ${breakpoint.lg} {
    max-width: 992px;

    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 18px;
    }
    img {
      max-width: 55vw;
    }
  }

  ${breakpoint.xl} {
    max-width: 1200px;

    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 16px;
    }
  }
`

export default RefillExperience

import { createContext } from 'react'

import { DEFAULT_EMPLOYER_DETAILS } from '../../constants'

export default createContext({
  employer: DEFAULT_EMPLOYER_DETAILS,
  /** @param {string} name */
  setEmployerName: name => {
    console.error('Employer name set with empty context:', name)
  },
})

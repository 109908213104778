import gql from 'graphql-tag'

const ClientGetCurrentDrugDispenseDetails = gql`
  {
    chkoutSelectedStrength @client
    chkoutSelectedQuantity @client
    chkoutPurchasePrice @client
    chkoutShippingSurcharge @client
  }
`

export default ClientGetCurrentDrugDispenseDetails

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import HowTruepillWorks from './HowTruepillWorksSection/HowTruepillWorks'
import MedicationFAQs from './FAQSection/MedicationFAQs'
import Testimonials from './TestimonySection/Testimonials'
import RefillExperience from './RefillExperienceSection/RefillExperience'
import TruepillMap from './TruepillMapSection/TruepillMap'
import AboutTruepillPharmacy from './AboutTruepillSection/AboutTruepillPharmacy'
import { LandingImage } from '../images'
import { Button, ImageContainer } from '../styledComponents'
import { initGAPageView } from '../../core/utils'
import { PBM_IDS, UNIQUE_EMPLOYER_NAMES_BY_PBM } from '../../constants'
import {
  LandingPageContainer,
  LandingPageHeroContainer,
  LandingPageHero,
  SizedImg,
  LandingPageHeader,
  LandingButtonContainer,
} from '../styledComponents/landingPage'

const RxSenseLandingPage = props => {
  useEffect(() => {
    initGAPageView(props.match.path)
  }, [props.match.path])

  return (
    <>
      <LandingPageContainer>
        <LandingPageHeroContainer>
          <LandingPageHero>
            <LandingPageHeader>
              <h1>
                Welcome to Your Preferred
                <br />
                Home Delivery Program
              </h1>
              <h2>Never wait in line at a pharmacy again</h2>
              <LandingButtonContainer>
                <Button
                  as={Link}
                  to={`/employers/${encodeURIComponent(UNIQUE_EMPLOYER_NAMES_BY_PBM[PBM_IDS.RX_SENSE])}`}
                >
                  Enroll Today
                </Button>
              </LandingButtonContainer>
            </LandingPageHeader>
            <ImageContainer>
              <SizedImg src={LandingImage} alt="pbm-experience" />
            </ImageContainer>
          </LandingPageHero>
        </LandingPageHeroContainer>
      </LandingPageContainer>
      <HowTruepillWorks />
      <AboutTruepillPharmacy />
      <TruepillMap />
      <Testimonials />
      <RefillExperience />
      <MedicationFAQs />
    </>
  )
}

export default RxSenseLandingPage

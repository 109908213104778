import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { combineSearchStrings } from '../lib'

const RedirectWithQueryString = ({ to, ...props }) => {
  const { search } = useLocation()

  return (
    <Redirect
      {...props}
      to={{ ...(typeof to === 'string' ? { pathname: to } : to), search: combineSearchStrings(search, to.search) }}
    />
  )
}
export default RedirectWithQueryString

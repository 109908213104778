import React, { Component } from 'react'

import ArrowDown from '../../assets/images/arrow-down.png'
import Close from '../../assets/images/close.png'

class Question extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 85,
      isActive: false,
    }
  }

  handleClick = () => {
    this.setState({
      isActive: !this.state.isActive,
    })
  }

  render() {
    const { body, title } = this.props
    const { isActive } = this.state
    const activeTag = isActive ? 'active' : ''
    return (
      <div className={`faq-question__container ${activeTag}`} onClick={this.handleClick}>
        <div className="faq-question__header-container">
          <h1 className={`faq-question__title ${activeTag}`}>{title}</h1>
          <img
            className={`faq-question__header-icon ${isActive ? 'open' : 'closed'}`}
            alt={isActive ? 'Close' : 'Open'}
            src={isActive ? Close : ArrowDown}
          />
        </div>
        <div className={`faq-question__body ${activeTag}`}>{body}</div>
      </div>
    )
  }
}

export default Question

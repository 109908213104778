import React from 'react'
import styled from 'styled-components'
import { Header, Text, Spacer, Theme } from '@truepill/react-capsule'
import '@truepill/react-capsule/dist/default-theme.css'
import LinkedCheckoutFooter from '../linked-checkout/common/footer'

export const OrderAlreadyProcessed = ({ phone }) => {
  return (

      <Theme>
        <LinkedCheckoutContainer>
          <DobContainer>
          <Spacer size="2xl" />
            <Header variant="4xl">This link is no longer valid.</Header>
            <Text style={{ fontSize: '1.2rem', marginTop: '16px' }} >
              If you need help placing a new order, please contact customer support at <TelNoWrap href={`tel:${phone}`}> {phone}</TelNoWrap>

            </Text>
          </DobContainer>
        </LinkedCheckoutContainer>
        <LinkedCheckoutFooter />
      </Theme>

  )
}

const TelNoWrap = styled.a`
  white-space:nowrap;
`

const DobContainer = styled.div`
  width: 40%;
  @media (max-width: 768px) {
    width: 85%;
  }
`

const LinkedCheckoutContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 500px
  font-family: CircularStd;
  flex-direction: column;
  height: 80vh;
`

import './OrderComplete.scss'

import React, { useContext } from 'react'

import OrderCompleteCheckmark from '../../assets/images/order-complete-check.svg'
import { initGAPageView } from '../../core/utils/index'
import { EmployerContext } from '../../lib'

import CheckoutCertifications from './CheckoutCertifications'
import CheckoutQuestions from './CheckoutQuestions'

const EnrollmentComplete = props => {
  const {
    employer: { email, phone, employerName },
  } = useContext(EmployerContext)

  const mailToEmail = employerName ? `mailto:${email}?subject=${employerName}` : `mailto:${email}`

  window.scrollTo(0, 0)
  const { pathname, search } = props.history.location
  initGAPageView(pathname + search)

  return (
    <React.Fragment>
      <div className="checkout__container">
        <div className="order-complete__container">
          <img alt="Order Complete!" className="order-complete__checkmark-img" src={OrderCompleteCheckmark} />
          <p className="order-complete__text-top" style={{ fontSize: '22px' }}>
            It doesn’t look like you have any current prescriptions but wish to enroll in home delivery. Our Customer
            Team will reach out soon to help.
          </p>
          <p className="order-complete__text">
            Still have questions?
            <p style={{ fontSize: '16px', marginTop: '1rem' }}>
              Please call us at <a href={`tel:${phone}`}>{phone}</a>
            </p>
          </p>
          <a href={mailToEmail} className="support-button">
            <span className="support-button__text">Contact Support</span>
          </a>
        </div>
      </div>
      <CheckoutCertifications />
      <CheckoutQuestions />
    </React.Fragment>
  )
}

export default EnrollmentComplete

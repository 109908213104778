import * as yup from 'yup'

const validationSchema = {
  name: yup.string().min(1).required(),
  street1: yup.string().min(1).required(),
  city: yup.string().min(1).required(),
  state: yup.string().min(2).required(),
  zip: yup.string().min(5).required(),
  shippingMethod: yup.string().min(3).required(),
  knownAllergies: yup.boolean().required(),
  otherMedications: yup.boolean().required(),
  medicalHistory: yup.boolean().required(),
  consent: yup.boolean().required().oneOf([true]),
}

const validateField = (name, validationValue) => {
  const valid = validationSchema[name].isValidSync(validationValue)
  return !valid
}

const validateFields = fields => {
  const results = {}
  let hasErrors = false

  Object.entries(fields).forEach(field => {
    const [key, value] = field
    const valid = validationSchema[key].isValidSync(value)

    if (!valid) {
      results[key] = !valid
    }

    const isInvalid = Object.values(results).some(value => value)
    if (isInvalid) {
      hasErrors = true
    }
  })

  return { results, hasErrors }
}

export { validateField, validateFields }

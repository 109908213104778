import { useContext } from 'react'
import { useGetPatientData } from '.'
import { EmployerContext } from '../lib'

import { PBM_IDS, UNIQUE_EMPLOYER_NAMES_BY_PBM } from '../constants'

export default function useRxDetailsNeeded() {
  const patient = useGetPatientData()
  const {
    employer: { employerName },
  } = useContext(EmployerContext)

  if (employerName === UNIQUE_EMPLOYER_NAMES_BY_PBM[PBM_IDS.CIRCLE_MEDICAL]) {
    return false
  }

  return !(patient?.data?.pharmacy_name && patient?.data?.pharmacy_phone && patient?.data?.transfer_medications)
}

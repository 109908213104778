import React from 'react'
import styled from 'styled-components'

import { breakpoint } from '../../styledComponents/variables'

const AboutTruepillItem = ({ data: { icon, title, subtitle, altkey } }) => (
  <AboutTruepillItemContainer>
    <AboutTruepillIconContainer>
      <img src={icon} alt={altkey} />
    </AboutTruepillIconContainer>
    <AboutTruepillTextContainer>
      <h1>{title}</h1>
      <p>{subtitle}</p>
    </AboutTruepillTextContainer>
  </AboutTruepillItemContainer>
)

const AboutTruepillItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem 0;
  padding: 0 2rem;

  ${breakpoint.lg} {
    max-width: 992px;
  }

  ${breakpoint.xl} {
    max-width: 1200px;
  }
`

const AboutTruepillIconContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 75vw;
    max-width: 50px;
  }
`

const AboutTruepillTextContainer = styled.div`
  padding: 1rem 0 1rem 2rem;

  h1 {
    font-size: 20px;
    margin: 0;
    padding-bottom: 1rem;
    text-align: left;
  }
  p {
    text-align: left;
    line-height: 1.25em;
  }
`
export default AboutTruepillItem

import React from 'react'
import currency from 'currency.js'

import { BaseMedContainer, MedHeaderContainer } from '../containers'
import { Checkbox, Text, Spacer } from '@truepill/react-capsule'

const ActiveMedCard = ({ setConfirmedMeds, medication, index }) => {
  const updateConfirmMed = () => {
    setConfirmedMeds(prevMeds => {
      const updateMeds = [...prevMeds]

      updateMeds[index].patient_confirmed = !updateMeds[index].patient_confirmed

      return updateMeds
    })
  }

  return (
    <BaseMedContainer onClick={updateConfirmMed}>
      <Checkbox onChange={updateConfirmMed} checked={medication.patient_confirmed} />
      <MedHeaderContainer>
        <Text bold>{medication.medication_name}</Text>
        <Text>{`Qty: ${medication.quantity} • Days Supply: ${medication.days_supply}`}</Text>
        <Text>{`Refills: ${medication.num_refills_remaining}`}</Text>
        <Spacer size="lg" />
        <Text bold>{`${currency(medication.copay_price).format()}`}</Text>
      </MedHeaderContainer>
    </BaseMedContainer>
  )
}

export default ActiveMedCard

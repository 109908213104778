import React, { Fragment } from 'react'

import { RouteConfig } from '../../core/routing'

import CheckoutCertifications from './CheckoutCertifications'
import CheckoutQuestions from './CheckoutQuestions'
import CheckoutStepTracker from './CheckoutStepTracker'

const CheckoutFlowSidebySide = props => {
  return (
    <Fragment>
      <div className="checkout__form--container">
        <RouteConfig {...props} />
      </div>
    </Fragment>
  )
}

const CheckoutContainer = props => {
  let location = ''
  if (props.location.pathname.includes('patient')) {
    location = 'patient'
  } else if (props.location.pathname.includes('pharmacy')) {
    location = 'prescription'
  } else if (props.location.pathname.includes('shipping')) {
    location = 'shipping'
  } else if (props.location.pathname.includes('payment')) {
    location = 'insurance'
  }
  return (
    <Fragment>
      <div className="checkout__heading-container">
        <h3 className="checkout__form--heading">Home Delivery Enrollment</h3>
        <p className="checkout__intro-text">Please enter your {location} information</p>
      </div>
      <CheckoutStepTracker {...props} />
      <div className="checkout__container">
        <CheckoutFlowSidebySide {...props} />
      </div>
      <CheckoutCertifications />
      <CheckoutQuestions />
    </Fragment>
  )
}

export default CheckoutContainer

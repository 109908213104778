import React from 'react'
import styled from 'styled-components'

import HowTruepillWorksItem from './HowTruepillWorksItem'
import { EnrollImage, PrescriptionImage, DeliveryImage } from '../../images'
import { breakpoint } from '../../styledComponents/variables'

const HowTruepillWorks = () => {
  const itemsData = [
    {
      text: `Enrolling takes less than one minute`,
      image: EnrollImage,
      altKey: 'pbm-enroll',
    },
    {
      text: `Truepill receives your existing prescription`,
      image: PrescriptionImage,
      altKey: 'pbm-prescription',
    },
    {
      text: `Get your meds delivered to your door`,
      image: DeliveryImage,
      altKey: 'pbm-delivery',
    },
  ]

  return (
    <HowTruepillWorksContainer>
      <h2>How it works</h2>
      <HowTruepillWorksItemsContainer>
        {itemsData.map(({ text, image, altKey }, i) => (
          <HowTruepillWorksItem text={text} image={image} alt={altKey} key={`how_truepill_works_item_${i}`} />
        ))}
      </HowTruepillWorksItemsContainer>
    </HowTruepillWorksContainer>
  )
}

const HowTruepillWorksContainer = styled.div`
  background-color: rgba(68, 189, 208, 0.05);
  padding: 2rem;

  h2 {
    padding-top: 2rem;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
  }

  ${breakpoint.xl} {
    h2 {
      font-size: 28px;
    }
  }
`

const HowTruepillWorksItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: 575px;

  ${breakpoint.lg} {
    max-width: 992px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  ${breakpoint.xl} {
    max-width: 1200px;
  }
`

export default HowTruepillWorks

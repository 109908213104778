import React from 'react'
import { Header, Spacer, Text } from '@truepill/react-capsule'
import styled from 'styled-components'

import { EditIcon } from '../../../assets'

import { useLinkedCheckout } from '../../../state/context'

const EditShippingCard = ({ setAddingShipping }) => {
  const { state } = useLinkedCheckout()
  const { patientShipping } = state
  const { address, address2, city, state: usState, zip } = patientShipping

  return (
    <EditCardContainer>
      <CardInfoContainer>
        <Header bold variant="2xl">
          Shipping Address
        </Header>
        <Spacer size="sm" axis="vertical" />
        <CardMetadata>
          <Text>{`${address} ${address2}`}</Text>
          <Text>{`${city}, ${usState} ${zip}`}</Text>
        </CardMetadata>
      </CardInfoContainer>
      <EditIconContainer onClick={() => setAddingShipping(true)}>
        <img src={EditIcon} alt="Edit Shipping Address" />
      </EditIconContainer>
    </EditCardContainer>
  )
}

const EditCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7fcff;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  padding: 2rem;
`

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const CardMetadata = styled.div`
  display: flex;
  flex-direction: column;
`

const EditIconContainer = styled.div`
  cursor: pointer;
`

export default EditShippingCard

import React from 'react'
import { Header, Text, Button, ProgressBar, Spacer, Grid, GridItem } from '@truepill/react-capsule'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import { useLinkedCheckout } from '../../state/context'
import { NEXT_PAGE } from '../../state/reducer/actions'

import { ProgressBarContainer, CapsuleButtonContainer } from '../components/containers'
import { InsuranceCard } from '../components/card'
import { getStepProgress } from '../helpers'
import { SECTION_NAMES } from '../../constants'

const ReviewInsurance = () => {
  const { state, dispatch } = useLinkedCheckout()
  const { patientOrderInsurances, orderInfo: { patientImageExists }, currentPage } = state

  const handleNext = () => {
    ReactGA.event({
      category: 'linkedCheckout',
      action: 'Reviewed Insurance Information',
      label: 'reviewedInsurance',
    })

    dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.PATIENT_PAYMENT })
  }

  const handleBack = () => {
    dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.PATIENT_SHIPPING })
  }

  return (
    <ReviewInsuranceContainer>
      <ProgressBarContainer>
        <ProgressBar value={getStepProgress(currentPage, patientImageExists)} />
      </ProgressBarContainer>
      <Header variant="4xl">Here’s the insurance information we have on file for you.</Header>
      <Spacer size="md" axis="vertical" />
      <Text>
        We used this insurance to calculate your copay. If you would like to use a different insurance plan, please
        contact customer support at{' '}
        <PhoneLink href="tel:+18332696909">
          <Text bold underline as="span">
            1 (833) 269-6909
          </Text>
        </PhoneLink>
        .
      </Text>
      <Spacer size="md" axis="vertical" />

      <Grid spacing="md">
        {patientOrderInsurances.map(insurance => (
          <GridItem mobile={4} tablet={4} desktop={6} key={insurance.cardholderId}>
            <InsuranceCard insurance={insurance} />
          </GridItem>
        ))}
      </Grid>

      <Spacer size="md" axis="vertical" />
      <CapsuleButtonContainer>
        <Button variant="primary-text" onClick={handleBack}>
          Back
        </Button>
        <Button onClick={handleNext}>Continue</Button>
      </CapsuleButtonContainer>
    </ReviewInsuranceContainer>
  )
}

const ReviewInsuranceContainer = styled.div`
  margin: 0 auto;
`
const PhoneLink = styled.a`
  color: var(--cap-text-body)
`;

export default ReviewInsurance

import React, { Fragment } from 'react'

const PatientInfoToggleSection = ({
  errorBorder,
  handleInputChange,
  handleToggle,
  handleValidation,
  inputName,
  inputValue,
  label,
  parent,
  placeholder,
  showInput,
  toggleName,
}) => (
  <Fragment>
    <div className="checkout__toggle-row">
      <div className="checkout__label-row">
        <label className="checkout__flow--input-label">
          {label}
          <FieldRequiredIndicator showError={parent.state.input[toggleName] !== null} />
        </label>
      </div>
      <div className="checkout__radio-input-container">
        <div className="checkout__radio-input-option">
          <label className="checkout__flow--input-label">Yes</label>
          <input
            checked={showInput === true}
            onChange={e => {
              handleToggle(e)
              handleValidation(e)
            }}
            type="radio"
            value
            name={toggleName}
          />
        </div>
        <div className="checkout__radio-input-option">
          <label className="checkout__flow--input-label">No</label>
          <input
            checked={showInput === false}
            onChange={e => {
              handleToggle(e)
              handleValidation(e)
              parent.setState(prevState => ({
                error: {
                  ...prevState.error,
                  [inputName]: null,
                },
              }))
            }}
            type="radio"
            value={false}
            name={toggleName}
          />
        </div>
      </div>
    </div>
    {showInput ? (
      <textarea
        className={`checkout__form--textarea ${errorBorder ? 'error' : ''}`}
        name={inputName}
        onBlur={handleValidation}
        onChange={handleInputChange}
        placeholder={placeholder}
        value={inputValue}
      />
    ) : null}
  </Fragment>
)

const FieldRequiredIndicator = ({ showError }) => {
  if (showError) return null
  return (
    <Fragment>
      <span className="field-required__indicator">*</span>
      <span className="field-required__indicator-text">required</span>
    </Fragment>
  )
}

export default PatientInfoToggleSection

/**
 * @typedef Employer
 * @type {object}
 * @property {string} employerName
 * @property {string} employerLogo
 * @property {string[]} requiredFields
 * @property {string} customerToken
 * @property {string} phone
 * @property {string} email
 * @property {string} baseUrl
 */

/**
 * References
 * https://github.com/truepill/pharmacy-connect/blob/master/src/utilities/alternateConfigurations.ts
 *
 * TODO: import from module instead of copying. Need to fix webpack for ->
 * Module parse failed: Unexpected character '#' (1:0)
 */
export const PBM_IDS = {
  TRUESCRIPTS: 1,
  TRANSPARENT_RX: 2,
  GOOD_RX: 3,
  SOUTHERN_SCRIPTS: 4,
  SINGLE_CARE: 5,
  TRUEPILL_DIRECT_CUSTOMER: 6,
  COVID_19: 7,
  OPTUM_RX: 8,
  RX_SENSE: 9,
  LIFESCAN: 10,
  RX_SAVINGS: 11,
  SOHO_MD: 12,
  CIRCLE_MEDICAL: 16,
  SEMPRE_HEALTH: 17,
  FOUNDPBM: 18,
  SOUTHERN_SCRIPTS_LC: 19,
}

/**
 * For each special case where a PBM corresponds with a single employer, there's
 * an entry here that can be used to set the employer name based off the PBM ID
 * from an order.
 *
 * @todo Replace these with values that come from the database.
 * @type {{ [k in PBM_IDS[keyof PBM_IDS]]: string }}
 */
export const UNIQUE_EMPLOYER_NAMES_BY_PBM = {
  [PBM_IDS.COVID_19]: 'Covid19i',
  [PBM_IDS.RX_SAVINGS]: 'Rx Home Delivery',
  [PBM_IDS.RX_SENSE]: 'RxSense',
  [PBM_IDS.SOHO_MD]: 'SohoMD',
  [PBM_IDS.CIRCLE_MEDICAL]: 'Circle Medical',
  [PBM_IDS.SEMPRE_HEALTH]: 'Sempre Health',
  [PBM_IDS.FOUNDPBM]: 'FoundPBM',
  [PBM_IDS.SOUTHERN_SCRIPTS_LC]: 'Southern Scripts LC',
}

/**
 * Values here are added to the employer context object (overriding any existing
 * values from the employer) if the name matches the key.
 * @type {{ [employerName: string]: Partial<Employer> }}
 */
export const EMPLOYER_OVERRIDES_BY_NAME = {
  [UNIQUE_EMPLOYER_NAMES_BY_PBM[PBM_IDS.RX_SAVINGS]]: {
    phone: '1 (800) 268-4476',
    email: 'support@rxsavingssolutions.com',
    baseUrl: '/enroll/patient_info',
  },
}

/**
 * @type {Employer}
 */
export const DEFAULT_EMPLOYER_DETAILS = {
  employerName: '',
  employerLogo: 'https://s3.amazonaws.com/onboarding-assets.truepill.com/truepill-blue.png',
  requiredFields: [],
  customerToken: null,
  phone: '1 (833) 860-1057',
  email: 'mailorder@truepill.com',
  baseUrl: '/',
}

import React, { Fragment } from 'react'

import HipaaLogo from '../../assets/images/hipaa-logo-certificate@3x.png'
import VippsLogo from '../../assets/images/vipps-logo-2017@3x.png'
import LegitScriptLogo from '../../assets/images/legit-script-h-wbg-1@3x.png'

const CertLogoGroup = () => {
  return (
    <Fragment>
      <div className="checkout__form-logo-group-header">Truepill has earned the following pharmacy certifications</div>
      <div className="checkout__form-logo-group">
        <img className="checkout__form-logo" id="form-logo-hipaa" src={HipaaLogo} alt="HIPAA" />
        <img className="checkout__form-logo" id="form-logo-vipps" src={VippsLogo} alt="VIPPS" />
        <img className="checkout__form-logo" id="form-logo-legitscript" src={LegitScriptLogo} alt="LegitScript" />
      </div>
    </Fragment>
  )
}

export default CertLogoGroup

import React, { useLayoutEffect } from 'react'
import { Theme, Header, Spacer, Text } from '@truepill/react-capsule'
import { useSite } from '../../lib/Context/SiteContext'
import { useLocation } from "react-router-dom"
import {
  Container,
  HeaderContainer,
  ContentContainer,
  Section,
  IndexList,
  Paragraph,
  SectionTitle,
  SectionSubTitle,
  IndexLink,
  List,
  ListItem
} from './styledContainers'
import '@truepill/react-capsule/dist/default-theme.css'

import LinkedCheckoutFooter from '../../core/linked-checkout/common/footer'

const PrivacyPolicyPage = () => {

  const { setSiteState } = useSite()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search);
  const isLinkedCheckout = urlParams.get('isLinkedCheckout')

  useLayoutEffect(() => {
    if (isLinkedCheckout) {
      setSiteState(prevState => ({ ...prevState, renderOldFooter: false }))
    }
  }, [setSiteState])

  return (
    <Theme>
      <Container>
        <HeaderContainer>
          <Header variant="6xl">Privacy Policy</Header>
        </HeaderContainer>

        <ContentContainer isLinkedCheckout={isLinkedCheckout}>
          <Spacer size="lg" />
          <Text size="caption">Last Updated: November 21, 2021</Text>
          <Spacer size="lg" />
          <Paragraph bold>
            The Services are not intended for use in the event of an emergency or other urgent situations. If you
            believe you may have a medical emergency, call 911 or your local emergency medical system immediately.
          </Paragraph>
          <Paragraph>
            This Privacy Policy explains how information about you is collected, used and disclosed by Truepill, Inc.
            and our corporate affiliates and subsidiaries (collectively, “Truepill”, “we”, “us” or “our”). This Privacy
            Policy applies to information we collect when you use our website (the “Website”); and any other services
            owned or operated by Truepill, Inc. which link to this Privacy Policy (together with the Website, the
            “Services”).
          </Paragraph>
          <Paragraph>
            We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the
            date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding
            a statement to our homepage or sending you a notification). We encourage you to review the Privacy Policy
            whenever you access the Services or otherwise interact with us to stay informed of our information practices
            and the ways you can help protect your privacy.
          </Paragraph>
          <Paragraph bold>
            By accessing or using the Services, you consent to our collection, use, and sharing of your information in
            accordance with this Privacy Policy. This Privacy Policy is not a contract and does not create any
            contractual rights or obligations.
          </Paragraph>

          <SectionSubTitle>Table of Contents</SectionSubTitle>
          <IndexList>
            <IndexLink href="#online-service">Collection of Information</IndexLink>
            <IndexLink href="#use-of-info">Use of Information</IndexLink>
            <IndexLink href="#sharing-of-info">Sharing of Information</IndexLink>
            <IndexLink href="#third-party-analytics">Third-Party Analytics</IndexLink>
            <IndexLink href="#link-to-third-party">Links to Third Party Sites</IndexLink>
            <IndexLink href="#security">Security</IndexLink>
            <IndexLink href="#how-we-respond">How We Respond to “Do Not Track” Signals</IndexLink>
            <IndexLink href="#children-under-13">Children under the Age of 13</IndexLink>
            <IndexLink href="#note-to-international">Note to International Users</IndexLink>
            <IndexLink href="#your-choice">Your Choices</IndexLink>
            <IndexLink href="#contact-us">Contact Us</IndexLink>{' '}
          </IndexList>
          <Spacer size="lg" />
          <Section id="online-service">
            <SectionTitle>1. Collection of Information</SectionTitle>
            <Paragraph>
              As used in this Privacy Policy, “Personal Information” means any information that can be used to
              individually identify a person or household, and may include, but is not limited to, name, email address,
              postal or other physical address, title, and other information reasonably linkable to an individual or
              household with or without other identifying information in our possession. Personal Information also
              includes information relating to a person’s professional background, and may include, but is not limited
              to, resume, educational history, professional specialty, professional licenses and/or certifications,
              geographic areas of professional practice, legal claims relating to professional background, and years of
              professional experience.
            </Paragraph>
            <SectionSubTitle>Information You Provide to Us </SectionSubTitle>
            <Paragraph>
              We collect information you provide directly to us. For example, we collect information when you create an
              account, participate in any interactive features of the Services, fill out a form, request customer
              support, apply for a job with us, or otherwise communicate with us.
            </Paragraph>
            <Paragraph>The types of information we may collect from you include:</Paragraph>
            <List>
              <ListItem>
                <Text bold as="span">
                  Account Information:
                </Text>{' '}
                When you register for an account on our Services, you will need to provide us with certain Personal
                Information to complete the registration, including information that can be used to contact or identify
                you, which may include your name, email address, username, password, and phone number.
              </ListItem>
              <ListItem>
                <Text bold as="span">
                  Health Information:
                </Text>{' '}
                We collect general health information from you regarding eligibility or diagnosis for treatment.
              </ListItem>
              <ListItem>
                <Text bold as="span">
                  Insurance Information:
                </Text>{' '}
                We may collect information such as your BIN, PCN and cardholder ID.
              </ListItem>
              <ListItem>
                <Text bold as="span">
                  Payment Information:
                </Text>{' '}
                We collect information regarding your payment methods.
              </ListItem>
              <ListItem>
                <Text bold as="span">
                  Other Information You Choose to Provide:
                </Text>{' '}
                We may collect additional information that you provide, such as when you request technical or customer
                support, communicate with us, or apply for a job with us.
              </ListItem>
            </List>
            <SectionSubTitle>Information We Collect When You Use the Services</SectionSubTitle>
            <Paragraph>
              When you access or use our Services, we automatically collect information about you, including:
            </Paragraph>
            <List>
              <ListItem>
                <Text bold as="span">
                  Usage Information:
                </Text>{' '}
                We collect information about your activity on our Services. For example, we may collect routine
                information about how our site is used including pages viewed and server side events (i.e. API requests
                and responses).
              </ListItem>
              <ListItem>
                <Text bold as="span">
                  Log Information:
                </Text>{' '}
                We may collect log information about your use of the Services, including the type of browser you use,
                access times, pages viewed and visited, and your IP address.
              </ListItem>
              <ListItem>
                <Text bold as="span">
                  Information Collected by Cookies and Other Tracking Technologies:
                </Text>{' '}
                We and our service providers may use various technologies to collect information, including cookies and
                web beacons. Cookies are small data files stored on your hard drive or in device memory that help us
                improve our Services and your experience, see which areas and features of our Services are popular, and
                count visits. Web beacons are electronic images that may be used on our Services or emails to help
                deliver cookies, count visits, and understand usage and campaign effectiveness. For more information
                about cookies, and how to disable them, please see “Your Choices” below.
              </ListItem>
            </List>
            <SectionSubTitle>Information We Collect from Other Sources</SectionSubTitle>
            <Paragraph>
              We may obtain information from other sources and use it as contemplated herein, including to provide the
              Services to and otherwise communicate with you.
            </Paragraph>
          </Section>
          <Section id="use-of-info">
            <SectionTitle>2. Use of Information</SectionTitle>
            <Paragraph>We may use information about you for various purposes, including to:</Paragraph>

            <List>
              <ListItem>Provide, maintain and improve our Services;</ListItem>
              <ListItem>
                Provide and deliver the products and services you request, and send you related information;
              </ListItem>
              <ListItem>
                Send you technical notices, updates, security alerts, and support and administrative messages;
              </ListItem>
              <ListItem>Respond to your comments, questions, and requests, and provide customer service;</ListItem>
              <ListItem>
                Communicate with you about products, services, offers, promotions, rewards, and events offered by
                Truepill and others, and provide information we think will be of interest to you;
              </ListItem>
              <ListItem>Monitor and analyze trends, usage, and activities in connection with our Services;</ListItem>
              <ListItem>
                Detect, investigate, and prevent fraudulent transactions and other illegal activities and protect the
                rights and property of Truepill and others; and
              </ListItem>
              <ListItem>Carry out any other purpose for which the information was collected.</ListItem>
            </List>
            <Paragraph>
              We also may use aggregated or de-identified information, which cannot reasonably be used to identify you,
              for various purposes, including, for example, to improve our automation and improve care. Once aggregated
              or de-identified, the data does not personally identify you, is no longer personal information, and is not
              subject to this Privacy Policy.
            </Paragraph>
          </Section>
          <Section id="sharing-of-info">
            <SectionTitle>3. Sharing of Information</SectionTitle>
            <Paragraph>
              We may share information about you as follows or as otherwise described in this Privacy Policy:
            </Paragraph>
            <List>
              <ListItem>
                With vendors, consultants and other service providers, including, but not limited to, hosting providers
                and payment processors, who need access to such information to carry out work on our behalf;
              </ListItem>
              <ListItem>
                In response to a request for information if we believe disclosure is in accordance with, or required by,
                any applicable law, regulation or legal process;
              </ListItem>
              <ListItem>With other users of the Services; </ListItem>
              <ListItem>
                If we believe your actions are inconsistent with our user agreements or policies, or to protect the
                rights, property and safety of Truepill or others;
              </ListItem>
              <ListItem>
                In connection with, or during negotiations of, any merger, sale of company assets, financing, or
                acquisition of all or a portion of our business by another company;
              </ListItem>
              <ListItem>To prevent, investigate, or take action against violation of the Terms of Use;</ListItem>
              <ListItem>
                Between and among Truepill and its current and future parents, affiliates, subsidiaries and other
                companies under common control and ownership; and
              </ListItem>
              <ListItem>With your consent or at your direction.</ListItem>
            </List>
            <Paragraph>
              We may also sell or share aggregated or de-identified information, which cannot reasonably be used to
              identify you.
            </Paragraph>
          </Section>
          <Section id="third-party-analytics">
            <SectionTitle>4. Third-Party Analytics</SectionTitle>
            <Paragraph>
              From time to time, we may partner with certain third parties, such as Google Analytics, to collect,
              analyze, and use some of the information described above. For example, we may allow third parties to set
              cookies or use web beacons on the Services or in email communications from Truepill. The information
              collected by third parties using these technologies may be used to engage in analysis and reporting. These
              third parties may set and access cookies on your computer or other device and may collect information
              about your online activities across different websites or services over time, including on websites and
              mobile applications that are not owned or operated by Truepill. You may review Google Analytics’ security
              and privacy principles by clicking{' '}
              <a href="https://policies.google.com/technologies/partner-sites" target="_blank">
                here
              </a>{' '}
              here and opt out of the use of cookies in web browsers by Google Analytics by clicking{' '}
              <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
                here
              </a>
              .
            </Paragraph>
          </Section>
          <Section id="link-to-third-party">
            <SectionTitle>5. Links to Third Party Sites</SectionTitle>
            <Paragraph>
              Our Services may contain links to other websites. Other websites may also reference or link to our
              Services. These other websites are not controlled by Truepill. We encourage our users to be aware when
              they leave our Services to read the privacy policies of each and every web site that collects personally
              identifiable information. We do not endorse, screen, or approve, and are not responsible for, the privacy
              practices or content of such other websites or mobile applications. Visiting these other websites is at
              your own risk.
            </Paragraph>
          </Section>
          <Section id="security">
            <SectionTitle>6. Security</SectionTitle>
            <Paragraph>
              While Truepill takes reasonable measures to help protect information about you from loss, theft, misuse
              and unauthorized access, disclosure, alteration, and destruction, please be advised that when information
              is transmitted over the internet, it cannot be guaranteed to be completely secure.
            </Paragraph>
          </Section>
          <Section id="how-we-respond">
            <SectionTitle>7. How We Respond to “Do Not Track” Signals</SectionTitle>
            <Paragraph>
              Some web browsers have “Do Not Track” or similar features that allow you to tell each website you visit
              that you do not want your activities on that website tracked. At present, our Services do not respond to
              “Do Not Track” signals and consequently, the Services will continue to collect information about you even
              if your browser’s “Do Not Track” feature is activated.
            </Paragraph>
          </Section>
          <Section id="children-under-13">
            <SectionTitle>8. Children under the Age of 13</SectionTitle>
            <Paragraph>
              Our Website is not intended for children under 13 years of age. No one under age 13 may provide any
              information to or on the Website. We do not knowingly collect personal information from children under 13.
              If you are under 13, do not use or provide any information on this Website or on or through any of its
              features/register on the Website, make any purchases through the Website, use any of the interactive or
              public comment features of this Website or provide any information about yourself to us, including your
              name, address, telephone number, email address, or any screen name or user name you may use. If we learn
              we have collected or received personal information from a child under 13 without verification of parental
              consent, we will delete that information. If you believe we might have any information from a child under
              13, please contact us at <a href="mailto:privacy@truepill.com">privacy@truepill.com</a>.
            </Paragraph>
          </Section>
          <Section id="note-to-international">
            <SectionTitle>9. Note to International Users</SectionTitle>
            <Paragraph>
              The Services are intended for use in the United States of America only. If you access our Services or
              contact us from outside of the United States of America, please be advised that (i) any information you
              provide to us or that we automatically collect will be transferred to the United States of America; and
              (ii) that by accessing our Services or submitting information, you explicitly authorize its transfer to
              and subsequent processing in the United States of America in accordance with this Privacy Policy.
            </Paragraph>
          </Section>
          <Section id="your-choice">
            <SectionTitle>10. Your Choices</SectionTitle>
            <SectionSubTitle>Account Information</SectionSubTitle>
            <Paragraph>
              You may update or correct certain information about you at any time by logging into your account or by
              emailing us at <a href="mailto:support@truepill.com">support@truepill.com</a>. If you wish to deactivate
              your account, you may do so by reaching out to{' '}
              <a href="mailto:complianceHL@truepill.com">complianceHL@truepill.com</a>, but note that we may retain
              certain information as required by law or for legitimate business purposes. We may also retain cached or
              archived copies of information about you for a certain period of time.
            </Paragraph>
            <SectionSubTitle>Promotional Communications</SectionSubTitle>
            <Paragraph>
              You may opt out of receiving promotional communications from us by following the instructions in those
              communications or by logging into your account. If you opt out, we may still send you non-promotional
              communications, such as those about your account or our ongoing business relations.
            </Paragraph>
            <SectionSubTitle>Cookies</SectionSubTitle>
            <Paragraph>
              Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your
              browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies,
              this could affect the availability and functionality of the Services.
            </Paragraph>
          </Section>
          <Section id="contact-us">
            <SectionTitle>11. Contact Us</SectionTitle>
            <Paragraph>
              If you have any questions about Truepill’s privacy practices, please contact us at:{' '}
              <a href="mailto:privacy@truepill.com">privacy@truepill.com</a>
            </Paragraph>
          </Section>
        </ContentContainer>
      </Container>
      {isLinkedCheckout && <LinkedCheckoutFooter isPrivacyPolicy />}
    </Theme>
  )
}

export default PrivacyPolicyPage

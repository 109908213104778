import React from 'react'
import styled from 'styled-components'

const ShippingDisplayCard = ({ shippingAddress }) => {
  const { address, address2, city, state, zip } = shippingAddress
  return (
    <ShippingDisplayCardContainer>
      <ShippingAddressContainer>
        <p>{address}</p>
        {address2 && <p>{address2}</p>}
        <p>{`${city}, ${state} ${zip}`}</p>
      </ShippingAddressContainer>
    </ShippingDisplayCardContainer>
  )
}

const ShippingDisplayCardContainer = styled.div`
  color: rgba(57, 66, 72, 0.498142);
`

const ShippingAddressContainer = styled.div`
  margin-top: 1rem;
  p {
    letter-spacing: 1px;
    line-height: 1.5rem;
    color: rgba(57, 66, 72, 0.5);
  }

  @media (min-width: 768px) {
    p {
      font-size: 20px;
    }
  }
`

export default ShippingDisplayCard

import React, { Fragment, useState } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { Button, Header, ProgressBar, Radio, Text, TextField, Spacer } from '@truepill/react-capsule'
import { yupResolver } from '@hookform/resolvers/yup'

import { device } from '../global/vars'
import { parseTextFieldStateForCapsule } from '../helpers'
import { LegalGuardianValidation } from '../validation'
import { ProgressBarContainer } from '../components/containers'
import { NEXT_PAGE, UPDATE_LEGAL_GUARDIAN } from '../../state/reducer/actions'
import { useLinkedCheckout } from '../../state/context'
import { SECTION_NAMES } from '../../constants'

const RelationshipConfirmation = () => {
  const { state, dispatch } = useLinkedCheckout()
  const [showGuardianForm, setShowGuardianForm] = useState(null)

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(LegalGuardianValidation),
    defaultValues: state.legalGuardian,
    mode: 'onChange',
  });

  const onSubmit = formData => {
    ReactGA.event({
      category: 'linkedCheckout',
      action: 'Confirmed Legal Guardian Verification',
      label: 'confirmedLegalGuardianVerification',
    })

    dispatch({ type: UPDATE_LEGAL_GUARDIAN, payload: formData })
    handleNext()
  }

  const handleNext = () => {
    dispatch({ 
      type: NEXT_PAGE, 
      payload: SECTION_NAMES.CONFIRM_PRESCRIPTIONS 
    })
  }

  const handleBack = () => {
    dispatch({ 
      type: NEXT_PAGE, 
      payload: SECTION_NAMES.PATIENT_IDENTITY 
    })
  }

  const backButton = (
    <Button type="button" variant="primary-text" onClick={handleBack}>
      Back
    </Button>
  )

  return (
    <Fragment>
      <ProgressBarContainer>
        <ProgressBar value={25} />
      </ProgressBarContainer>
      <Header variant="4xl">What is your relationship with the patient?</Header>
      <Spacer size="md" axis="vertical" />
      <Text>Because this prescription is for a minor, we require a little more information.</Text>
      <Spacer size="lg" axis="vertical" />
      <Fragment>
        <Radio
          onChange={() => setShowGuardianForm(true)}
          name="guardian"
          value="guardian"
          label="I am their legal guardian."
          checked={showGuardianForm !== null && showGuardianForm}
        />
        <Spacer size="md" axis="vertical" />
        <Radio
          onChange={() => setShowGuardianForm(false)}
          name="patient"
          value="patient"
          label="I am the patient."
          checked={showGuardianForm !== null && !showGuardianForm}
        />
        <Spacer size="md" axis="vertical" />
      </Fragment>
      {!showGuardianForm && (
        <ButtonsContainer>
          <Spacer size="lg" axis="vertical" />
          {backButton}
          <Button disabled={showGuardianForm == null} type="button" onClick={handleNext}>
            Continue
          </Button>
        </ButtonsContainer>
      )}
      {showGuardianForm && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <GuardianFieldsContainer>
            <TextField
              label="Guardian first name"
              {...register('guardianFirstName')}
              required
              state={parseTextFieldStateForCapsule(errors.guardianFirstName, dirtyFields.guardianFirstName)}
            />
            <Spacer size="md" axis="horizontal" />
            <TextField
              label="Guardian last name"
              {...register('guardianLastName')}
              required
              state={parseTextFieldStateForCapsule(errors.guardianLastName, dirtyFields.guardianLastName)}
            />
          </GuardianFieldsContainer>
          <Spacer size="lg" axis="vertical" />
          <ButtonsContainer>
            {backButton}
            <Button type="submit">Continue</Button>
          </ButtonsContainer>
        </form>
      )}
    </Fragment>
  )
}

const GuardianFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  input {
    margin-top: 0.75rem;
  }

  @media ${device.laptop} {
    flex-direction: row;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
`

export default RelationshipConfirmation

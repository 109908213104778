import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Modal } from '@truepill/react-capsule'

import InactivityModalContent from '../layout/InactivityModalContent'

const InactivityModal = ({ title, description, onSessionEnd, modalPopupTime, modalVisibilityTime }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [timeInSeconds, setTimeInSeconds] = useState(modalVisibilityTime / 1000)
  const events = ['keypress', 'mousedown', 'mouseup', 'touchmove', 'scroll']
  const interval = useRef(null)
  let modalPopupTimer

  const minutesToDisplay = useMemo(() => {
    const minutes = Math.floor(timeInSeconds / 60)
    return minutes < 10 ? `0${minutes}` : `${minutes}`
  }, [timeInSeconds])

  const secondsToDisplay = useMemo(() => {
    const seconds = timeInSeconds % 60
    return seconds < 10 ? `0${seconds}` : `${seconds}`
  }, [timeInSeconds])

  const onModalDismiss = () => {
    setIsOpen(false)
    setTimeInSeconds(modalVisibilityTime / 1000)
    clearInterval(interval.current)
  }

  const removeEvents = () => {
    events.forEach(event => {
      document.removeEventListener(event, setTimer)
    })
  }

  const setTimer = () => {
    clearTimeout(modalPopupTimer)
    modalPopupTimer = setTimeout(() => {
      setIsOpen(true)
    }, modalPopupTime)
  }

  useEffect(() => {
    if (timeInSeconds === 0) {
      onModalDismiss()
      onSessionEnd()
    }
  }, [timeInSeconds])

  useEffect(() => {
    events.forEach(event => {
      document.addEventListener(event, setTimer)
    })
    setTimer()

    return () => {
      clearTimeout(modalPopupTimer)
      removeEvents()
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      interval.current = setInterval(() => {
        setTimeInSeconds(seconds => seconds - 1)
      }, 1000)
    }
  }, [isOpen])

  return (
    <Modal aria-label={title} className="inactivity-modal" isOpen={isOpen} onDismiss={onModalDismiss}>
      <InactivityModalContent
        title={title}
        description={description}
        minutes={minutesToDisplay}
        seconds={secondsToDisplay}
        onModalDismiss={onModalDismiss}
      />
    </Modal>
  )
}

export default InactivityModal

import React from 'react'
import styled from 'styled-components'

import TruepillMapItem from './TruepillMapItem'
import { MapImage } from '../../images'
import { ImageContainer } from '../../styledComponents'
import { breakpoint } from '../../styledComponents/variables'

const mapInfo = [
  {
    title: '90 Day Fills',
    subtitle: '90-day fills are available for most maintenance medications. Never forget a refill again.',
  },
  {
    title: 'Licensed US Pharmacies',
    subtitle: 'Truepill has multiple licensed US pharmacies across the country shipping to all 50 states.',
  },
  {
    title: 'Refill with 1-click',
    subtitle:
      'We will notify you when it’s time to refill your meds and will also reach out to your doctor when you have no refills remaining.',
  },
]

const TruepillMap = () => (
  <TruepillMapContainer>
    <ImageContainer>
      <img src={MapImage} alt="truepill-locations" />
    </ImageContainer>
    <TruepillMapItemsContainer>
      {mapInfo.map((mapData, i) => (
        <TruepillMapItem key={`truepill_map_item_${i}`} data={mapData} />
      ))}
    </TruepillMapItemsContainer>
  </TruepillMapContainer>
)

const TruepillMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 2rem 0;
  max-width: 575px;

  h1 {
    font-size: 26px;
  }
  p {
    font-size: 16px;
    color: rgba(40, 48, 51, 0.65);
    line-height: 1.25rem;
  }

  ${breakpoint.lg} {
    flex-direction: row;
    max-width: 992px;
  }

  ${breakpoint.xl} {
    max-width: 1200px;
  }
`

const TruepillMapItemsContainer = styled.div``

export default TruepillMap

import React from 'react'
import styled from 'styled-components'
import { TextField } from '@truepill/react-capsule'

const ZipcodeInput = ({ register, setValue, name, ...props }) => {
  const handleChangeZip = e => {
    setValue(name, e.target.value.slice(0, 5), { shouldDirty: true, shouldValidate: true })
  }

  return <CustomTextField {...props} type="number" {...register(name)} onChange={handleChangeZip} />
}

const CustomTextField = styled(TextField)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`

export default ZipcodeInput

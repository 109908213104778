import React, { useState, useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Spacer, Text } from '@truepill/react-capsule'
import './multisections.css'
import { MultiSectionsContext } from './MultiSectionsContext'

const multiSectionAnimationSpeed = 350

export const useIsMount = () => {
  const isMountRef = useRef(true)
  useEffect(() => {
    isMountRef.current = false
  }, [])
  return isMountRef.current
}

export const MultiSectionsElement = ({ section, idx, defaultOpen }) => {
  const { _activeSection, _setActiveSection } = useContext(MultiSectionsContext)

  const [_open, setOpen] = useState(false)
  const [_triggerAnimation, setTriggerAnimation] = useState(false)

  const isMount = useIsMount()

  const closeTab = () => {
    setTriggerAnimation(false)
    setTimeout(() => {
      setOpen(false)
    }, multiSectionAnimationSpeed)
  }

  const openTab = () => {
    setTriggerAnimation(true)
    setOpen(true)
  }

  const toogleState = () => {
    _setActiveSection(idx)
    if (_open) {
      closeTab()
    } else {
      openTab()
    }
  }

  useEffect(() => {
    if (!isMount) {
      if (_activeSection !== idx) closeTab()
      else {
        openTab()
      }
    } else {
      if (defaultOpen === idx) {
        openTab()
        _setActiveSection(idx)
      }
    }
  }, [_activeSection])

  const open = section.alwaysOpen || _open
  const triggerAnimation = section.alwaysOpen || _triggerAnimation

  return (
    <>
      <Spacer size="md" axis="vertical" />
      <Bar
        onClick={() => {
          if (section.autoToggle === false) {
            return
          }
          toogleState()
        }}
        isOpen={open}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {section.checked && <CheckIcon />}

          <Text bold>{section.title}</Text>
        </div>
        {section.trigger ? <>{section.trigger}</> : <DownIcon isOpen={triggerAnimation} />}
      </Bar>
      {open && (
        <Body className={`${triggerAnimation ? 'open' : 'close'}-section`} isOpen={open}>
          {section.section}
        </Body>
      )}
    </>
  )
}

export const DownIcon = ({ isOpen }) => {
  return (
    <svg
      width={14}
      height={8}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="down-icon"
      transform={isOpen ? 'rotate(180)' : 'rotate(0)'}
    >
      <path d="M1 1l6 6 6-6" stroke="#034D83" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const CheckIcon = () => {
  return (
    <div style={{ marginRight: '8px' }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={20} height={20}>
        <circle cx={10} cy={10} r={10} fill="#127132" />
        <path
          d="M14 7.25l-5.5 5.5-2.5-2.5"
          fill="none"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 16px 16px;
  justify-content: space-between;
  box-sizing: border-box;
  background: #e9ecf0;
  border-radius: 8px 8px ${props => (props.isOpen ? '0px 0px' : '8px 8px')};
  cursor: pointer;
`

const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 16px 16px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #e9ecf0;
  box-sizing: border-box;
  /* Drop shadow light/100 */

  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: ${props => (props.isOpen ? '0px 0px' : '8px 8px')} 8px 8px;
`

import gql from 'graphql-tag'

const ClientSetSelectedMedication = gql`
  mutation SetMedication($medicationObj: Obj, $purchasePrice: Float) {
    cacheSelectedMedication(medicationObj: $medicationObj) @client
    saveSelectedPurchasePrice(purchasePrice: $purchasePrice) @client
  }
`

export default ClientSetSelectedMedication

import React, { Fragment } from 'react'

import Question from './Question'

const FAQGroup = props => {
  const { data, name } = props
  return (
    <Fragment>
      <div className="faq-group__header">
        <h1 className="faq__heading--1">{name}</h1>
      </div>
      {data.map((item, i) => {
        return <Question title={item.title} body={item.body} key={i} />
      })}
    </Fragment>
  )
}

export default FAQGroup

const queryStringParser = queryString => {
  queryString = queryString.replace(/\?/g, '')
  const queries = queryString.split('&')
  const queryAsHash = queries.reduce((acc, currVal) => {
    const keyAndValue = currVal.split('=')
    acc[keyAndValue[0]] = keyAndValue[1]
    return acc
  }, {})
  return queryAsHash
}

export { queryStringParser }

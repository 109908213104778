import snakeCase from 'lodash/snakeCase'
import startCase from 'lodash/startCase'
import { CheckoutFlow, ErrorContainer } from '../core/checkout'
import { EnrollmentComplete, OrderComplete, OrderIncomplete } from '../common/checkout'
import { CopayConfirmContainer, CopaySuccess } from '../core/copay-check'
import LandingPage from '../common/landingPage/LandingPage'
import CovidLandingPage from '../common/landingPage/CovidLandingPage'
import RxSenseLandingPage from '../common/landingPage/RxSenseLandingPage'
import CircleMedicalLandingPage from '../common/landingPage/CircleMedicalLandingPage'
import SempreHealthLandingPage from '../common/landingPage/SempreHealthLandingPage'
import Error404Page from '../common/Error404Page'
import { PrivacyPolicy, TermsOfUse, NoticeOfPrivacyPolicyPage } from '../common/support'

import { formSections } from '../core/checkout/formSections'
import EmployerRoute from './employers/employer'

const CheckoutRoutes = Object.keys(formSections).map(section => {
  return {
    path: `/enroll/${snakeCase(section)}`,
    name: startCase(section),
    component: formSections[section],
    protected: false,
  }
})

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Employer Selection',
    component: LandingPage,
    protected: false,
  },
  {
    path: '/employers/:employerName',
    name: 'Employer Selection',
    component: EmployerRoute,
    protected: false,
  },
  {
    path: '/covid19',
    exact: false,
    name: 'COVID-19 Senior Response Program',
    component: CovidLandingPage,
    protected: false,
  },
  {
    path: '/circle-medical',
    exact: false,
    name: 'Circle Medical Patients',
    component: CircleMedicalLandingPage,
    protected: false,
  },
  {
    path: '/rxsense',
    exact: false,
    name: 'RxSense Patients',
    component: RxSenseLandingPage,
    protected: false,
  },
  {
    path: '/sempre-health',
    exact: false,
    name: 'Sempre Health Patients',
    component: SempreHealthLandingPage,
    protected: false,
  },
  {
    path: '/e/*',
    name: 'Employer Selection',
    component: LandingPage,
    protected: false,
  },
  {
    path: '/enroll/*',
    name: 'Checkout',
    component: CheckoutFlow,
    protected: false,
    routes: CheckoutRoutes,
  },
  {
    path: '/order_complete',
    name: 'Order Complete',
    component: OrderComplete,
    protected: false,
  },
  {
    path: '/order_incomplete',
    name: 'Order InComplete',
    component: OrderIncomplete,
    protected: false,
  },
  {
    path: '/enrollment_complete',
    name: 'Enrollment Complete',
    component: EnrollmentComplete,
    protected: false,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorContainer,
    protected: false,
  },
  {
    path: '/orders/:orderUrlToken',
    name: 'Confirm Copay',
    component: CopayConfirmContainer,
    protected: false,
  },
  {
    path: '/order-confirmed',
    name: 'Confirm Success',
    component: CopaySuccess,
    protected: false,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    protected: false,
  },
  {
    path: '/notice-of-privacy-practices',
    name: 'Notice of Privacy Policy',
    component: NoticeOfPrivacyPolicyPage,
    protected: false,
  },
  {
    path: '/terms-of-use',
    name: 'Terms of Use',
    component: TermsOfUse,
    protected: false,
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: Error404Page,
    protected: false,
  },
]

export default routes

import * as yup from 'yup'

export const PatientIdentityValidation = yup.object().shape({
  month: yup.number().required().positive().integer().min(1).max(12),
  day: yup.number().required().positive().integer().min(1).max(31),
  year: yup.number().required().positive().integer().min(1900).max(new Date().getFullYear()),
  firstName: yup.string().min(1).required(),
  lastName: yup.string().min(1).required(),
  middleInitial: yup.string(),
})

export const LegalGuardianValidation = yup.object().shape({
  guardianFirstName: yup.string().min(1).required(),
  guardianLastName: yup.string().min(1).required(),
})

export const PatientUracAllergies = yup.object().shape({
  chkoutToggleAllergies: yup.string().oneOf(['true', 'false']).required(),
  chkoutPatientAllergies: yup.array().when('chkoutToggleAllergies', {
    is: 'true',
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
})

export const PatientUracMedications = yup.object().shape({
  chkoutToggleMedications: yup.string().oneOf(['true', 'false']).required(),
  chkoutPatientMedications: yup.array().when('chkoutToggleMedications', {
    is: 'true',
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
})

export const PatientUracConditions = yup.object().shape({
  chkoutToggleMedicalHistory: yup.string().oneOf(['true', 'false']).required(),
  chkoutPatientMedicalHistory: yup.array().when('chkoutToggleMedicalHistory', {
    is: 'true',
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
})

export const PatientUracPregnant = yup.object().shape({
  chkoutTogglePregnancy: yup.string().oneOf(['true', 'false', "I'm not sure"]).required(),
})

export const PatientShippingValidation = yup.object().shape({
  name: yup.string().min(1).max(200).required(),
  address: yup.string().min(1).max(200).required(),
  address2: yup.string().max(200).notRequired(),
  city: yup.string().min(1).max(200).required(),
  state: yup.string().min(1).required(),
  zip: yup.string().min(5).max(5).required(),
  shippingSpeed: yup.string().required(),
})

export const PatientPaymentValidation = yup.object().shape({
  // cardRequired is a virtual field that determines whether the rest of the fields are required
  cardRequired: yup.boolean().oneOf([true, false]).required(),
  cardNumberCompleted: yup.boolean().when('cardRequired', {
    is: true,
    then: yup.boolean().oneOf([true]).required(),
    otherwise: yup.boolean().notRequired(),
  }),
  cardCvvCompleted: yup.boolean().when('cardRequired', {
    is: true,
    then: yup.boolean().oneOf([true]).required(),
    otherwise: yup.boolean().notRequired(),
  }),
  cardExpiryCompleted: yup.boolean().when('cardRequired', {
    is: true,
    then: yup.boolean().oneOf([true]).required(),
    otherwise: yup.boolean().notRequired(),
  }),
  cardholder: yup.string().when('cardRequired', {
    is: true,
    then: yup.string().min(1).max(200).required(),
    otherwise: yup.string().notRequired(),
  }),
  zipcode: yup.string().length(5).required(),
})

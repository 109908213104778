import React from 'react'

const CheckoutFormInput = props => {
  const { error, handleInputChange, handleValidation, maxLength, name, placeholder, type, value, textArea } = props
  return textArea ? (
    <textarea
      className={`checkout__form--textarea ${error ? 'error' : ''}`}
      onBlur={handleValidation}
      onChange={handleInputChange}
      placeholder={placeholder}
      maxLength={maxLength}
      name={name}
      value={value}
      type={type || 'text'}
    />
  ) : (
    <input
      className={`checkout__form--input ${error ? 'error' : ''}`}
      onBlur={handleValidation}
      onChange={handleInputChange}
      placeholder={placeholder}
      maxLength={maxLength}
      name={name}
      value={value}
      type={type || 'text'}
    />
  )
}

export default CheckoutFormInput

import gql from 'graphql-tag'

const ClientGetPaymentDetails = gql`
  {
    chkoutPurchasePrice @client
    chkoutAddressTo1 @client
    chkoutAddressTo2 @client
    chkoutAddressToCity @client
    chkoutAddressToState @client
    chkoutAddressToZip @client
    chkoutCustomerFirstName @client
    chkoutCustomerLastName @client
  }
`

export default ClientGetPaymentDetails

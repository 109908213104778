import React from 'react'
import BackButton from '../../common/checkout/NewBackButton'

const ShippingPaymentButtons = ({ completeDisabled, disabled, goBack }) => {
  if (disabled) return null
  return (
    <div className="buttons__container">
      <BackButton goBack={goBack} />
      <button className="medications__continue-button continue-checkout-button" disabled={completeDisabled}>
        Complete order
      </button>
    </div>
  )
}

export default ShippingPaymentButtons

import React from 'react'
import { Redirect } from 'react-router-dom'
import { parsePhoneNumber } from 'libphonenumber-js'

import { CheckoutContainer } from '../../common'
import EmployerContext from '../../lib/Context/EmployerContext'

const formatDob = dobStr => {
  if (dobStr) {
    const split = dobStr.split('/')
    return `${split[1]}-${split[0]}-${split[2]}`
  }
}

export default class CheckoutFlow extends React.Component {
  static contextType = EmployerContext
  state = {
    patientInfo: {
      chkoutCustomerEmail: '',
      chkoutCustomerFirstName: '',
      chkoutCustomerLastName: '',
      chkoutCustomerPhone: '',
      chkoutCustomerZip: '',
      chkoutPatientDob: '',
      chkoutPatientSex: '',
    },
    patientPhoto: {
      base64Image: '',
    },
    additionalInfo: {
      chkoutPatientAllergies: '',
      chkoutPatientMedicalHistory: '',
      chkoutPatientMedications: '',
    },
    pharmacyInfo: {
      chkoutPharmacyName: '',
      chkoutPharmacyPhone: '',
      chkoutPrescriberName: '',
      chkoutPrescriberPhone: '',
      chkoutTransferAll: true,
      chkoutTransferMedications: '',
    },
    insuranceInfo: {
      chkoutInsuranceIdNumber: '',
      chkoutInsuranceRxBin: '',
      chkoutInsuranceRxGroup: '',
      chkoutInsuranceRxPCN: '',
    },
  }

  saveInput = (input, section) => {
    this.setState({
      [section]: input,
    })
  }

  createBody = () => {
    const {
      employer: { customerToken, employerName },
    } = this.context

    const isCircleMedical = employerName === 'Circle Medical'

    const { patientInfo, additionalInfo, pharmacyInfo, insuranceInfo, patientPhoto } = this.state

    const {
      chkoutCustomerEmail,
      chkoutCustomerFirstName,
      chkoutCustomerLastName,
      chkoutCustomerPhone,
      chkoutCustomerZip,
      chkoutPatientDob,
      chkoutPatientSex,
      chkoutPatientAllergies: patientInfoAllergies,
      chkoutPatientMedicalHistory: patientInfoMedicalHistory,
      chkoutPatientMedications: patientInfoMedications,
    } = patientInfo

    const { base64Image } = patientPhoto

    const { chkoutPatientAllergies, chkoutPatientMedicalHistory, chkoutPatientMedications } = additionalInfo

    const {
      chkoutPharmacyName,
      chkoutPharmacyPhone,
      chkoutPrescriberName,
      chkoutPrescriberPhone,
      chkoutTransferAll,
      chkoutTransferMedications,
    } = pharmacyInfo

    const {
      chkoutInsuranceIdNumber,
      chkoutInsuranceRxBin,
      chkoutInsuranceRxGroup,
      chkoutInsuranceRxPCN,
    } = insuranceInfo

    const body = {
      user: {
        first_name: chkoutCustomerFirstName,
        last_name: chkoutCustomerLastName,
        email: chkoutCustomerEmail,
        password: '',
        phone: chkoutCustomerPhone ? parsePhoneNumber(chkoutCustomerPhone, 'US').nationalNumber : '',
      },
      patient: {
        first_name: chkoutCustomerFirstName,
        last_name: chkoutCustomerLastName,
        dob: formatDob(chkoutPatientDob),
        sex: chkoutPatientSex,
        known_allergies: chkoutPatientAllergies || patientInfoAllergies || null,
        other_medications: chkoutPatientMedications || patientInfoMedications || null,
        medical_history: chkoutPatientMedicalHistory || patientInfoMedicalHistory || null,
        ...(isCircleMedical && { id_image: base64Image || null }),
      },
      address: {
        zip: chkoutCustomerZip,
      },
      products: [
        {
          sku: '',
          quantity: 1,
        },
      ],
      insurance: {
        cardholder_id: chkoutInsuranceIdNumber,
        rx_group: chkoutInsuranceRxGroup,
        rx_bin: chkoutInsuranceRxBin,
        pcn: chkoutInsuranceRxPCN,
      },
      payment_type: 'insurance',
      pharmacy_name: chkoutPharmacyName,
      pharmacy_phone: chkoutPharmacyPhone,
      prescriber_name: chkoutPrescriberName,
      prescriber_phone: chkoutPrescriberPhone,
      transfer_all_prescriptions: chkoutTransferAll,
      transfer_medications: chkoutTransferMedications,
      employer_name: employerName,
      customer_token: customerToken,
      noDoctorAndNoPharmacy: !!(Object.entries(pharmacyInfo).length === 0 && pharmacyInfo.constructor === Object),
    }

    return body
  }

  render() {
    const {
      employer: { employerName },
    } = this.context

    if (!employerName) {
      return <Redirect to="/" />
    }

    return (
      <React.Fragment>
        <CheckoutContainer
          saveInput={this.saveInput}
          handleSubmit={this.handleSubmit}
          formBody={this.createBody()}
          state={this.state}
          {...this.props}
        />
      </React.Fragment>
    )
  }
}

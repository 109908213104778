import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { combineSearchStrings } from '../../lib'

const BackButton = ({ to }) => {
  const { search } = useLocation()

  return (
    <Link
      to={{ ...(typeof to === 'string' ? { pathname: to } : to), search: combineSearchStrings(search, to.search) }}
      className="checkout__form--prev-button"
    >
      <span className="checkout__form--button-link">Back</span>
    </Link>
  )
}

export default BackButton

import React from 'react'

import HipaaLogo from '../../assets/images/hipaa.png'
import NabpLogo from '../../assets/images/nabp.png'
import VippsLogo from '../../assets/images/vipps.png'
import LegitScriptLogo from '../../assets/images/legitscript.jpg'

const CheckoutCertifications = props => (
  <div className="checkout__certifications-container">
    <p className="checkout__certifications-text">Truepill has earned the following pharmacy certifications</p>
    <div className="checkout__certifications-logo-container">
      <img src={HipaaLogo} alt="HIPAA" className="checkout__certifications-hipaa-logo" />
      <img src={NabpLogo} alt="NABP" className="checkout__certifications-nabp-logo" />
      <img src={VippsLogo} alt="HIPAA" className="checkout__certifications-vipps-logo" />
      <img src={LegitScriptLogo} alt="HIPAA" className="checkout__certifications-legitscript-logo" />
    </div>
  </div>
)

export default CheckoutCertifications

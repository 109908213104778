import React from 'react'

const CopayStepTracker = ({ confirmRefills, confirmOrder }) => {
  function findStep() {
    if (confirmRefills && !confirmOrder) return 2
    if (!confirmRefills) return 1
    if (confirmRefills && confirmOrder) return 3
  }

  const currStep = findStep()

  return (
    <div className="step-tracker">
      <div className={`step-tracker__step-container ${currStep === 1 || 2 || 3 ? 'active' : ''}`}>
        <p>Select Refills</p>
      </div>
      <div className={`step-tracker__step-container ${currStep !== 1 ? 'active' : ''}`}>
        <p>Confirm Order</p>
      </div>
      <div className={`step-tracker__step-container ${currStep === 3 ? 'active' : ''}`}>
        <p>Shipping and Payment</p>
      </div>
    </div>
  )
}

export default CopayStepTracker

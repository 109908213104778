import React from 'react'
import { queryStringParser } from './utils/'

import { NoPrescriptionEndState, PaymentFailed } from '../../common/checkout'

const ErrorStates = {
  '517533b83d14d108': NoPrescriptionEndState,
  '3e80b413fe39a553': PaymentFailed,
}

const ErrorContainer = props => {
  const query = queryStringParser(props.location.search)
  if (!query.eid) {
    return null
  }
  const ComponentToRender = ErrorStates[query.eid]
  return (
    <div className="checkout__container">
      <ComponentToRender />
    </div>
  )
}

export default ErrorContainer

import React from 'react'
import styled from 'styled-components'

import { MedCard, ActiveMedCard } from '../card'
import { Grid, GridItem } from '@truepill/react-capsule'
const MedicationList = ({ medStatusHeader, medStatusText, medList, interactive, setConfirmedMeds }) => {
  return (
    <MedicationListContainer>
      <MedStatusHeader>
        <h2>{medStatusHeader}</h2>
        {medStatusText && <p>{medStatusText}</p>}
      </MedStatusHeader>

      <Grid>
        {medList.map((med, index) => {
          if (interactive) {
            return (
              <GridItem key={`active-med-card-grid-${index}}`} desktop={6} mobile={4}>
                <ActiveMedCard
                  key={`active-med-card-${index}}`}
                  index={index}
                  setConfirmedMeds={setConfirmedMeds}
                  medication={med}
                />
              </GridItem>
            )
          }

          return <MedCard key={`med-card-${index}`} medication={med} />
        })}
      </Grid>
    </MedicationListContainer>
  )
}

const MedicationListContainer = styled.div``

const MedStatusHeader = styled.div`
  margin: 2rem 0;
  h2 {
    font-size: 24px;
    padding: 0 1rem;
  }

  p {
    margin: 1rem 0;
  }

  @media (min-width: 768px) {
    h2 {
      padding: 0;
    }
  }
`

export default MedicationList

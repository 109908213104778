import React, { useState } from 'react'
import styled from 'styled-components'

import { useLinkedCheckout } from '../../state/context'
import { NEXT_PAGE } from '../../state/reducer/actions'
import { submitOrder } from '../../state/api'

import { StepTracker } from '../components/layout'
import { ButtonContainer } from '../components/containers'
import { ContinueButton, BackButton } from '../components/button'
import { SECTION_NAMES } from '../../constants'

const ConfirmNoMedications = () => {
  const { dispatch, state } = useLinkedCheckout()
  const [disableButton, setDisableButton] = useState(false)

  const handleBack = () => {
    dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.CONFIRM_PRESCRIPTIONS })
  }

  const handleSubmit = async () => {
    setDisableButton(true)
    const { status } = await submitOrder(state, true)

    if (status !== 202) {
      // show error screen
    }

    dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.ORDER_REJECTED })
  }

  return (
    <>
      <StepTracker activeSteps={['personalInfo', 'prescriptions']} />
      <ConfirmNoMedicationsContainer>
        <p>
          You don’t have any medications selected to be filled. If you do not wish to fill your prescription, select
          submit below. If you do wish to receive you prescription, go back to the previous page and select the
          medication you want filled.
        </p>
        <ButtonContainer>
          <BackButton onClick={handleBack} />
          <ContinueButton onClick={handleSubmit} disabled={disableButton}>
            Submit Order
          </ContinueButton>
        </ButtonContainer>
      </ConfirmNoMedicationsContainer>
    </>
  )
}

const ConfirmNoMedicationsContainer = styled.div`
  text-align: center;
`

export default ConfirmNoMedications

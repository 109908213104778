import React from 'react'
import { Header, Spacer, Text } from '@truepill/react-capsule'
import styled from 'styled-components'

import { useLinkedCheckout } from '../../../state/context'

import { EditIcon } from '../../../assets'

const EditCreditCard = ({ setIsAddingCard }) => {
  const { state } = useLinkedCheckout()
  const { patientPayment } = state

  return (
    <EditCardContainer>
      <CardInfoContainer>
        <Header bold variant="2xl">
          Credit Card
        </Header>
        <Spacer size="sm" axis="vertical" />
        <CardMetadata>
          <Text>{patientPayment?.cardInfo?.brand.toUpperCase()}</Text>
          <Text>****{patientPayment?.cardInfo?.last4}</Text>
        </CardMetadata>
      </CardInfoContainer>
      <EditIconContainer onClick={() => setIsAddingCard(true)}>
        <img src={EditIcon} alt="Edit Credit Card" />
      </EditIconContainer>
    </EditCardContainer>
  )
}

const EditCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7fcff;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  padding: 2rem;
`

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const CardMetadata = styled.div`
  display: flex;
`

const EditIconContainer = styled.div`
  cursor: pointer;
`

export default EditCreditCard

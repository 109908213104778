import JWT from '../../utils/jwt'
import { getToken } from '../storage'

const isLoggedIn = () => {
  const token = getToken('token')
  if (token) {
    const decodedToken = new JWT().parseJwt(token)
    const currentTime = Math.floor(Date.now() / 1000)
    if (decodedToken.exp > currentTime) {
      return true
    } else {
      return false
    }
  }
  return false
}

export { isLoggedIn }

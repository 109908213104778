import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import EmployerContext from '../../lib/Context/EmployerContext'
import { breakpoint } from '../styledComponents/variables'

export const Header = () => {
  const {
    employer: { baseUrl, employerLogo },
  } = useContext(EmployerContext)

  return (
    <HeaderContainer id="header">
      <HeaderLeft>
        <Logo alt="logo" src={employerLogo} />
      </HeaderLeft>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  max-width: 100vw;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #44bdd0;
  padding: 0 1rem;
  box-shadow: 0 4px 10px -2px rgba(169, 169, 169, 0.5);
  position: relative;
  z-index: 1;
  background-color: white;

  ${breakpoint.sm} {
    height: 72px;
    padding-left: 1rem;
  }
`

const HeaderLeft = styled.div`
  img {
    max-height: 42px;
    max-width: 300px;
    object-fit: contain;
  }
`

const Logo = styled.img`
  padding: ${props =>
    props.src === 'https://s3.amazonaws.com/onboarding-assets.truepill.com/truepill-blue.png' ? '5px 0' : '0'};

  ${breakpoint.sm} {
    padding: 0;
  }
`

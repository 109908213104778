import React from 'react'
import currency from 'currency.js'
import styled from 'styled-components'

import { Header, Spacer, Text } from '@truepill/react-capsule'

const MedicationSummary = ({ confirmedMeds, isConfirmation }) => {
  const total = confirmedMeds.reduce((acc, med) => med.copay_price + acc, 0)
  return (
    <MedicationSummaryContainer>
      <MedList>
        {confirmedMeds.map(med => (
          <div key={med.medication_name}>
            <MedItemContainer>
              <Text bold>{med.medication_name}</Text>
              <Text>{`${currency(med.copay_price).format()}`}</Text>
            </MedItemContainer>
            <Spacer size="md" axis="vertical" />
          </div>
        ))}
        <MedItemContainer>
          <Text>Shipping</Text>
          <Text>FREE</Text>
        </MedItemContainer>
        <Spacer size="md" axis="vertical" />
      </MedList>
      <OrderTotal isConfirmation={isConfirmation}>
        <Header variant="2xl" bold>
          Order Total
        </Header>
        <Header variant="2xl" bold>{`${currency(total).format()}`}</Header>
      </OrderTotal>
    </MedicationSummaryContainer>
  )
}

const MedicationSummaryContainer = styled.div``

const MedList = styled.div``

const MedItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const OrderTotal = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ isConfirmation }) => (isConfirmation ? '' : '#007ad1')};
`

export default MedicationSummary

import React, { Component, Fragment } from 'react'

const formatMedicationDetails = data => {
  const quickLinksOrder = [
    ['Overview', 'overview'],
    ['Description', 'description'],
    ['Proper Use', 'proper_use'],
    ['Dosing', 'dosing'],
    ['Missed Dose', 'missed_dose'],
    ['Use & Storage', 'use_and_storage'],
    ['Before Using', 'before_using'],
    ['Allergies', 'allergies'],
    ['Pediatric', 'pediatric'],
    ['Geriatric', 'geriatric'],
    ['Pregnancy', 'pregnancy'],
    ['Breast Feeding', 'breast_feeding'],
    ['Drug Interactions', 'drug_interactions'],
    ['Other Interactions', 'other_interactions'],
    ['Other Medical Issues', 'other_medical_problems'],
    ['Side Effects', 'side_effects'],
    ['Precautions', 'precautions'],
  ]

  return quickLinksOrder.filter(section => !!data[section[1]]).map(section => [section[0], data[section[1]]])
}

const handleClickShowMore = parent => e => {
  parent.setState({ expanded: !parent.state.expanded })
}

const MedicationShowDetailsDesktop = props => {
  const { data, expanded, parent } = props
  if (data.length === 0) return null
  return (
    <div className="medication-show__drug-details">
      <div className="medication-show__quicklinks">
        <h4>Quicklinks:</h4>
        <nav className="medication-show__quicklinks-options">
          {data.map((section, i) => (
            <a
              onClick={e => props.parent.setState({ expanded: true })}
              href={`#${section[0].split(' ').join('')}`}
              key={i}
            >
              {section[0]}
            </a>
          ))}
        </nav>
      </div>
      <div className="medication-show__details-section-container" ref={parent.detailsContainer}>
        <div className={`medication-show__details-section-wrapper ${expanded ? 'expanded' : null}`}>
          {data.map((section, i) => (
            <div className="medication-show__details-section" key={i}>
              <h3 className="medication-show__details-heading" id={section[0].split(' ').join('')}>
                {section[0]}
              </h3>
              <p className="medication-show__details-body">{section[1]}</p>
            </div>
          ))}
        </div>
        <div className={`medication-show__details-section-container--fade ${expanded ? 'hide' : null}`}>
          <button className="medication-show__show-more-button" onClick={handleClickShowMore(parent)}>
            Show more
          </button>
        </div>
      </div>
    </div>
  )
}

class DetailsSection extends Component {
  constructor(props) {
    super(props)
    this.state = { isActive: false }
  }

  handleClick = () => {
    this.setState({ isActive: !this.state.isActive })
  }

  render() {
    return (
      <Fragment>
        <div className="medication-show__details-divider" onClick={this.handleClick}>
          <span>{this.props.sectionName}</span>
          <div className="dropdown-triangle" />
        </div>
        {this.state.isActive ? (
          <div className="medication-show__details-section-text">
            <p>{this.props.sectionData}</p>
          </div>
        ) : null}
      </Fragment>
    )
  }
}

const MedicationShowDetailsMobile = props => {
  const { data, name } = props
  if (data.length === 0) return null
  return (
    <div className="medication-show__drug-details--mobile">
      <div className="medication-show__details-section">
        <div className="medication-show__drug-details--mobile-about">
          <h3 className="medication-show__details-heading">{`About ${name}`}</h3>
          <p className="medication-show__details-body">{data[0][1]}</p>
        </div>
        {data.slice(1, data.length).map((section, i) => (
          <DetailsSection sectionName={section[0]} sectionData={section[1]} key={i} />
        ))}
      </div>
    </div>
  )
}

class MedicationShowDetails extends Component {
  constructor(props) {
    super(props)
    this.state = { expanded: false }
    this.detailsContainer = React.createRef()
  }

  componentDidMount() {
    const { clientHeight } = this.detailsContainer.current
    if (clientHeight < 450) this.setState({ expanded: true })
  }

  render() {
    const { expanded } = this.state
    const { data } = this.props
    const details = formatMedicationDetails(data)
    return (
      <Fragment>
        <MedicationShowDetailsDesktop data={details} expanded={expanded} parent={this} />
        <MedicationShowDetailsMobile data={details} name={data.name} />
      </Fragment>
    )
  }
}

export default MedicationShowDetails

import React, { useEffect, useState } from 'react'
import { Theme, Toast } from '@truepill/react-capsule'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import LinkedCheckoutRouter from './common/router'
import { LinkedCheckoutProvider, useLinkedCheckout } from './state/context'
import { RESET_LOCAL_STATE } from './state/reducer/actions'
import InactivityModal from './common/components/modal/InactivityModal'

import GlobalStyle from './common/global'

import { clearLocalStorage, clearSessionStorage, initGAPageView } from '../utils'
import { STRIPE_PK } from '../../config'

import '@truepill/react-capsule/dist/default-theme.css'

const stripePromise = loadStripe(STRIPE_PK)

const stripeOptions = {
  fonts: [{ cssSrc: 'https://use.typekit.net/ysv7wrb.css' }],
}

const MODAL_POPUP_TIME_IN_MS = 18 * 60 * 1000
const MODAL_VISIBILITY_TIME_IN_MS = 2 * 60 * 1000

const LinkedCheckoutWithSessionTimeout = ({ data }) => {
  const { state, dispatch } = useLinkedCheckout()
  const [showToast, setShowToast] = useState(false)

  const onSessionEnd = () => {
    dispatch({ type: RESET_LOCAL_STATE, payload: data })
    clearSessionStorage()
    clearLocalStorage()
    setShowToast(true)
  }

  return (
    <>
      <LinkedCheckoutRouter />
      {state?.patientIdentity?.patient_verified && (
        <InactivityModal
          title="You’re about to be signed out"
          description="We automatically sign you out if you’ve been inactive for a while to protect your privacy and security."
          onSessionEnd={onSessionEnd}
          modalPopupTime={MODAL_POPUP_TIME_IN_MS}
          modalVisibilityTime={MODAL_VISIBILITY_TIME_IN_MS}
        />
      )}
      <Toast
        className="toast-message"
        onDismiss={() => setShowToast(false)}
        position={{ vertical: 'top' }}
        color="pastel"
        visible={showToast}
        timeout={10000}
        onTimeout={() => setShowToast(false)}
        icon={true}
      >
        You have been logged out as your session has expired. Please click the link in your email or SMS to log back in.
      </Toast>
    </>
  )
}

const LinkedCheckout = ({ data }) => {
  useEffect(() => {
    initGAPageView(window.location.pathname)
  }, [])

  return (
    <LinkedCheckoutProvider orderInfo={data}>
      <Theme>
        <GlobalStyle />
        <Elements stripe={stripePromise} options={stripeOptions}>
          <LinkedCheckoutWithSessionTimeout data={data} />
        </Elements>
      </Theme>
    </LinkedCheckoutProvider>
  )
}

export default LinkedCheckout

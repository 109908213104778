import React from 'react'
import styled from 'styled-components'

import moment from 'moment'

import { BaseMedContainer, MedHeaderContainer, MedImageContainer } from '../containers'

import MedPlaceholder from '../../../../../assets/images/med-placeholder.jpg'

const MedCard = ({ medication }) => {
  return (
    <BaseMedContainer>
      <MedCardContainer>
        <MedImageContainer>
          <img alt="Medicine" src={MedPlaceholder} />
        </MedImageContainer>
        <MedHeaderContainer>
          <h3>{medication.medication_name}</h3>
          {medication.next_fill_date && (
            <p>{`Refill Available: ${moment(medication.next_fill_date).format('M/DD/YYYY')}`}</p>
          )}
          <p>{`Quantity ${medication.quantity} • Days Supply: ${medication.days_supply}`}</p>
          <p>{`Refills left: ${medication.num_refills_remaining}`}</p>
        </MedHeaderContainer>
      </MedCardContainer>
    </BaseMedContainer>
  )
}

const MedCardContainer = styled.div`
  display: grid;
  grid-template-columns: 11fr 1fr;
  align-items: center;
  grid-column-gap: 1rem;

  h3 {
    color: #2f2f2f;
  }

  @media (min-width: 768px) {
    grid-template-columns: 2fr 10fr;
  }
`

export default MedCard

/* global window */

const localStorage = window.localStorage

const setItem = (storage, key, value) => {
  try {
    storage.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.error(e)
  }
}

const getItem = (storage, key) => {
  try {
    const item = storage.getItem(key)
    if (item === null) {
      return undefined
    }
    return JSON.parse(item)
  } catch (err) {
    return undefined
  }
}

const removeItem = (storage, key) => {
  try {
    storage.removeItem(key)
  } catch (e) {
    console.error(e)
  }
}

export function setToken(token) {
  setItem(localStorage, 'token', token)
}

export function getToken() {
  return getItem(localStorage, 'token')
}

export function removeToken() {
  removeItem(localStorage, 'token')
}

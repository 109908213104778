import React from 'react'

import { MixpanelConsumer } from 'react-mixpanel'

const NoPrescriptionEndState = props => {
  return (
    <MixpanelConsumer>
      {mixpanel => {
        mixpanel.track('SHOW prescription nonexist')
        return (
          <div className="order-complete__container">
            <h2 className="order-complete__heading">Prescription Required</h2>
            <div className="order-complete__subheading">
              <br />
              Unfortunately, we can only fulfill your order
              <br />
              if you have a valid prescription.
              <br />
              <br />
              If you do have a valid prescription (either at another <br />
              pharmacy or your doctor's office), we can help you transfer it.
              <br />
              <br />
              If you don't yet have a valid prescription, <br />
              we hope you will bookmark us and come back later.
            </div>
            <a href="https://goodrx.truepill.com" className="complete-button">
              Return to Home Page
            </a>
          </div>
        )
      }}
    </MixpanelConsumer>
  )
}

export default NoPrescriptionEndState

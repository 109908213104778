import React, { useRef, useCallback } from 'react'
import styled from 'styled-components'
import Webcam from 'react-webcam'
import { useDropzone } from 'react-dropzone'

import UploadIcon from '../../assets/images/upload-icon.svg'
import DeleteIcon from '../../assets/images/delete-icon.svg'

const videoConstraints = {
  width: 300,
  height: 300,
  facingMode: 'user',
}

const BrowseTextStyles = `
  text-decoration: underline;
  text-decoration-thickness: 2px;
`

const UploadBox = ({ webCamState, webcamRef, setWebCamState, capturePhoto, handleFileUpload, handleDelete, image }) => {
  const fileUploadRef = useRef(null)
  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        const [image] = acceptedFiles
        handleFileUpload(image)
      }
    },
    [handleFileUpload]
  )

  const { getRootProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    onDrop,
  })

  const handleFileClick = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click()
    }
  }

  return (
    <>
      <UploadBoxContainer dragActive={isDragActive} {...getRootProps({ className: 'dropzone' })}>
        {!webCamState && <UploadImage src={UploadIcon} />}
        {webCamState ? (
          <WebCamContainer>
            <Webcam
              audio={false}
              screenshotFormat="image/jpeg"
              ref={webcamRef}
              videoConstraints={videoConstraints}
              minScreenshotHeight={1000}
              minScreenshotWidth={1000}
            />
          </WebCamContainer>
        ) : (
          <>
            <UploadButtonContainer>
              <UploadButton onClick={() => setWebCamState(true)}>Take photo</UploadButton>
              <p style={{ textAlign: 'center' }}>or</p>
              <input
                accept="image/png, image/jpeg"
                hidden
                onChange={e => handleFileUpload(e.target.files[0])}
                type="file"
                ref={fileUploadRef}
                id="photoUpload"
              />
              <UploadButton styles={BrowseTextStyles} onClick={handleFileClick}>
                Browse
              </UploadButton>
            </UploadButtonContainer>
            <UploadFileInfo>
              <p>Supported formats: JPG, or PNG</p>
              <p>Drag and Drop Image File</p>
            </UploadFileInfo>
          </>
        )}
      </UploadBoxContainer>
      {webCamState && <TakePhotoButton onClick={capturePhoto}>Take Photo</TakePhotoButton>}
      {image && (
        <ImageCard>
          <ImageStyleContainer>
            <ImageMetaInfo>
              <img alt="patientImage" src={image.fileBase64} />
              <p>{image.fileName}</p>
            </ImageMetaInfo>
            <DeleteContainer>
              <StyledDeleteButton onClick={handleDelete}>
                <img alt="deletePhoto" src={DeleteIcon} />
              </StyledDeleteButton>
            </DeleteContainer>
          </ImageStyleContainer>
        </ImageCard>
      )}
    </>
  )
}

const UploadBoxContainer = styled.div`
  margin: 2rem 0;
  background-color: #fbfbfb;
  border: 2px dashed ${props => (props.dragActive ? '#44BDD0' : '#aebbc6')};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
`

const UploadImage = styled.img`
  max-width: 50px;
`

const WebCamContainer = styled.div`
  div {
    max-width: 150px;
    height: 200px;
  }
  video {
    height: auto;
    width: 100%;
  }
`

const UploadButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 1rem 0;
`

const UploadButton = styled.button`
  background: none;
  text-decoration: none;
  border: none;
  color: #44bdd0;
  font-size: 0.82rem;
  cursor: pointer;
  ${({ styles }) => styles}

  @media screen and (min-width: 768px) {
    font-size: 1rem;
  }
`

const UploadFileInfo = styled.div`
  color: #5f6567;
  text-align: center;
  font-size: 0.85rem;

  p {
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 1rem;
  }
`

const ImageCard = styled.div`
  margin-top: 1rem;
  border: 2px solid #eaeaea;
  border-radius: 5px;
  padding: 0.5rem;
`

const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  grid-area: delete;
  justify-content: flex-end;
`

const TransparentButton = styled.button`
  background: none;
  border: none;
  font-size: 1rem;
`

const StyledDeleteButton = styled(TransparentButton)`
  width: 100%;
  max-width: 30px;
  cursor: pointer;
`

const ImageMetaInfo = styled.div`
  display: flex;
  align-items: center;
  grid-area: info;
  img {
    max-width: 69px;
    padding-right: 0.5rem;
  }
`

const ImageStyleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2f;
  grid-template-areas: 'info . delete';
`

const TakePhotoButton = styled.button`
  font-family: CircularStd;
  padding: 1rem;
  width: 100%;
  border-radius: 5px;
  ${props =>
    props.filled
      ? `
    background-color: ${props.disabled ? '#6C757D' : '#003471'};
    color: #fff;
  `
      : `
    background-color: #fff;
    color: #003471;
    border: 1px solid #003471;
  `}
`

export default UploadBox

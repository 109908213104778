import React, { useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { withApollo } from 'react-apollo'

import BackButton from './NewBackButton'
import NextButton from './NextButton'
import UploadBox from './UploadBox'

import { processFile } from './helpers'

const infoBulletPoints = [
  'The ID must not be expired',
  'The ID must be a Driver’s License, Passport, Military ID, or Permanent resident card',
  'Be sure your name, a photograph of you and your date of birth are clear enough to read',
]

const PatientPhoto = ({ state }) => {
  const history = useHistory()
  const [webCamState, setWebCamState] = useState(false)
  const [showError, setShowError] = useState(false)
  const [imageData, setImageData] = useState(null)
  const webcamRef = useRef(null)

  const capturePhoto = useCallback(() => {
    if (webcamRef.current) {
      const image = webcamRef.current.getScreenshot()
      if (image) {
        setImageData({ fileBase64: image, fileName: 'governmentId.jpg' })
      }
      setWebCamState(false)
    }
  }, [webcamRef])

  const handleFileUpload = async file => {
    const newFile = await processFile(file)
    setImageData({ fileBase64: newFile, fileName: file.name })
  }

  const handleDelete = () => setImageData(null)

  return (
    <PatientPhotoContainer>
      <h3>Photo of your Government ID</h3>
      {showError && !imageData && <ErrorText>Please upload an image</ErrorText>}
      <UploadBox
        webCamState={webCamState}
        webcamRef={webcamRef}
        setWebCamState={setWebCamState}
        handleFileUpload={handleFileUpload}
        handleDelete={handleDelete}
        capturePhoto={capturePhoto}
        image={imageData}
      />
      <InfoContainer>
        <h2>Before we continue, please ensure:</h2>
        <ul>
          {infoBulletPoints.map((bulletPoint, index) => (
            <li key={`bullet-${index}`}>{bulletPoint}</li>
          ))}
        </ul>
      </InfoContainer>
      <CheckoutFooter>
        <ButtonWrapper>
          <BackButton goBack={history.goBack} />
          <NextButton
            to="/enroll/payment_info"
            handleClick={e => {
              if (!imageData) {
                e.preventDefault()
                setShowError(true)
              } else {
                const governmentIdImage = { base64Image: imageData.fileBase64 }
                state.saveInput(governmentIdImage, 'patientPhoto')
              }
            }}
          />
        </ButtonWrapper>
      </CheckoutFooter>
    </PatientPhotoContainer>
  )
}

const PatientPhotoContainer = styled.div`
  h3 {
    font-size: 1.12rem;
  }
`

const CheckoutFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const InfoContainer = styled.div`
  font-family: CircularStd;
  max-width: 450px;
  margin-bottom: 2rem;
  h2 {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  ul {
    list-style: outside;
    margin-left: 1.25rem;
  }
  li {
    line-height: 2rem;
    font-size: 0.875rem;
  }
`

const ButtonWrapper = styled.div`
  margin: 25px 0px 28px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

const ErrorText = styled.p`
  color: red;
  text-align: center;
  margin: 1rem 0;
`

export default withApollo(PatientPhoto)

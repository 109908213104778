import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { withApollo } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import isNil from 'lodash/isNil'
import mapValues from 'lodash/mapValues'

import BackButton from './NewBackButton'
import NextButton from './NextButton'
import { validateField } from './validation'
import EmployerContext from '../../lib/Context/EmployerContext'
import { useGetPatientData } from '../../hooks'

/**
 * @type {import('react').FunctionComponent<{ state: { saveInput: (values: object, label: string) => void } }>}
 */
const PaymentInfo = ({ state }) => {
  const {
    employer: { requiredFields, employerName },
  } = useContext(EmployerContext)
  const history = useHistory()
  const isCircleMedical = employerName === 'Circle Medical'

  const { data: patientData, isValidating: patientDataValidating } = useGetPatientData()

  const [input, setInput] = useState({
    chkoutInsuranceIdNumber: null,
    chkoutInsuranceRxBin: null,
    chkoutInsuranceRxGroup: null,
    chkoutInsuranceRxPCN: null,
  })

  const [dirty, setDirty] = useState({
    chkoutInsuranceIdNumber: false,
    chkoutInsuranceRxBin: false,
    chkoutInsuranceRxGroup: false,
    chkoutInsuranceRxPCN: false,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /**
   * @type {import('react').ChangeEventHandler<HTMLInputElement>}
   */
  const handleMaterialChange = useCallback(
    ({ target: { name, value } }) => {
      setInput(input => ({
        ...input,
        [name]: value,
      }))
      setDirty(dirty => ({
        ...dirty,
        [name]: true,
      }))
    },
    [setInput]
  )

  const defaultedInput = {
    chkoutInsuranceIdNumber: input.chkoutInsuranceIdNumber ?? patientData?.insurance?.cardholder_id ?? '',
    chkoutInsuranceRxBin: input.chkoutInsuranceRxBin ?? patientData?.insurance?.rx_bin ?? '',
    chkoutInsuranceRxGroup: input.chkoutInsuranceRxGroup ?? patientData?.insurance?.rx_group ?? '',
    chkoutInsuranceRxPCN: input.chkoutInsuranceRxPCN ?? patientData?.insurance?.rx_pcn ?? '',
  }

  /**
   * @type {{ [key in keyof typeof defaultedInput]: boolean }}
   */
  const errors = Object.fromEntries(
    Object.entries(defaultedInput)
      .filter(([key]) => {
        const keyAsRequiredField = {
          chkoutInsuranceIdNumber: 'cardholder_id',
          chkoutInsuranceRxBin: 'rx_bin',
          chkoutInsuranceRxGroup: 'rx_group',
          chkoutInsuranceRxPCN: 'rx_pcn',
        }[key]

        return !!requiredFields.includes(keyAsRequiredField)
      })
      .map(([key, value]) => [key, validateField(key, value)])
      .filter(([, isError]) => isError === true)
  )

  if (patientDataValidating) {
    return null
  }

  return (
    <>
      {requiredFields.includes('cardholder_id') && (
        <TextField
          error={dirty.chkoutInsuranceIdNumber && errors.chkoutInsuranceIdNumber}
          onChange={handleMaterialChange}
          name="chkoutInsuranceIdNumber"
          placeholder="Cardholder ID Number"
          label="Cardholder ID Number"
          variant="outlined"
          value={defaultedInput.chkoutInsuranceIdNumber}
          disabled={!isNil(patientData?.insurance?.cardholder_id)}
        />
      )}
      {requiredFields.includes('rx_bin') && (
        <TextField
          error={dirty.chkoutInsuranceRxBin && errors.chkoutInsuranceRxBin}
          onChange={handleMaterialChange}
          name="chkoutInsuranceRxBin"
          placeholder="Rx BIN"
          label="Rx BIN"
          variant="outlined"
          value={defaultedInput.chkoutInsuranceRxBin}
          disabled={!isNil(patientData?.insurance?.rx_bin)}
        />
      )}
      {requiredFields.includes('rx_group') && (
        <TextField
          error={dirty.chkoutInsuranceRxGroup && errors.chkoutInsuranceRxGroup}
          onChange={handleMaterialChange}
          name="chkoutInsuranceRxGroup"
          placeholder="Rx Group"
          label="Rx Group"
          variant="outlined"
          value={defaultedInput.chkoutInsuranceRxGroup}
          disabled={!isNil(patientData?.insurance?.rx_group)}
        />
      )}
      {requiredFields.includes('rx_pcn') && (
        <TextField
          error={dirty.chkoutInsuranceRxPCN && errors.chkoutInsuranceRxPCN}
          onChange={handleMaterialChange}
          name="chkoutInsuranceRxPCN"
          placeholder="Rx PCN"
          label="Rx PCN"
          variant="outlined"
          value={defaultedInput.chkoutInsuranceRxPCN}
          disabled={!isNil(patientData?.insurance?.rx_pcn)}
        />
      )}
      {isCircleMedical && (
        <CashInfoLabel>
          If you prefer to pay cash for your medication or would like to compare your copay amount to the cash price,
          please call us at +1 855-792-7113
        </CashInfoLabel>
      )}
      <div className="checkout__form--footer">
        <div className="checkout__form-button-group">
          <BackButton goBack={history.goBack} />
          <NextButton
            to="/enroll/order_processing"
            handleClick={e => {
              if (Object.keys(errors).length > 0) {
                e.preventDefault()
              }

              setDirty(dirty => mapValues(dirty, () => true))
              state.saveInput(defaultedInput, 'insuranceInfo')
            }}
          >
            Complete
          </NextButton>
        </div>
      </div>
    </>
  )
}

const CashInfoLabel = styled.p`
  color: #000;
  font-size: 1.1rem;
  margin-top: 1rem;
  font-weight: 400;
  text-align: center;
`

export default withApollo(PaymentInfo)

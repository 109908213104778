import styled from 'styled-components'

export const CapsuleButtonContainer = styled.div`
  text-align: right;
`

export const ProgressBarContainer = styled.div`
  margin: 1rem 0;
`

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 10fr;
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }
`

export const ContinueContainer = styled.div`
  padding: 1rem;
`

export const BaseMedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 16px 16px;

  background: #f7fcff;
  border: 1px solid #0173ca;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
`

export const BaseShippingPaymentContainer = styled.div``

export const MedHeaderContainer = styled.div`
  margin-left: 16px;
`

export const MedImageContainer = styled.div`
  img {
    max-width: 120px;
    height: auto;
  }
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`

export const TotalContainer = styled.div`
  text-align: center;

  h5 {
    margin-top: 1rem 0;
  }
`

export const SpaceContainer = styled.div`
  margin: 2rem 0;
`
export const ErrorContainer = styled.div`
  color: red;
  text-align: center;
  margin: 1rem 0;
`

export const CardShadowContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

import React from 'react'
import useRxDetailsNeeded from '../../hooks/use-rx-details-needed'

const CheckoutStepTracker = props => {
  const rxDetailsNeeded = useRxDetailsNeeded()
  const currStep = props.location.pathname.split('enroll/')[1].split('/')[0].split('?')[0]

  const insuranceStepTrackerRoutes = ['payment_info']
  const rxDetailsStepTrackerRoutes = [...insuranceStepTrackerRoutes, 'pharmacy_info']
  const personalInfoStepTrackerRoutes = [
    ...rxDetailsStepTrackerRoutes,
    'patient_info',
    'additional_info',
    'patient_photo',
  ]

  const stepTrackerIsActive = (array, URL) => (array.includes(URL) ? 'active' : '')

  return (
    <div className="checkout__steptracker step-tracker">
      <div className={`step-tracker__step-container ${stepTrackerIsActive(personalInfoStepTrackerRoutes, currStep)}`}>
        <p>Personal Info</p>
      </div>
      {rxDetailsNeeded && (
        <div className={`step-tracker__step-container ${stepTrackerIsActive(rxDetailsStepTrackerRoutes, currStep)}`}>
          <p>Rx Details</p>
        </div>
      )}
      <div className={`step-tracker__step-container ${stepTrackerIsActive(insuranceStepTrackerRoutes, currStep)}`}>
        <p>Insurance</p>
      </div>
    </div>
  )
}

export default CheckoutStepTracker

import {
  UPDATE_PATIENT_IDENTITY,
  UPDATE_GOV_ID,
  NEXT_PAGE,
  UPDATE_URAC,
  UPDATE_CONFIRM_MEDS,
  UPDATE_SHIPPING,
  UPDATE_SHIPPING_ADDRESS,
  UPDATE_PAYMENT,
  UPDATE_INSURANCES,
  UPDATE_LEGAL_GUARDIAN,
  RESET_LOCAL_STATE,
} from './actions'
import { SHIPPING_METHODS } from '../../constants'
import { PBM_IDS } from '../../../../constants/'

const defaultUracFlow = ['allergies', 'conditions', 'medications']

export const initializeState = orderInfo => {
  const isCircleMedical = orderInfo.pbm_id === PBM_IDS.CIRCLE_MEDICAL
  return {
    currentPage: 'patientIdentity',
    orderInfo: {
      orderToken: orderInfo.url_token,
      pbmId: orderInfo.pbm_id,
      firstName: orderInfo.first_name,
      sortedProducts: orderInfo.sorted_products,
      patientImageExists: orderInfo?.linked_checkout.patient_image_exists,
      orderHasControlledSubstance: orderInfo?.linked_checkout?.order_has_controlled_substance,
      hideInsuranceScreen: orderInfo?.linked_checkout?.hide_insurance_screen,
      showCouponAppliedIcon: orderInfo?.linked_checkout?.show_coupon_applied_icon,
    },
    dobVerification: null,
    patientInsurance: null,
    patientGovId: null,
    patientUrac: {
      chkoutPatientAllergies: [],
      chkoutPatientMedicalHistory: [],
      chkoutPatientMedications: [],
      uracFlow: orderInfo?.linked_checkout?.show_preg_urac_question
        ? defaultUracFlow.concat('pregnancy')
        : defaultUracFlow,
      latestUracSection: 'allergies',
      isUracSurveyAnsweredBefore: null,
    },
    patientShipping: {
      shippingSpeed: orderInfo?.linked_checkout?.order_has_controlled_substance
        ? isCircleMedical 
          ? SHIPPING_METHODS.FEDEX_TWO_DAY 
          : SHIPPING_METHODS.USPS_EXPRESS
        : SHIPPING_METHODS.USPS_FIRST,
    },
    patientPayment: null,
    patientConfirmation: null,
    patientOrderInsurances: [],
    legalGuardian: null,
  }
}

export const linkedCheckoutReducer = (state, action) => {
  switch (action.type) {
    case NEXT_PAGE:
      return { ...state, currentPage: action.payload }
    case UPDATE_PATIENT_IDENTITY:
      return { ...state, patientIdentity: action.payload }
    case UPDATE_GOV_ID:
      return { ...state, patientGovId: action.payload }
    case UPDATE_LEGAL_GUARDIAN:
      return { ...state, legalGuardian: action.payload }
    case UPDATE_URAC:
      return { ...state, patientUrac: { ...state.patientUrac, ...action.payload } }
    case UPDATE_CONFIRM_MEDS:
      return { ...state, patientConfirmation: action.payload }
    case UPDATE_SHIPPING:
      return { ...state, patientShipping: action.payload }
    case UPDATE_PAYMENT:
      return { ...state, patientPayment: action.payload }
    case UPDATE_SHIPPING_ADDRESS: {
      const updatedState = { ...state }
      updatedState.patientPayment.shippingAddress = action.payload
      return updatedState
    }
    case UPDATE_INSURANCES: {
      return { ...state, patientOrderInsurances: action.payload }
    }
    case RESET_LOCAL_STATE: {
      return { ...initializeState(action.payload) }
    }
    default:
      return { ...state }
  }
}

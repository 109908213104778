import React, { useState } from 'react'
import { Text, Spacer, Tooltip, Modal, Header } from '@truepill/react-capsule'
import styled from 'styled-components'
import { InfoIcon } from '../../../assets'

export const WebTooltip = ({ text, position, variant, ariaLabel }) => {
  return (
    <IconForWeb>
      <Tooltip
        variant={variant || 'white'}
        label={text}
        position={position || 'bottom'}
        ariaLabel={ariaLabel || 'tooltip'}
      >
        <img src={InfoIcon} />
      </Tooltip>
    </IconForWeb>
  )
}

const IconForWeb = styled.div`
  display: none;
  margin-left: 8px;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    }
  }
`

export const MobileTooltip = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toogleIcon = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <IconForMobile>
        <img src={InfoIcon} onClick={toogleIcon} />
      </IconForMobile>

      <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)} aria-labelledby="title">
        {title && (
          <>
            <Header variant="4xl" id="title">
              {title}
            </Header>
            <Spacer />
          </>
        )}
        <Text style={{ color: 'var(--cap-typography-medium)' }}>{text}</Text>
      </Modal>
    </>
  )
}

const IconForMobile = styled.div`
  display: none;
  margin-left: 8px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    }
  }
`

export const NEXT_PAGE = 'next_page'
export const UPDATE_PATIENT_IDENTITY = 'update_patient_identity'
export const UPDATE_GOV_ID = 'update_gov_id'
export const UPDATE_LEGAL_GUARDIAN = 'update_legal_guardian'
export const UPDATE_URAC = 'update_urac'
export const UPDATE_CONFIRM_MEDS = 'update_confirm_meds'
export const UPDATE_SHIPPING = 'update_shipping'
export const UPDATE_SHIPPING_ADDRESS = 'update_shipping_address'
export const UPDATE_PAYMENT = 'update_payment'
export const UPDATE_INSURANCES = 'update_patient_insurances'
export const RESET_LOCAL_STATE = 'reset_local_state'

import styled from 'styled-components'
import { TextField } from '@truepill/react-capsule'

const NumberInput = styled(TextField)`
  padding-right: ${props => (props.state !== 'default' ? '36px' : 'auto')};

  &:focus {
    padding-right: ${props => (props.state !== 'default' ? '36px' : 'auto')};
  }
`

export default NumberInput

export const parseTextFieldStateForCapsule = (error, isDirty) => {
  if (error) {
    return 'error'
  } else if (!error && isDirty) {
    return 'complete'
  }

  return 'default'
}

export const getStepProgress = (currentPage, imageExists) => {
  const steps = imageExists
    ? ['patientIdentity', 'confirmPrescriptions', 'patientUrac', 'patientShipping', 'reviewInsurance', 'patientPayment']
    : [
        'patientIdentity',
        'confirmPrescriptions',
        'patientUpload',
        'patientUrac',
        'patientShipping',
        'reviewInsurance',
        'patientPayment',
      ]
  const index = steps.indexOf(currentPage) || 0

  return Math.round(((index + 1) / steps.length) * 100)
}

import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import LoadingOverlay from '../../common/LoadingOverlay'
import RedirectWithQueryString from '../../common/RedirectWithQueryString'
import { EmployerContext } from '../../lib'

/**
 * This component exists to work around render flicker issues by setting an
 * employer, then redirecting to a final route only when that employer renders.
 * @type {import('react').FunctionComponent<{}>}
 */
const EmployerRoute = () => {
  const { employer, setEmployerName } = useContext(EmployerContext)
  const { employerName } = useParams()

  useEffect(() => {
    setEmployerName(employerName)
  }, [employerName, setEmployerName])

  if (employer.employerName === employerName) {
    return <RedirectWithQueryString to="/enroll/patient_info" />
  }

  return <LoadingOverlay />
}
EmployerRoute.displayName = 'EmployerRoute'
export default EmployerRoute

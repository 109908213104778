import React from 'react'
import styled from 'styled-components'

import { ImageContainer } from '../../styledComponents'
import { breakpoint } from '../../styledComponents/variables'

const HowTruepillWorksItem = ({ image, text, alt }) => (
  <HowTruepillWorksItemContainer>
    <ImageContainer>
      <img src={image} height="99px" alt={alt} />
    </ImageContainer>
    <HowTruepillWorksTextContainer>
      <p>{text}</p>
    </HowTruepillWorksTextContainer>
  </HowTruepillWorksItemContainer>
)

const HowTruepillWorksItemContainer = styled.div`
  padding: 2rem;
  margin: 1rem 0;
  max-width: 575px;

  p {
    display: inline-block;
    width: 200px;
    line-height: 1.25rem;
  }

  ${breakpoint.lg} {
    max-width: 992px;
  }

  ${breakpoint.xl} {
    max-width: 1200px;

    p {
      font-size: 18px;
    }
  }
`

const HowTruepillWorksTextContainer = styled.div`
  margin: 2rem auto 0 auto;
  text-align: center;
`

export default HowTruepillWorksItem

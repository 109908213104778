import React from 'react'

export const IconBox = () => {
  return (
    <svg
      width={168}
      height={168}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.833 159c31.334-25.333 40.278-58.333 40.834-71.667l27.5 4.167v50L96.5 159H84.833z"
        fill="#DBF0FF"
      />
      <path
        d="M84 54v108.333"
        stroke="#00253F"
        strokeWidth={10}
        strokeLinecap="round"
      />
      <path
        d="M84 5.667V29M32.417 19.084l16.5 16.499M135.583 19l-16.499 16.5"
        stroke="#007AD1"
        strokeWidth={10}
        strokeLinecap="round"
      />
      <path
        d="M84 162.333l68.333-20M84 162.333l-68.333-20M152.333 69v73.333M15.667 69v73.333"
        stroke="#00253F"
        strokeWidth={10}
        strokeLinecap="round"
      />
      <path
        d="M154.5 69L84 50.667 104.367 84l57.966 5.833L154.5 69z"
        stroke="#00253F"
        strokeWidth={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 69L84 50.667 63.633 84 5.667 89.833 13.5 69z"
        fill="#fff"
        stroke="#00253F"
        strokeWidth={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

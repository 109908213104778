import React from 'react'
import styled from 'styled-components'
import currency from 'currency.js'

import { Text, Spacer } from '@truepill/react-capsule'

const MedicationConfirmationList = ({ confirmedMeds, shippingPrice, couponApplied, showCouponAppliedIcon }) => {
  const medTotal = confirmedMeds.reduce((acc, med) => acc.add(med.copay_price), currency(0)).value
  const orderTotal = currency(medTotal).add(shippingPrice).value

  return (
    <MedicationConfirmationContainer>
      <CopayContainer>
        <Text bold>Medication</Text>
      </CopayContainer>
      <MedList>
        {confirmedMeds.map(med => (
          <>
          <MedItemContainer key={med.medication_name}>
            <CopayContainer>
              <Text>{`${med.medication_name} - ${med.quantity} qty`}</Text>
              <Text bold>{`${currency(med.copay_price).format()}`}</Text>
            </CopayContainer>
            {showCouponAppliedIcon && <CouponApplied couponApplied={couponApplied} />}
          </MedItemContainer>
          {confirmedMeds.length > 1 && <Spacer size="sm" axis="vertical" />}
          </>
        ))}
        <Spacer size="md" axis="vertical" />
      </MedList>
      <Spacer size="md" axis="vertical" />
      <CopayContainer>
        <Text bold>Subtotal</Text>
        <Text bold>{`${currency(medTotal).format()}`}</Text>
      </CopayContainer>
      <CopayContainer>
        <Text>Shipping</Text>
        <Text>{currency(shippingPrice).format()}</Text>
      </CopayContainer>
      <CopayContainer>
        <Text>Tax</Text>
        <Text>$0.00</Text>
      </CopayContainer>
      <CopayTotal>
        <Text bold>Order total</Text>
        <Text bold>{`${currency(orderTotal).format()}`}</Text>
      </CopayTotal>
      <Spacer size="lg" axis="vertical" />
    </MedicationConfirmationContainer>
  )
}

const CopayContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  line-height: 1.5rem;
`

const CopayTotal = styled.div`
  display: flex;
  justify-content: space-between;
`

const MedicationConfirmationContainer = styled.div``

const MedItemContainer = styled.div``

const MedList = styled.div`
  border-bottom: 1px solid #979797;
`
const CouponApplied = ({ couponApplied }) => {
  return (
    <>
      {
        (couponApplied === true) ?
          <CouponAppliedContainer>
            <Text>Coupon applied</Text>
            <Spacer size="sm" axis="horizontal" />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={20} height={20}>
              <circle cx={10} cy={10} r={10} fill="#127132" />
              <path
                d="M14 7.25l-5.5 5.5-2.5-2.5"
                fill="none"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </CouponAppliedContainer >
          : null}
    </>
  )
}

const CouponAppliedContainer = styled.div`
  display: flex;
  flex-direction: row
`


export default MedicationConfirmationList

import './OrderComplete.scss'

import React, { useContext } from 'react'

import OrderCompleteCheckmark from '../../assets/images/order-complete-check.svg'
import { initGAPageView } from '../../core/utils/index'
import { EmployerContext } from '../../lib'

import CheckoutCertifications from './CheckoutCertifications'
import CheckoutQuestions from './CheckoutQuestions'

const OrderComplete = props => {
  const {
    employer: { email, phone, employerName },
  } = useContext(EmployerContext)

  const mailToEmail = employerName ? `mailto:${email}?subject=${employerName}` : `mailto:${email}`

  window.scrollTo(0, 0)
  const { pathname, search } = props.history.location
  initGAPageView(pathname + search)

  return (
    <React.Fragment>
      <div className="checkout__container">
        <div className="order-complete__container">
          <img alt="Order Complete!" className="order-complete__checkmark-img" src={OrderCompleteCheckmark} />
          <h2 className="order-complete__heading">All done!</h2>
          <h3 className="order-complete__subheading">You have successfully enrolled in Home Delivery.</h3>
          <p className="order-complete__text-top">
            Our team will follow-up once your prescriptions have been transferred and are ready to be filled.
          </p>
          <p className="order-complete__text">Questions?</p>
          <a href={mailToEmail} className="support-button">
            <span className="support-button__text">Contact Support</span>
          </a>
          <p className="order-complete__text-bottom">
            or call us at <a href={`tel:${phone}`}>{phone}</a>
          </p>
        </div>
      </div>
      <CheckoutCertifications />
      <CheckoutQuestions />
    </React.Fragment>
  )
}

export default OrderComplete

const maxWidth = 1000
const maxHeight = 1000

function resizeImage(img) {
  const canvas = document.createElement('canvas')
  let width = img.width
  let height = img.height
  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height *= maxWidth / width))
      width = maxWidth
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width *= maxHeight / height))
      height = maxHeight
    }
  }
  // resize the canvas and draw the image data into it
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, width, height)
  return canvas.toDataURL('image/jpeg', 0.7)
}

export async function processFile(file) {
  const processFilePromise = new Promise((resolve, reject) => {
    if (!/image/i.test(file.type)) {
      reject(new Error('File is not an image'))
    }
    // read the files
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = function (event) {
      // blob stuff
      if (event.target) {
        const blob = new Blob([event.target.result]) // create blob...
        window.URL = window.URL || window.webkitURL
        const blobURL = window.URL.createObjectURL(blob) // and get it's URL
        // helper Image object
        const image = new Image()
        image.src = blobURL
        image.onload = function () {
          const resized = resizeImage(image) // send it to canvas
          resolve(resized)
        }
      }
    }
  })
  return processFilePromise
}

import gql from 'graphql-tag'

const ClientGetResponseData = gql`
  {
    ppCcProcessorApproved @client
    ppCcProcessorError @client
    ppCcSslResultMessage @client
    ppCcErrorName @client
    ppCcErrorMessage @client
  }
`

export default ClientGetResponseData

import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useLinkedCheckout } from '../../state/context'

import PatientIdentity from '../sections/PatientIdentity'
import PatientUpload from '../sections/PatientUpload'
import PatientUrac from '../sections/PatientUrac'
import ConfirmPrescriptions from '../sections/ConfirmPrescriptions'
import PatientShipping from '../sections/PatientShipping'
// import ConfirmOrder from '../sections/ConfirmOrder' // Deprecated
import ConfirmNoMedications from '../sections/ConfirmNoMedications'
import OrderConfirmation from '../sections/OrderConfirmation'
// import ConfirmInsurance from '../sections/ConfirmInsurance' // Deprecated
import ReviewInsurance from '../sections/ReviewInsurance'
import PatientPayment from '../sections/PatientPayment'
import RelationshipConfirmation from '../sections/RelationshipConfirmation'
import LinkedCheckoutFooter from '../footer'
import { SECTION_NAMES, PAGE_TITLE } from '../../constants'

import { device } from '../global/vars'

const LinkedCheckoutRouter = () => {
  const { state } = useLinkedCheckout()
  const { currentPage } = state

  const renderSection = {
    [SECTION_NAMES.PATIENT_IDENTITY]: <PatientIdentity />,
    [SECTION_NAMES.RELATIONSHIP_CONFIRMATION]: <RelationshipConfirmation />,
    // [SECTION_NAME.PATIENT_INSURANCE]: <ConfirmInsurance />, // Deprecated
    [SECTION_NAMES.PATIENT_UPLOAD]: <PatientUpload />,
    [SECTION_NAMES.PATIENT_URAC]: <PatientUrac />,
    [SECTION_NAMES.CONFIRM_PRESCRIPTIONS]: <ConfirmPrescriptions />,
    [SECTION_NAMES.CONFIRM_NO_PRESCRIPTIONS]: <ConfirmNoMedications />,
    [SECTION_NAMES.PATIENT_SHIPPING]: <PatientShipping />,
    [SECTION_NAMES.REVIEW_INSURANCE]: <ReviewInsurance />,
    [SECTION_NAMES.PATIENT_PAYMENT]: <PatientPayment />,
    // [SECTION_NAMES.CONFIRM_ORDER]: <ConfirmOrder />, // Deprecated
    [SECTION_NAMES.ORDER_CONFIRMATION]: <OrderConfirmation />,
    [SECTION_NAMES.ORDER_REJECTED]: <OrderConfirmation isDeclinedOrder />,
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = `${PAGE_TITLE[currentPage]} - Truepill`
  }, [currentPage])

  return (
    <LinkedCheckoutContainer>
      <LinkedStyleContainer>{renderSection[currentPage]}</LinkedStyleContainer>
      <LinkedCheckoutFooter />
    </LinkedCheckoutContainer>
  )
}

const LinkedCheckoutContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: CircularStd;
  flex-direction: column;
`

const LinkedStyleContainer = styled.div`
  min-height: calc(100vh - 8.5rem - 60px);
  padding: 1rem 1rem;
  width: 100%;

  @media ${device.mobileL} {
    min-height: calc(100vh - 8.5rem - 72px);
  }

  @media ${device.tablet} {
    padding-top: 4rem;
    min-height: calc(100vh - 4rem - 72px);
  }

  @media ${device.laptop} {
    max-width: 650px;
  }
`

export default LinkedCheckoutRouter

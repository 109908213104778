import React, { useState, useEffect, useRef } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import { StateOptions, ShippingMethodOptions } from '../../common/checkout/utils'
import { validateField } from './validation'

import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import ShippingPaymentButtons from './ShippingPaymentButtons'

import { submitOrder } from './utils'

const ShippingInfo = props => {
  const {
    enableSubmit,
    goBack,
    medications,
    orderTotal,
    redirectCompletion,
    shippingState,
    showPatientSurvey,
    setShippingState,
    shippingErrorState,
    setShippingErrorState,
    url_token,
    validateAllFields,
    showConsentBox,
    consentError,
    setConsent,
    consent,
  } = props

  const inputs = shippingState
  const setInputs = setShippingState
  const errors = shippingErrorState
  const setErrors = setShippingErrorState

  const stateLabel = useRef(null)
  const shippingLabel = useRef(null)

  const [stateLabelWidth, setStateLabelWidth] = useState(0)
  const [shippingLabelWidth, setShippingLabelWidth] = useState(0)
  const [isSubmitting, disableComplete] = useState(false)

  const handleChange = e => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    })
  }

  const handleValidation = (e, meta) => {
    const { value } = e.target
    const name = meta || e.target.name
    const validationValue = !inputs[name] ? value : inputs[name]
    setErrors({
      ...errors,
      [name]: validateField(name, validationValue),
    })
  }

  const handleBlur = (e, name) => {
    handleValidation(e, name)
    stateLabel.current.blur()
  }

  const submitHandler = async e => {
    if (e) e.preventDefault()
    disableComplete(true)

    if (validateAllFields()) {
      disableComplete(false)
      return null
    }

    const orderDetails = JSON.stringify({
      payment: { price: Number(orderTotal) },
      shipping: { ...shippingState },
      medications,
      ...(consent && { consent }),
    })

    await submitOrder(orderDetails, url_token).then(response => {
      if (response.status === 202) {
        medications.forEach(medication => {
          ReactGA.plugin.execute('ec', 'addProduct', {
            name: medication.medication_name,
            quantity: medication.quantity,
            price: medication.copay_price,
          })
        })
        ReactGA.plugin.execute('ec', 'setAction', 'purchase')
        ReactGA.event({
          category: 'checkout',
          action: 'success',
          label: 'checkoutSuccess',
        })
        redirectCompletion('name')
      } else {
        ReactGA.event({
          category: 'checkout',
          action: 'error',
          label: 'checkoutError',
        })
        disableComplete(false)
        console.log('Need to add error handling here')
      }
    })
  }

  const generateStyles = error => ({
    borderColor: error ? '#e1656f' : null,
  })

  useEffect(() => {
    setStateLabelWidth(stateLabel.current.offsetWidth)
  }, [])

  useEffect(() => {
    if (showPatientSurvey) {
      handleChange({
        target: {
          name: 'shippingMethod',
          value: 'usps_first',
        },
      })
      return
    }

    setShippingLabelWidth(shippingLabel.current.offsetWidth)
  }, [])

  return (
    <form onSubmit={submitHandler}>
      <div className="shipping-info__container">
        <div className="shipping-inputs__container">
          <h2 className="shipping-inputs__header">Shipping Info</h2>
          <TextField
            error={errors.street1}
            className="shipping-inputs__street1"
            onBlur={handleBlur}
            style={generateStyles(errors.street1)}
            name="street1"
            onChange={handleChange}
            value={inputs.street1}
            label="Address"
            variant="outlined"
            autoFocus
          />

          <TextField
            error={errors.street2}
            className="shipping-inputs__street2"
            style={generateStyles(errors.street2)}
            name="street2"
            onChange={handleChange}
            value={inputs.street2}
            label="Apt, Suite, etc..."
            variant="outlined"
          />

          <TextField
            error={errors.city}
            className="shipping-inputs__city"
            style={generateStyles(errors.city)}
            name="city"
            onChange={handleChange}
            value={inputs.city}
            label="City"
            onBlur={handleBlur}
            variant="outlined"
          />
          <FormControl variant="outlined" className={'shipping-inputs__dropdown-select-state'}>
            <InputLabel style={{ color: errors.state ? '#e1656f' : null }} ref={stateLabel} htmlFor="state-select">
              State
            </InputLabel>
            <Select
              error={errors.state}
              style={generateStyles(errors.state)}
              value={inputs.state}
              onChange={handleChange}
              input={<OutlinedInput labelWidth={stateLabelWidth} color={'red'} name="state" id="state-select" />}
              onBlur={e => handleBlur(e, 'state')}
              variant={'outlined'}
            >
              {StateOptions.map(state => (
                <MenuItem key={state.value} value={state.value}>
                  {state.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            error={errors.zip}
            style={generateStyles(errors.zip)}
            className="shipping-inputs__zip"
            name="zip"
            maxLength="10"
            onChange={handleChange}
            value={inputs.zip}
            label="Zip"
            onBlur={handleBlur}
            variant="outlined"
          />
          {!showPatientSurvey && (
            <FormControl
              variant="outlined"
              className={'shipping-inputs__shipping-dropdown shipping-inputs--shipping-dropdown'}
            >
              <InputLabel
                style={{ color: errors.shippingMethod ? '#e1656f' : null }}
                ref={shippingLabel}
                htmlFor="shipping-select"
              >
                Preferred Shipping Method
              </InputLabel>
              <Select
                error={errors.shippingMethod}
                style={generateStyles(errors.shippingMethod)}
                value={inputs.shippingMethod}
                onChange={handleChange}
                input={<OutlinedInput labelWidth={shippingLabelWidth} name="shippingMethod" id="shipping-select" />}
                onBlur={e => handleBlur(e, 'shippingMethod')}
                variant="outlined"
              >
                <MenuItem label="Preferred Shipping Method" value="Preferred Shipping Method" disabled>
                  Preferred Shipping Method
                </MenuItem>
                {ShippingMethodOptions.map(shipping => (
                  <MenuItem label={'Preferred Shipping Method'} key={shipping.value} value={shipping.value}>
                    {shipping.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <div className="shipping-info__save-buttton-container" />
        {showConsentBox && (
          <>
            <ConsentContainer>
              <input
                type="checkbox"
                checked={consent}
                onChange={e => {
                  setConsent(e.target.checked)
                }}
                id="consent"
              />
              <ConsentLabel consentError={consentError} for="consent">
                I consent for a fill of the aforementioned medications and copay amount.
              </ConsentLabel>
            </ConsentContainer>
          </>
        )}
      </div>
      <ShippingPaymentButtons disabled={!enableSubmit} completeDisabled={isSubmitting} goBack={goBack} />
    </form>
  )
}

const ConsentContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const ConsentLabel = styled.label`
  margin-left: 1rem;
  font-family: CircularStd;

  ${({ consentError }) =>
    consentError &&
    `
    color: red;
  `}
`

export default ShippingInfo

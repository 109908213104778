import axios from 'axios'
import moment from 'moment'

import { createSubmitOrderPayload } from './utils'
import { API_PATH } from '../../../../config'

export const validatePatientIdentity = async (orderToken, patientInfo) => {
  const patientIdentityParams = {
    patientFirstName: patientInfo.firstName,
    patientLastName: patientInfo.lastName,
    patientDob: moment(patientInfo.dob).format('YYYY-MM-DD'),
  }

  try {
    const { data } = await axios.get(`${API_PATH}/orders/${orderToken}/verifyIdentity`, {
      params: patientIdentityParams,
    })

    const { patient_order_insurances, latest_patient_survey } = data

    let insurances
    if (patient_order_insurances) {
      insurances = patient_order_insurances.map(insurance => ({
        cardholderId: insurance.cardholder_id,
        rxGroup: insurance.rx_group,
        rxBin: insurance.rx_bin,
        pcn: insurance.pcn,
      }))
    }

    let patientSurvey = {}
    if (latest_patient_survey) {
      patientSurvey = data?.latest_patient_survey
    }

    window.sessionStorage.setItem('secureHash', data.secureHash)

    return { valid: true, insurances, patientSurvey }
  } catch (e) {
    return { valid: false }
  }
}

export const createPaymentMethod = async (orderToken, stripeToken) => {
  try {
    const data = await axios.post(`${API_PATH}/payments/create/method`, {
      transaction_token: stripeToken,
      url_token: orderToken,
    })
    return data
  } catch (e) {
    return e.response
  }
}

export const submitOrder = async (checkoutState, declineAll = false) => {
  const {
    orderInfo: { orderToken },
  } = checkoutState

  const orderPath = `${API_PATH}/orders/submit/${orderToken}`
  const headers = { 'x-secure-hash': window.sessionStorage.getItem('secureHash') }
  try {
    if (declineAll) {
      const declineOrderData = await axios.post(orderPath, { decline_all: true }, { headers })
      return declineOrderData
    }

    const orderPayload = createSubmitOrderPayload(checkoutState)
    const data = await axios.post(orderPath, orderPayload, { headers })
    return data
  } catch (e) {
    return e.response
  }
}

import React, { Fragment } from 'react'
import currency from 'currency.js'

import { calculateTotal } from './utils'
import { MedicationContainer } from './'

import BackButton from '../../common/checkout/NewBackButton'
import EstimatedDelivery from './EstimatedDelivery'

import './ConfirmOrderList.scss'

const ConfirmOrderList = props => {
  const { active, advanceForm, breadcrumbs, currentMeds, goBack, submitWithNoMeds, requiresPatientSurvey } = props
  if (!active) return null
  const orderTotal = calculateTotal(currentMeds)
  const userShippingPaymentRequired = currentMeds.length > 0
  return (
    <Fragment>
      <div className="confirm-order__container">
        {userShippingPaymentRequired ? <h1>Total {currency(orderTotal).format()}</h1> : null}
        <EstimatedDelivery currentMeds={currentMeds} requiresPatientSurvey={requiresPatientSurvey} />
        {breadcrumbs()}
        {userShippingPaymentRequired ? <h2>Prescriptions to be filled:</h2> : <h1>No medications selected.</h1>}
        {currentMeds.map((med, i) => {
          if (!med.patient_confirmed) return null
          return <MedicationContainer details={med} key={`confirm-order-med-${i}`} />
        })}
        <div className="buttons__container">
          <BackButton goBack={goBack} />
          <button
            className="medications__continue-button"
            onClick={userShippingPaymentRequired ? advanceForm : submitWithNoMeds}
          >
            Continue
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default ConfirmOrderList

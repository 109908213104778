import React, { useState } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import { Header, Text, Button, ProgressBar, Spacer } from '@truepill/react-capsule'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useLinkedCheckout } from '../../state/context'
import { NEXT_PAGE, UPDATE_SHIPPING } from '../../state/reducer/actions'
import { PatientShippingValidation } from '../validation'

import { ShippingInfoCard } from '../components/card'
import { CapsuleButtonContainer, ProgressBarContainer } from '../components/containers'
import { getStepProgress } from '../helpers'
import { SECTION_NAMES } from '../../constants'

const PatientShipping = () => {
  const { state, dispatch } = useLinkedCheckout()
  const { patientShipping, orderInfo, currentPage } = state
  const { orderHasControlledSubstance, patientImageExists, hideInsuranceScreen } = orderInfo
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid, dirtyFields },
  } = useForm({
    resolver: yupResolver(PatientShippingValidation),
    mode: 'onChange',
    defaultValues: patientShipping,
  })

  const handleBack = () => {
    dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.PATIENT_URAC })
  }

  const onSubmit = async formData => {
    ReactGA.event({
      category: 'linkedCheckout',
      action: 'Completed Shipping',
      label: 'completedShipping',
    })
    dispatch({ type: UPDATE_SHIPPING, payload: formData })

    const nextPage = hideInsuranceScreen
      ? SECTION_NAMES.PATIENT_PAYMENT
      : SECTION_NAMES.REVIEW_INSURANCE

    dispatch({ type: NEXT_PAGE, payload: nextPage })
  }

  return (
    <>
      <ProgressBarContainer>
        <ProgressBar value={getStepProgress(currentPage, patientImageExists)} />
      </ProgressBarContainer>
      <Spacer size="md" axis="vertical" />
      <PatientPaymentContainer>
        <Header variant="4xl">Where do you want your medication shipped?</Header>
        <Spacer size="xl" axis="vertical" />
        <Text style={{ fontSize: '1.1rem' }} bold>
          Address
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ShippingInfoCard
            errors={errors}
            register={register}
            control={control}
            dirtyFields={dirtyFields}
            setValue={setValue}
            controlledSubstance={orderHasControlledSubstance}
            watch={watch}
          />

          <CapsuleButtonContainer>
            <Button type="button" variant="primary-text" onClick={handleBack}>
              Back
            </Button>
            <Button type="submit" disabled={!isValid}>
              Continue
            </Button>
          </CapsuleButtonContainer>
        </form>
      </PatientPaymentContainer>
    </>
  )
}

const PatientPaymentContainer = styled.div``

export default PatientShipping

import './OrderComplete.scss'

import React, { useContext } from 'react'

import OrderCompleteCheckmark from '../../assets/images/order-complete-check.svg'
import { initGAPageView } from '../../core/utils/index'
import { EmployerContext } from '../../lib'

import CheckoutCertifications from './CheckoutCertifications'
import CheckoutQuestions from './CheckoutQuestions'

// import NextButton from '../checkout/NextButton'

const OrderIncomplete = props => {
  const {
    employer: { email, phone, employerName },
  } = useContext(EmployerContext)
  const mailToEmail = employerName ? `mailto:${email}?subject=${employerName}` : `mailto:${email}`

  window.scrollTo(0, 0)
  const { pathname, search } = props.history.location
  initGAPageView(pathname + search)

  return (
    <React.Fragment>
      <div className="checkout__container">
        <div className="order-complete__container">
          <img alt="Order Complete!" className="order-complete__checkmark-img" src={OrderCompleteCheckmark} />
          <h2 className="order-complete__heading">There was an error.</h2>
          <p className="order-complete__text-top">
            Please contact <a href={`mailto:${email}`}>{email}</a> to open a support ticket. Our team will follow-up
            once your request is received.
          </p>
          <a href={mailToEmail} className="support-button">
            <span className="support-button__text">Contact Support</span>
          </a>
          <p className="order-complete__text-bottom">
            or call us at <a href={`tel:${phone}`}>{phone}</a>
          </p>
        </div>
      </div>
      <CheckoutCertifications />
      <CheckoutQuestions />
    </React.Fragment>
  )
  //       }
  //     }
  //   </MixpanelConsumer>
  // )
}

export default OrderIncomplete

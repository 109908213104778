import useSWR from 'swr'

import { API_PATH } from '../config'

/**
 * @typedef Employer
 * @type {object}
 * @property {string} name
 * @property {string} img_url
 * @property {string[]} required_fields
 * @property {string} customer_token
 */

/**
 * @typedef EmployersResponse
 * @type {Employer[]}
 */

/**
 * Fetch the data for the list of employers from the patient-api `/employers`
 * endpoint. With a long debounce time here, we can call this multiple times in
 * the same browser session and get the same response back without multiple
 * calls happening.
 *
 * @returns {import('swr').responseInterface<EmployersResponse, any>}
 */
export default function useGetEmployers() {
  return useSWR(`${API_PATH}/employers`, {
    dedupingInterval: Infinity,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
}

import React, { Component } from 'react'

const handleClickSelectOption = parent => async e => {
  const {
    target: {
      dataset: { value },
    },
  } = e
  e.preventDefault()
  e.stopPropagation()
  const displayedString = String(e.target.innerHTML)
  await parent.setState({ displayedString, value })
  await parent.hideGlobalEvent()
  const event = {
    target: {
      name: parent.props.name,
      value,
      type: 'text',
    },
  }
  parent.props.handleInput(event)
}

const handleClickCustomQuantity = parent => e => {
  e.preventDefault()
  e.stopPropagation()
}

const handleSubmitCustomQuantity = parent => e => {
  e.preventDefault()
  parent.setState({ hidden: true })
}

const handleCustomQuantityChange = parent => e => {
  e.preventDefault()
  e.stopPropagation()
  const value = Number(Math.round(Math.abs(e.target.value)))
  if (typeof value === 'number' && !isNaN(value)) {
    const displayedString = value > 1 ? Math.min(value, 99) : 1
    parent.setState({ displayedString })
    e.target.name = parent.props.name
    e.target.value = displayedString
    e.target.type = 'text'
    parent.props.handleInput(e)
  }
}

const CustomDropdownInput = props => {
  const { display, parent } = props
  if (display) {
    return (
      <form
        onClick={handleClickCustomQuantity(parent)}
        onSubmit={handleSubmitCustomQuantity(parent)}
        className="dropdown-select__select--custom-quantity"
      >
        <input
          name={parent.props.name}
          placeholder="Enter Custom quantity"
          onChange={handleCustomQuantityChange(parent)}
          type="number"
          min="1"
          max="99"
          step="1"
        />
      </form>
    )
  }
  return null
}

class DropdownSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayedString: this.props.defaultValue,
      hidden: true,
      value: '',
    }
  }

  hideGlobalEvent = e => {
    if (e) e.preventDefault()
    this.setState({ hidden: true })
    window.removeEventListener('click', this.hideGlobalEvent)
    if (this.props.onBlur) {
      this.props.onBlur({
        target: {
          name: this.props.name,
          value: this.state.value,
        },
      })
    }
  }

  handleDropdown = e => {
    e.preventDefault()
    e.stopPropagation()
    window.addEventListener('click', this.hideGlobalEvent)
    const currentHiddenState = this.state.hidden
    this.setState({ hidden: !currentHiddenState })
  }

  render() {
    const {
      // data,
      disabled,
      errorBorder,
      noMaxHeight,
      optionValues,
    } = this.props
    const displayValue = this.state.displayedString
    return (
      <div className="dropdown-select__container">
        <div
          className={`dropdown-select__select ${disabled ? 'disabled' : ''} ${errorBorder ? 'error' : ''}`}
          onClick={disabled ? null : this.handleDropdown}
        >
          <span className="select-text">{displayValue}</span>
          <div className="dropdown-triangle" />
        </div>
        {this.state.hidden ? null : (
          <div className={`dropdown-select__select--dropdown ${noMaxHeight ? 'no-max-height' : null}`}>
            {optionValues.map((option, i) => (
              <div
                className="dropdown-select__select--dropdown-text"
                data-value={option.value}
                onClick={handleClickSelectOption(this)}
                key={i}
              >
                {option.string}
              </div>
            ))}
            <CustomDropdownInput display={this.props.displayCustomInput} parent={this} />
          </div>
        )}
      </div>
    )
  }
}

export default DropdownSelect

import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { MultiSectionsElement } from './MultiSectionElement'
import { MultiSectionsContext } from './MultiSectionsContext'
import './multisections.css'

const NO_DEFAULT_SECTION_SELECTED = -1

export const MultiSections = ({ sections, defaultOpen = NO_DEFAULT_SECTION_SELECTED }) => {
  const { _setMultiSectionElementCounter } = useContext(MultiSectionsContext)

  useEffect(() => {
    _setMultiSectionElementCounter(sections.length)
  })

  return (
    <BarContainer className="section-multi-bar">
      {sections.map((section, index) => (
        <MultiSectionsElement idx={index} key={index} section={section} defaultOpen={defaultOpen} />
      ))}
    </BarContainer>
  )
}

const BarContainer = styled.div``

export default MultiSections

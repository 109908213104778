import React from 'react'
import styled from 'styled-components'

const RadioQuestion = props => {
  const {
    handleInputChange,
    questionText,
    toggleName,
    toggleValue,
    errorToggle,
    textAreaInputName,
    textAreaValue,
    textAreaPlaceholder,
    errorTextArea,
  } = props

  return (
    <>
      <RadioQuestionContainer>
        <label>
          {questionText} {errorToggle && <RequiredText>* required</RequiredText>}
        </label>

        <RadioContainer>
          <RadioButton>
            <label htmlFor={`${toggleName}-yes`}>Yes</label>
            <input
              checked={toggleValue === true}
              onChange={handleInputChange}
              type="radio"
              id={`${toggleName}-yes`}
              name={toggleName}
              value={true}
            />
          </RadioButton>

          <RadioButton>
            <label htmlFor={`${toggleName}-no`}>No</label>
            <input
              checked={toggleValue === false}
              onChange={handleInputChange}
              type="radio"
              id={`${toggleName}-no`}
              name={toggleName}
              value={false}
            />
          </RadioButton>
        </RadioContainer>
      </RadioQuestionContainer>
      {toggleValue && (
        <RadioTextArea
          name={textAreaInputName}
          value={textAreaValue}
          onChange={handleInputChange}
          placeholder={textAreaPlaceholder}
          error={errorTextArea}
        />
      )}
    </>
  )
}

const RadioQuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;

  &:nth-child(even) {
    margin: 1rem 0;
  }

  label {
    font-size: 16px;
    font-family: CircularStd;
    color: rgba(57, 66, 72, 0.5);
    letter-spacing: 0.3px;
    @media (max-width: 768px) {
      max-width: 200px;
    }
  }
`

const RadioContainer = styled.div`
  display: flex;

  &:nth-child(1) {
    margin-right: 15px;
  }
`

const RadioButton = styled.div`
  &:nth-child(1) {
    margin-right: 15px;
  }
`

const RadioTextArea = styled.textarea`
  width: 100%;
  height: 90px;
  margin: 0.5rem 0;
  padding: 1rem;
  font-size: 16px;
  font-family: CircularStd;
  border-radius: 3px;

  ${props =>
    props.error &&
    `
    border 1px solid red;
  `}
`

const RequiredText = styled.span`
  color: red;
`

export default RadioQuestion

import ApolloClient from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import React, { useState, Fragment } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import merge from 'lodash/merge'
import { ApolloProvider } from 'react-apollo'

import { RouteConfig } from './routing'
import { Footer, Header } from '../common'
import stateResolvers from '../stateResolvers'
import routes from '../routes'

import { GlobalStyles } from '../common/styledComponents/'
import EmployerContext from '../lib/Context/EmployerContext'
import { SiteProvider } from '../lib/Context/SiteContext'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { useGetEmployers } from '../hooks'
import { DEFAULT_EMPLOYER_DETAILS, EMPLOYER_OVERRIDES_BY_NAME } from '../constants'
import { formatPhoneNumber } from './utils'

import LoadingOverlay from '../common/LoadingOverlay'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#43bdcf',
    },
    secondary: {
      main: '#405467',
    },
    error: {
      main: '#e1656f',
    },
  },
})

const cache = new InMemoryCache({
  addTypename: false,
})

const client = new ApolloClient({
  cache,
  clientState: { ...merge(...stateResolvers) },
})

const App = () => {
  const { data: employers } = useGetEmployers()
  const [employerName, setEmployerName] = useState(null)

  if (!employers) return <LoadingOverlay />

  return (
    <EmployerContext.Provider
      value={{
        employer: {
          ...DEFAULT_EMPLOYER_DETAILS,
          ...((employers ?? [])
            .filter(employer => employer.name === employerName)
            .map(({ customer_token, img_url, name, required_fields, email, phone_number }) => ({
              employerName: name,
              employerLogo: img_url,
              requiredFields: required_fields,
              customerToken: customer_token,
              phone: phone_number ? formatPhoneNumber(phone_number) : null,
              email,
            }))[0] ?? {}),
          ...(EMPLOYER_OVERRIDES_BY_NAME[employerName] ?? {}),
        },
        setEmployerName,
      }}
    >
      <SiteProvider>
        <ApolloProvider client={client}>
          <MuiThemeProvider theme={theme}>
            <Router>
              <Fragment>
                <GlobalStyles />
                <Header />
                <RouteConfig routes={routes} />
                <Footer />
              </Fragment>
            </Router>
          </MuiThemeProvider>
        </ApolloProvider>
      </SiteProvider>
    </EmployerContext.Provider>
  )
}

export default App

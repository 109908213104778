import React from 'react'
import ReactDOM from 'react-dom'
import { SWRConfig } from 'swr'

import App from './core'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <SWRConfig value={{ fetcher: (...args) => fetch(...args).then(res => res.json()) }}>
    <App />
  </SWRConfig>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

import React from 'react'

const EstimatedDelivery = ({ requiresPatientSurvey, currentMeds }) =>
  !requiresPatientSurvey && (
    <h5 className={currentMeds.some(med => med.patient_confirmed) ? null : 'no-current-meds'}>
      Estimated Delivery: {new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US')}
    </h5>
  )

export default EstimatedDelivery

import gql from 'graphql-tag'

const ClientGetSelectedMedicationAndCheckoutDetails = gql`
  {
    chkoutCustomerFirstName @client
    chkoutCustomerLastName @client
    chkoutCustomerEmail @client
    chkoutCustomerPhone @client

    chkoutPatientDifferentThanCustomer @client
    chkoutPatientFirstName @client
    chkoutPatientLastName @client
    chkoutPatientDob @client

    chkoutSelectedStrength @client
    chkoutSelectedQuantity @client
    chkoutPurchasePrice @client
    chkoutShippingSurcharge @client
    chkoutSelectedMedication @client {
      id
      name
      unit_price
      base_strength
      dose_unit
      strength_options
      quantity_options
      image_url
      sku
      default_quantity
    }

    chkoutPrescriptionPresent @client

    chkoutToggleAllergies @client
    chkoutPatientAllergies @client
    chkoutToggleMedications @client
    chkoutPatientMedications @client
    chkoutToggleMedicalHistory @client
    chkoutPatientMedicalHistory @client

    chkoutPharmacyName @client
    chkoutPharmacyPhone @client
    chkoutTransferAll @client
    chkoutTransferMedications @client
    chkoutPrescriberName @client
    chkoutPrescriberPhone @client
    chkoutRxAtPharmacy @client
    chkoutRxByDoctor @client

    chkoutAddressTo1 @client
    chkoutAddressTo2 @client
    chkoutAddressToCity @client
    chkoutAddressToState @client
    chkoutAddressToZip @client
    chkoutShippingMethod @client

    chkoutInsuranceIdNumber @client
    chkoutInsuranceRxBin @client
    chkoutInsuranceRxGroup @client
    chkoutInsuranceRxPCN @client
  }
`

export default ClientGetSelectedMedicationAndCheckoutDetails

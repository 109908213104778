/**
 * Config
 */

export const API_PATH = 'https://truepill-pharmacy.truepill.com'

export const CONVERGE_PATH = 'https://www.convergepay.com/hosted-payments/service/payment/hpe/process'

export const MIXPANEL_TOKEN = 'e5e86fbeef709871e11ddf2d4477fc42'

export const STRIPE_PK = 'pk_live_CJVereby5UjtIhE2VgD2bB1B'

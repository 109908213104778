import React from 'react'
import styled from 'styled-components'

const stepText = [
  { header: 'Personal Info', activeKey: 'personalInfo' },
  { header: 'Prescriptions', activeKey: 'prescriptions' },
  { header: 'Shipping and Payment', activeKey: 'shippingPayment' },
]

const StepTracker = ({ activeSteps }) => {
  return (
    <StepTrackerContainer>
      {stepText.map(({ header, activeKey }) => (
        <StepBox key={activeKey} active={activeSteps.includes(activeKey)}>
          <p>{header}</p>
        </StepBox>
      ))}
    </StepTrackerContainer>
  )
}

const StepTrackerContainer = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 0.5rem;
  margin: 2rem 0;
`

const StepBox = styled.div`
  font-weight: 700;
  text-align: center;
  width: 100%;
  border-bottom: 4px solid #9ca0a3;

  p {
    display: none;
  }

  @media (min-width: 768px) {
    padding: 1rem 0.5rem;
    background-color: #fbfbfb;

    &:first-child {
      border-radius: 10px 0px 0px 10px;
    }

    &:last-child {
      border-radius: 0px 10px 10px 0px;
    }

    p {
      font-size: 12px;
      color: #9ca0a3;
      display: block;
    }

    border-bottom: 1px solid #9ca0a3;
  }

  ${({ active }) =>
    active &&
    `
    p {
      color: #44BDD0;
    }
    border-bottom 4px solid #44BDD0;

    @media (min-width:768px) {
      border-bottom 1px solid #44BDD0;
    }
  `}
`

export default StepTracker

import {
  AdditionalInfo,
  PatientInfo,
  PrescriptionLocation,
  PharmacyInfo,
  ShippingAddressInfo,
  PaymentInfo,
  PatientPhoto,
} from '../../common'

import OrderProcessing from './OrderProcessing'

const formSections = {
  AdditionalInfo,
  PatientInfo,
  PrescriptionLocation,
  PharmacyInfo,
  ShippingAddressInfo,
  PaymentInfo,
  OrderProcessing,
  PatientPhoto,
}

const formSectionKeys = Object.keys(formSections)

export { formSections, formSectionKeys }

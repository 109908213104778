const chkoutDefaults = {
  chkoutSelectedMedication: {},
  chkoutSelectedStrength: 0,
  chkoutSelectedQuantity: 0,
  chkoutPurchasePrice: 0.0,
  chkoutShippingSurcharge: 0.0,
  chkoutShippingMethod: null,

  chkoutPatientDifferentThanCustomer: false,
  chkoutCustomerFirstName: '',
  chkoutCustomerLastName: '',
  chkoutCustomerEmail: '',
  chkoutCustomerPhone: '',

  chkoutPatientFirstName: '',
  chkoutPatientLastName: '',
  chkoutPatientDob: '',

  chkoutPrescriptionPresent: '', // REGAL-TODO - remove

  chkoutToggleAllergies: null,
  chkoutPatientAllergies: '',
  chkoutToggleMedications: null,
  chkoutPatientMedications: '',
  chkoutToggleMedicalHistory: null,
  chkoutPatientMedicalHistory: '',

  chkoutPharmacyName: '',
  chkoutPharmacyPhone: '',
  chkoutTransferAll: true,
  chkoutTransferMedications: '',
  chkoutPrescriberName: '',
  chkoutPrescriberPhone: '',
  chkoutRxAtPharmacy: null,
  chkoutRxByDoctor: null,

  chkoutAddressTo1: '',
  chkoutAddressTo2: '',
  chkoutAddressToCity: '',
  chkoutAddressToState: '',
  chkoutAddressToZip: '',

  chkoutInsuranceIdNumber: '',
  chkoutInsuranceRxBin: '',
  chkoutInsuranceRxGroup: '',
  chkoutInsuranceRxPCN: '',
}

export default {
  defaults: { ...chkoutDefaults },
  resolvers: {
    Mutation: {
      cacheSelectedMedication: (_, { medicationObj }, { cache }) => {
        cache.writeData({
          data: {
            chkoutSelectedMedication: medicationObj,
          },
        })
        return null
      },
      updateCurrentFormSection: (_, { formSectionName }, { cache }) => {
        cache.writeData({
          data: {
            chkoutCurrentSection: formSectionName,
          },
        })
        return null
      },
      saveSelectedPurchasePrice: (_, { purchasePrice }, { cache }) => {
        cache.writeData({
          data: {
            chkoutPurchasePrice: purchasePrice,
          },
        })
        return null
      },
      updateSelectedShipping: (_, { shippingMethod, surcharge }, { cache }) => {
        cache.writeData({
          data: {
            chkoutShippingMethod: shippingMethod,
            chkoutShippingSurcharge: surcharge,
          },
        })
        return null
      },
      clearCheckoutDetails: (_, args, { cache }) => {
        cache.writeData({
          data: { ...chkoutDefaults },
        })
        return null
      },
    },
  },
}

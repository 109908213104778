import React from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
import { useController } from 'react-hook-form'

const InputDob = ({ control, name }) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    defaultValue: '',
  })

  return (
    <InputMask mask="99/99/9999" placeholder="D.O.B (mm/dd/yyyy)" {...inputProps}>
      {inputProps => <StyledInput error={invalid} inputRef={ref} {...inputProps} />}
    </InputMask>
  )
}

const BaseInput = styled.input`
  padding: 1rem;
  border: 1px solid rgba(175, 188, 198, 0.55);
  font-size: 16px;
  font-family: CircularStd;
`

const StyledInput = styled(BaseInput)`
  ${({ error }) =>
    error &&
    `
    border: 1px solid red;
  `}
`

export default InputDob

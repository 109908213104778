import React from 'react'
import styled from 'styled-components'
import { Header, Spacer, Text } from '@truepill/react-capsule'

export const Container = styled.div`
  width: 100%;
  color: var(--cap-typography-dark);
  padding-bottom: 24px;
`

export const HeaderContainer = styled.div`
  background-color: var(--cap-primary-300);
  padding: 80px 0px;
  text-align: center;
`

export const ContentContainer = styled.div`
  max-width: ${props => props.isLinkedCheckout ? '37.75rem' : '62rem'};
  margin: auto;
  display: block;
  padding: 24px;
`

export const Section = styled.div`
  margin-bottom: 24px;
`

const _SectionTitle = styled(Header)`
  color: var(--cap-primary-700);
`

const _List = styled.ul`
  list-style-type: disc;
  margin-left: 36px;
`

export const IndexList = styled.ol`
  list-style-type: number;
  margin-left: 36px;
`

export const IndexHyperlink = styled.a`
  color: #13171c;
  text-decoration: underline;
`

export const Paragraph = ({ children, ...props }) => (
  <>
    <Text {...props}>{children}</Text>
    <Spacer />
  </>
)

export const SectionTitle = ({ children }) => (
  <>
    <_SectionTitle variant="3xl">{children}</_SectionTitle>
    <Spacer size="lg" />
  </>
)

export const SectionSubTitle = ({ children }) => (
  <>
    <_SectionTitle variant="2xl">{children}</_SectionTitle>
    <Spacer size="md" />
  </>
)

export const IndexLink = ({ children, ...props }) => (
  <li>
    <IndexHyperlink {...props}>
      <Text>{children}</Text>
    </IndexHyperlink>
  </li>
)

export const List = ({ children, ...props }) => (
  <_List {...props}>
    {children}
    <Spacer />
  </_List>
)

export const ListItem = ({ children }) => (
  <li>
    <Text>{children}</Text>
  </li>
)

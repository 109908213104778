import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import currency from 'currency.js'

import { SHIPPING_SPEED, SHIPPING_PRICE, SHIPPING_METHOD_NAME } from '../../constants'
import { useLinkedCheckout } from '../../state/context'
import { CreditCardIcon, CheckIcon, IconBox } from '../components/icons'
import { Header, Spacer, Text, Grid, GridItem, Card, Divider } from '@truepill/react-capsule'

function ItemText({ name, value }) {
  return (
    <>
      <ItemContainer>
        <Text>{name}</Text>
        <Text bold>{`${currency(value).format()}`}</Text>
      </ItemContainer>
      <Spacer size="2xs" axis="vertical" />
    </>
  )
}

const OrderConfirmation = () => {
  const { state } = useLinkedCheckout()
  const {
    orderInfo,
    patientConfirmation,
    patientShipping: { shippingSpeed },
    patientPayment,
    patientOrderInsurances
  } = state
  const { cardInfo } = patientPayment || {}
  const total =
    (patientConfirmation?.confirmedMeds.reduce((acc, med) => acc.add(med.copay_price), currency(0)) || currency(0)).add(SHIPPING_PRICE[shippingSpeed])
  const couponApplied = patientOrderInsurances?.length > 1

  useEffect(() => {
    ReactGA.event({
      category: 'linkedCheckout',
      action: 'Completed Order',
      label: 'completedOrder',
    })
  }, [])

  return (
    <>
      <OrderConfirmationContainer>
        <OrderCompleteHeader justifyItems="center" justifyContent="center">
          <GridItem mobile={12} desktop={12}>
            <Header bold variant="4xl">{`Thanks, ${orderInfo.firstName}!`}</Header>
          </GridItem>
          <GridItem mobile={12} desktop={12}>
            <IconBox />
          </GridItem>
          <GridItem mobile={12} desktop={12}>
            <Text bold>{`Order number: ${orderInfo.orderToken}`}</Text>
          </GridItem>
          <GridItem mobile={12} desktop={12}>
            <Text>{"Your order has been processed successfully. You'll get a confirmation email shortly."}</Text>
          </GridItem>
        </OrderCompleteHeader>
        <OrderSummaryInfo>
          <CardHeader color="none" width="auto" shadow="none">
            <Header bold variant="2xl">
              {'Order summary'}
            </Header>
          </CardHeader>
          <CardBody color="white" width="auto" shadow="xl">
            <Spacer size="md" axis="vertical" />
            <SectionHeader bold>{'Medication'}</SectionHeader>
            <Spacer size="2xs" axis="vertical" />
            <MedicationSummaryContainer>
              <MedList>
                {patientConfirmation?.confirmedMeds.map(med => (
                  <div key={med.medication_name}>
                    <ItemContainer>
                      <Text>{`${med.medication_name} - ${med.quantity} count`}</Text>
                      <Text bold>{`${currency(med.copay_price).format()}`}</Text>
                    </ItemContainer>
                    {
                      couponApplied && orderInfo.showCouponAppliedIcon && (
                      <TextItem>
                        {'Coupon applied'}&nbsp;
                        <Spacer size="xs" axis="horizontal" />
                        <CheckIcon />
                      </TextItem>
                    )}
                    <Spacer size="2xs" axis="vertical" />
                  </div>
                ))}
              </MedList>
            </MedicationSummaryContainer>
            <MedicationTotalContainer>
              <ItemContainer>
                <SectionHeader bold>{'Subtotal'}</SectionHeader>
                <Text bold>{`${currency(patientConfirmation?.total_copay).format()}`}</Text>
              </ItemContainer>
              <Spacer size="lg" axis="vertical" />
              <SectionDivider variant="mid" />
              <Spacer size="lg" axis="vertical" />
              <ItemText name="Tax" value={0} />
              <ItemText name="Shipping" value={SHIPPING_PRICE[shippingSpeed]} />
              <ItemText name="Total copay" value={patientConfirmation?.total_copay} />

              <ItemContainer>
                <Header bold>{'Order total'}</Header>
                <Text bold>{`${currency(total).format()}`}</Text>
              </ItemContainer>
              <Spacer size="lg" axis="vertical" />
            </MedicationTotalContainer>
            <SectionDivider variant="mid" />
            <Spacer size="lg" axis="vertical" />
            <ShippingInfo>
              <ItemContainer>
                <SectionHeader bold>{'Shipping method'}</SectionHeader>
                <Text bold>
                  {`${SHIPPING_METHOD_NAME[shippingSpeed]} ${SHIPPING_SPEED[shippingSpeed]}`}
                </Text>
              </ItemContainer>
              <Spacer size="lg" axis="vertical" />
            </ShippingInfo>
            <SectionDivider variant="mid" />
            <Spacer size="lg" axis="vertical" />
            <PaymentInfo>
              <ItemContainer>
              {cardInfo && (
                <SectionHeader bold>
                  <CreditCardIcon cardType={cardInfo?.brand} />
                  &nbsp;{`Ending in ${cardInfo?.last4}`}
                </SectionHeader>
                  )}
                <SectionHeader bold>{`${currency(total).format()}`}</SectionHeader>
              </ItemContainer>
            </PaymentInfo>
            <Spacer size="lg" axis="vertical" />
          </CardBody>
          <Spacer size="lg" axis="vertical" />
          <Text>
            {'For patient support, please contact us at:'}&nbsp;
            <PhoneLink href="tel:+18332696909">
              <Text bold underline as="span">
                1 (833) 269-6909
              </Text>
            </PhoneLink>
          </Text>
        </OrderSummaryInfo>
      </OrderConfirmationContainer>
    </>
  )
}
const OrderConfirmationContainer = styled.div``

const OrderCompleteHeader = styled(Grid)``

const OrderSummaryInfo = styled.div`
  margin-top: 1rem;
`

const CardHeader = styled(Card)`
  background-color: #e9ecf0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`

const CardBody = styled(Card)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`

const SectionHeader = styled(Header)``

const SectionDivider = styled(Divider)``

const MedicationSummaryContainer = styled.div``

const MedList = styled.div``

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextItem = styled(Text)`
  display: flex;
`

const MedicationTotalContainer = styled.div``

const ShippingInfo = styled.div``

const PaymentInfo = styled.div``

const PhoneLink = styled.a`
  color: var(--cap-text-body)
`;

export default OrderConfirmation

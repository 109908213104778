import Joi from 'joi-browser'

const validationSchema = {
  chkoutCustomerFirstName: Joi.string().min(1).required(),
  chkoutCustomerLastName: Joi.string().min(1).required(),
  chkoutCustomerEmail: Joi.string().email().min(1).required(),
  chkoutCustomerPhone: Joi.string().min(14).required(),
  chkoutCustomerZip: Joi.string()
    .regex(/^\d{5}(-\d{4})?$/)
    .required(),
  chkoutPatientDifferentThanCustomer: Joi.boolean().required(),
  chkoutPatientDob: Joi.string()
    .regex(/(([0])[1-9]|([1])[0-2])\/(([0])[1-9]|([1-2])[0-9]|([3][0-1]))\/((([1])[9]|([2])[0-9])[0-9][0-9])$/)
    .required(),
  chkoutPatientFirstName: Joi.string().min(1).required(),
  chkoutPatientLastName: Joi.string().min(1).required(),
  chkoutPatientSex: Joi.string().valid('male', 'female').required(),
  chkoutToggleAllergies: Joi.boolean().required(),
  chkoutToggleMedications: Joi.boolean().required(),
  chkoutToggleMedicalHistory: Joi.boolean().required(),
  chkoutPatientAllergies: Joi.string().min(1).required(),
  chkoutPatientMedications: Joi.string().min(1).required(),
  chkoutPatientMedicalHistory: Joi.string().min(1).required(),
  chkoutPharmacyName: Joi.string().min(1).required(),
  chkoutPharmacyPhone: Joi.string().min(14).required(),
  chkoutTransferAll: Joi.boolean().required(),
  chkoutTransferMedications: Joi.string().min(3).required(),
  chkoutPrescriberName: Joi.string().min(1).required(),
  chkoutPrescriberPhone: Joi.string().min(14).required(),
  chkoutAddressTo1: Joi.string().min(1).required(),
  chkoutAddressToCity: Joi.string().min(1).required(),
  chkoutAddressToState: Joi.string().min(2).required(),
  chkoutAddressToZip: Joi.string().min(5).required(),
  chkoutShippingMethod: Joi.string().min(3).required(),
  chkoutInsuranceIdNumber: Joi.string().min(1).required(), // REGAL-TODO
  chkoutInsuranceRxBin: Joi.string()
    .regex(/^[0-9]{6}$/)
    .required(), // REGAL-TODO
  chkoutInsuranceRxGroup: Joi.string().allow(''), // REGAL-TODO
  chkoutInsuranceRxPCN: Joi.string().allow(''), // REGAL-TODO
}

const validateField = (name, value) => {
  return Joi.validate(value, validationSchema[name]).error !== null
}
// const validateField = (name, value) => Joi.validate(value, validationSchema[name])

/**
 * @template T
 * @param {{ [key: string]: T }} fields
 * @returns {{ hasErrors: boolean; results: { [key: string]: boolean } }}
 */
const validateFields = fields => {
  /** @type { [key: string]: boolean } */
  const results = {}
  let hasErrors = false
  for (const key in fields) {
    const validate = validateField(key, fields[key])
    results[key] = validate
    if (validate === true) hasErrors = true
  }

  return { results, hasErrors }
}

export { validateField, validateFields }

/**
 * Given two search strings in the form `?key=value`, combine them into one.
 * @param {string} a
 * @param {string} b
 * @returns {string}
 */
export default function combineSearchStrings(a, b = '?') {
  const searchFinal = new URLSearchParams(a)
  if (b) {
    ;[...new URLSearchParams(b)].forEach(([key, value]) => searchFinal.set(key, value))
  }
  return '?' + searchFinal.toString()
}

import React, { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import { EmployerContext } from '../../lib'

import { useSite } from '../../lib/Context/SiteContext'

import './index.scss'
import Logo from '../../assets/images/truepill-footer-logo.png'

export const Footer = () => {
  const {
    employer: { email, phone, employerName },
  } = useContext(EmployerContext)
  const { siteState } = useSite()
  const { renderOldFooter } = siteState
  const mailToEmail = employerName ? `mailto:${email}?subject=${employerName}` : `mailto:${email}`
  const showJobs = false

  if (!renderOldFooter) return null

  return (
    <Fragment>
      <div className="footer__container">
        <div className="footer__main">
          <div className="footer__company-section">
            <div className="footer__logo">
              <img className="footer__logo--img" alt="Truepill" src={Logo} />
            </div>
            <div className="footer__contact-info">
              <span>
                Phone: <a href={`tel:${phone}`}>{phone}</a>
              </span>
              <span>Hours: Monday - Sunday 7AM-5PM PST</span>
              <span>3121 Diablo Ave</span>
              <span>Hayward, CA 94545</span>
              <span className="oval">•</span>
              <a href={mailToEmail}>
                <span>{email}</span>
              </a>
            </div>
            <div className="footer__copyright-notice">
              <span>NPI: 1295182590</span>
              <span>&copy; Truepill 2019</span>
              <span>All Rights Reserved.</span>
            </div>
          </div>
          <div className="footer__divider" />
          <div className="footer__support-section--container">
            <div className="footer__support-section--group">
              <h4 className="footer__support-section--header">Help</h4>
              <ul>
                <li className="footer__support-section--group-item">
                  <a href={mailToEmail}>Contact Us</a>
                </li>
                <li className="footer__support-section--group-item">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.fda.gov/Drugs/ResourcesForYou/Consumers/BuyingUsingMedicineSafely/EnsuringSafeUseofMedicine/SafeDisposalofMedicines/ucm186187.htm"
                  >
                    Disposal of Unused Medicines
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__support-section--group">
              <h4 className="footer__support-section--header">Company</h4>
              <ul>
                <li className="footer__support-section--group-item">
                  <a href="https://truepill.com/" rel="noopener noreferrer" target="_blank">
                    About
                  </a>
                </li>
                {showJobs && (
                  <li className="footer__support-section--group-item">
                    <a href="https://truepill.com/jobs.html" rel="noopener noreferrer" target="_blank">
                      Careers
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="footer__support-section--group">
              <h4 className="footer__support-section--header">Legal</h4>
              <ul>
                <li className="footer__support-section--group-item">
                  <Link to="/terms-of-use" target="_blank">
                    Terms of Use
                  </Link>
                </li>
                <li className="footer__support-section--group-item">
                  <a href="/privacy-policy" rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-mobile__container">
        <div className="mobile-footer__logo-container">
          <img className="footer__logo--img" alt="Truepill" src={Logo} />
        </div>
        <div className="footer-mobile__bottom-section">
          <div className="mobile-footer__info-container">
            <p>
              <a href={`tel:${phone}`}>{phone}</a>
            </p>
            <p>
              <a href={mailToEmail}>{email}</a>
            </p>
            <p>© Truepill 2019</p>
            <p>All Rights Reserved.</p>
          </div>
          <div className="mobile-footer__link-container">
            <div className="mobile-footer__link-section-company">
              <h4>Company</h4>
              <a href="https://www.truepill.com">About</a>
              {showJobs && <a href="https://www.truepill.com/jobs.html">Careers</a>}
              <a href={mailToEmail}>Contact</a>
            </div>
            <div className="mobile-footer__link-section-legal">
              <h4>Legal</h4>
              <Link to="/terms-of-use" target="_blank">
                Terms of Use
              </Link>
              <a href="https://truepill.com/privacy" rel="noopener noreferrer" target="_blank">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
        {false && (
          <>
            {' '}
            <span>
              <a className="footer-mobile__phone-text" href={`tel:${phone}`}>
                {phone}
              </a>
            </span>
            <span className="oval">•</span>
            <a href={`mailto:${email}`}>
              <span className="footer__support-email-text">{email}</span>
            </a>
          </>
        )}
      </div>
    </Fragment>
  )
}

const Months = [
  { value: '01', string: '01 - January' },
  { value: '02', string: '02 - February' },
  { value: '03', string: '03 - March' },
  { value: '04', string: '04 - April' },
  { value: '05', string: '05 - May' },
  { value: '06', string: '06 - June' },
  { value: '07', string: '07 - July' },
  { value: '08', string: '08 - August' },
  { value: '09', string: '09 - September' },
  { value: '10', string: '10 - October' },
  { value: '11', string: '11 - November' },
  { value: '12', string: '12  - December' },
]

const Days = []
for (let i = 1; i <= 31; i++) {
  Days.push({ value: i, string: `0${i}`.slice(-2) })
}

const CurrentDate = new Date()
const currentYear = CurrentDate.getFullYear()
const Years = []
for (let i = 0; i <= 100; i++) {
  const yearIndex = currentYear - i
  Years.push({ value: yearIndex, string: yearIndex })
}

const States = [
  { value: 'Alabama', string: 'Alabama' },
  { value: 'Alaska', string: 'Alaska' },
  { value: 'Arizona', string: 'Arizona' },
  { value: 'Arkansas', string: 'Arkansas' },
  { value: 'California', string: 'California' },
  { value: 'Colorado', string: 'Colorado' },
  { value: 'Connecticut', string: 'Connecticut' },
  { value: 'Delaware', string: 'Delaware' },
  { value: 'DC', string: 'D.C.' },
  { value: 'Florida', string: 'Florida' },
  { value: 'Georgia', string: 'Georgia' },
  { value: 'Hawaii', string: 'Hawaii' },
  { value: 'Idaho', string: 'Idaho' },
  { value: 'Illinois', string: 'Illinois' },
  { value: 'Indiana', string: 'Indiana' },
  { value: 'Iowa', string: 'Iowa' },
  { value: 'Kansas', string: 'Kansas' },
  { value: 'Kentucky', string: 'Kentucky' },
  { value: 'Louisiana', string: 'Louisiana' },
  { value: 'Maine', string: 'Maine' },
  { value: 'Maryland', string: 'Maryland' },
  { value: 'Massachusetts', string: 'Massachusetts' },
  { value: 'Michigan', string: 'Michigan' },
  { value: 'Minnesota', string: 'Minnesota' },
  { value: 'Mississippi', string: 'Mississippi' },
  { value: 'Missouri', string: 'Missouri' },
  { value: 'Montana', string: 'Montana' },
  { value: 'Nebraska', string: 'Nebraska' },
  { value: 'Nevada', string: 'Nevada' },
  { value: 'New Hampshire', string: 'New Hampshire' },
  { value: 'New Jersey', string: 'New Jersey' },
  { value: 'New Mexico', string: 'New Mexico' },
  { value: 'New York', string: 'New York' },
  { value: 'North Carolina', string: 'North Carolina' },
  { value: 'North Dakota', string: 'North Dakota' },
  { value: 'Ohio', string: 'Ohio' },
  { value: 'Oklahoma', string: 'Oklahoma' },
  { value: 'Oregon', string: 'Oregon' },
  { value: 'Pennsylvania', string: 'Pennsylvania' },
  { value: 'Rhode Island', string: 'Rhode Island' },
  { value: 'South Carolina', string: 'South Carolina' },
  { value: 'South Dakota', string: 'South Dakota' },
  { value: 'Tennessee', string: 'Tennessee' },
  { value: 'Texas', string: 'Texas' },
  { value: 'Utah', string: 'Utah' },
  { value: 'Vermont', string: 'Vermont' },
  { value: 'Virginia', string: 'Virginia' },
  { value: 'Washington', string: 'Washington' },
  { value: 'West Virginia', string: 'West Virginia' },
  { value: 'Wisconsin', string: 'Wisconsin' },
  { value: 'Wyoming ', string: 'Wyoming' },
]
const StateOptions = [
  { value: 'Alabama', label: 'Alabama' },
  { value: 'Alaska', label: 'Alaska' },
  { value: 'Arizona', label: 'Arizona' },
  { value: 'Arkansas', label: 'Arkansas' },
  { value: 'California', label: 'California' },
  { value: 'Colorado', label: 'Colorado' },
  { value: 'Connecticut', label: 'Connecticut' },
  { value: 'Delaware', label: 'Delaware' },
  { value: 'DC', label: 'D.C.' },
  { value: 'Florida', label: 'Florida' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Hawaii', label: 'Hawaii' },
  { value: 'Idaho', label: 'Idaho' },
  { value: 'Illinois', label: 'Illinois' },
  { value: 'Indiana', label: 'Indiana' },
  { value: 'Iowa', label: 'Iowa' },
  { value: 'Kansas', label: 'Kansas' },
  { value: 'Kentucky', label: 'Kentucky' },
  { value: 'Louisiana', label: 'Louisiana' },
  { value: 'Maine', label: 'Maine' },
  { value: 'Maryland', label: 'Maryland' },
  { value: 'Massachusetts', label: 'Massachusetts' },
  { value: 'Michigan', label: 'Michigan' },
  { value: 'Minnesota', label: 'Minnesota' },
  { value: 'Mississippi', label: 'Mississippi' },
  { value: 'Missouri', label: 'Missouri' },
  { value: 'Montana', label: 'Montana' },
  { value: 'Nebraska', label: 'Nebraska' },
  { value: 'Nevada', label: 'Nevada' },
  { value: 'New Hampshire', label: 'New Hampshire' },
  { value: 'New Jersey', label: 'New Jersey' },
  { value: 'New Mexico', label: 'New Mexico' },
  { value: 'New York', label: 'New York' },
  { value: 'North Carolina', label: 'North Carolina' },
  { value: 'North Dakota', label: 'North Dakota' },
  { value: 'Ohio', label: 'Ohio' },
  { value: 'Oklahoma', label: 'Oklahoma' },
  { value: 'Oregon', label: 'Oregon' },
  { value: 'Pennsylvania', label: 'Pennsylvania' },
  { value: 'Rhode Island', label: 'Rhode Island' },
  { value: 'South Carolina', label: 'South Carolina' },
  { value: 'South Dakota', label: 'South Dakota' },
  { value: 'Tennessee', label: 'Tennessee' },
  { value: 'Texas', label: 'Texas' },
  { value: 'Utah', label: 'Utah' },
  { value: 'Vermont', label: 'Vermont' },
  { value: 'Virginia', label: 'Virginia' },
  { value: 'Washington', label: 'Washington' },
  { value: 'West Virginia', label: 'West Virginia' },
  { value: 'Wisconsin', label: 'Wisconsin' },
  { value: 'Wyoming ', label: 'Wyoming' },
]

const StatesShort = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  'D.C.': 'DC',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
}

const ShippingMethods = [
  {
    string: 'USPS First Class',
    value: 'usps_first',
    shippingTimeMin: 2,
    shippingTimeMax: 5,
    surcharge: 0.0,
  },
  {
    string: 'USPS Priority (+$5)',
    value: 'usps_priority',
    shippingTimeMin: 1,
    shippingTimeMax: 3,
    surcharge: 5.0,
  },
]

const ShippingMethodOptions = [
  {
    label: 'USPS First Class',
    value: 'usps_first',
    shippingTimeMin: 2,
    shippingTimeMax: 5,
    surcharge: 0.0,
  },
  {
    label: 'USPS Priority (+$5)',
    value: 'usps_priority',
    shippingTimeMin: 1,
    shippingTimeMax: 3,
    surcharge: 5.0,
  },
]

const ExpirationMonths = [
  { value: '01', string: '01' },
  { value: '02', string: '02' },
  { value: '03', string: '03' },
  { value: '04', string: '04' },
  { value: '05', string: '05' },
  { value: '06', string: '06' },
  { value: '07', string: '07' },
  { value: '08', string: '08' },
  { value: '09', string: '09' },
  { value: '10', string: '10' },
  { value: '11', string: '11' },
  { value: '12', string: '12' },
]

const currYear = CurrentDate.getFullYear()
const endYear = CurrentDate.getFullYear() + 20

const ExpirationYears = []

for (let i = currYear; i < endYear; i++) {
  ExpirationYears.push({ value: i, string: i })
}

export {
  Days,
  Months,
  Years,
  States,
  StatesShort,
  StateOptions,
  ShippingMethods,
  ShippingMethodOptions,
  ExpirationMonths,
  ExpirationYears,
}

import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * @param {string} queryParam
 * @param {string=} defaultValue
 * @returns {string | undefined}
 */
export default function useQueryStringValue(queryParam, defaultValue = undefined) {
  const location = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const queryStringValue = queryParams.get(queryParam)

  if (queryStringValue === null || queryStringValue === '') {
    return defaultValue
  }

  return queryStringValue
}

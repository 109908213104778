import React, { createContext, useContext, useReducer } from 'react'
import { linkedCheckoutReducer, initializeState } from '../reducer'

const LinkedCheckoutContext = createContext(undefined)

export const LinkedCheckoutProvider = ({ children, orderInfo }) => {
  const [state, dispatch] = useReducer(linkedCheckoutReducer, initializeState(orderInfo))

  const value = { state, dispatch }
  return <LinkedCheckoutContext.Provider value={value}>{children}</LinkedCheckoutContext.Provider>
}

export const useLinkedCheckout = () => {
  const linkedCheckoutData = useContext(LinkedCheckoutContext)

  if (!linkedCheckoutData) {
    throw new Error('useLinkedCheckout must be used inside a LinkedCheckoutProvider')
  }

  return linkedCheckoutData
}

import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { isLoggedIn } from '../../services/authentications'

const RouteWithSubRoutes = route => {
  const { data } = route
  document.title = 'Truepill'
  if (route.name) {
    document.title = `${route.name} - Truepill`
  }
  return (
    <Route
      path={route.path}
      render={props => {
        return route.protected && !isLoggedIn() && route.path !== '/login' ? (
          <Redirect to="/login" />
        ) : (
          <route.component {...props} data={data} state={route.state} routes={route.routes} />
        )
      }}
    />
  )
}

export default RouteWithSubRoutes

import React from 'react'
import styled from 'styled-components'

import { BaseShippingPaymentContainer } from '../containers'
import { SelectInput, ShippingSpeed } from '../input'

import { StateOptions } from '../utils'
import { parseTextFieldStateForCapsule } from '../../helpers'
import { ZipcodeInput } from '../input'

import { Text, TextField, Spacer } from '@truepill/react-capsule'

// This component should be used alongside useForm in react-hook-form
const ShippingInfoCard = ({ register, control, errors, dirtyFields, setValue, controlledSubstance, watch }) => {
  return (
    <ShippingInfoCardContainer>
      <Spacer size="md" axis="vertical" />

      <TextField
        label="Name"
        state={parseTextFieldStateForCapsule(errors.name, dirtyFields.name)}
        placeholder="Full name"
        {...register('name')}
        required
      />
      <Spacer size="md" axis="vertical" />
      <TextField
        label="Street address"
        state={parseTextFieldStateForCapsule(errors.address, dirtyFields.address)}
        placeholder="Street address"
        {...register('address')}
        required
      />
      <Spacer size="md" axis="vertical" />
      <TextField
        label="Suite, apartment, building, etc"
        state={parseTextFieldStateForCapsule(errors.address2, dirtyFields.address2)}
        placeholder="Suite, apartment, building, etc"
        {...register('address2')}
      />
      <Spacer size="md" axis="vertical" />
      <ShippingInfoGrid>
        <ShippingInfoGridElement>
          <TextField
            label="City"
            state={parseTextFieldStateForCapsule(errors.city, dirtyFields.city)}
            placeholder="City"
            {...register('city')}
            required
          />
          <Spacer size="md" axis="vertical" />
        </ShippingInfoGridElement>
        <StateZipContainer>
          <ShippingInfoGridElement>
            <SelectInput
              label="State"
              placeholder="State"
              control={control}
              name="state"
              options={StateOptions}
              required
            />
            <Spacer size="md" axis="vertical" />
          </ShippingInfoGridElement>
          <ShippingInfoGridElement>
            <ZipcodeInput
              label="ZIP code"
              state={parseTextFieldStateForCapsule(errors.zip, dirtyFields.zip)}
              placeholder="12345"
              register={register}
              setValue={setValue}
              name="zip"
              required
            />
          </ShippingInfoGridElement>
        </StateZipContainer>
      </ShippingInfoGrid>
      <Spacer size="md" axis="vertical" />
      <Text style={{ fontSize: '1.1rem' }} bold>
        Method
      </Text>
      <Spacer size="md" axis="vertical" />
      <ShippingSpeed setValue={setValue} controlledSubstance={controlledSubstance} register={register} watch={watch} />
      <Spacer size="xl" axis="vertical" />
      <Text>If your medication requires refrigeration, we will ship it overnight, free of additional charge.</Text>
      <Spacer size="lg" axis="vertical" />
    </ShippingInfoCardContainer>
  )
}

const ShippingInfoCardContainer = styled(BaseShippingPaymentContainer)``
const ShippingInfoGridElement = styled.div``

const ShippingInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 6fr 6fr;
    grid-template-rows: 1fr;
    align-items: center;
    grid-column-gap: 1rem;
  }
`

const StateZipContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`

export default ShippingInfoCard

import React from 'react'
import styled from 'styled-components'

import { breakpoint } from '../../styledComponents/variables'

const TruepillMapItem = ({ data: { title, subtitle } }) => (
  <TruepillMapItemContainer>
    <h1>{title}</h1>
    <p>{subtitle}</p>
  </TruepillMapItemContainer>
)

const TruepillMapItemContainer = styled.div`
  margin: 2rem 0;
  padding: 1rem 2rem;

  h1 {
    font-size: 20px;
    text-align: left;
    margin: 0;
    padding-bottom: 1rem;
  }
  p {
    font-size: 14px;
  }

  ${breakpoint.xl} {
    h1 {
      font-size: 22px;
    }
    p {
      font-size: 16px;
    }
  }
`
export default TruepillMapItem

import React from 'react'
import styled from 'styled-components'
import BackArrow from '../../../../../assets/images/back-arrow.svg'

export const ContinueButton = styled.button`
  max-width: 500px;
  width: 100%;
  height: 52px;
  font-size: 22px;
  font-family: CircularStd;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  border-radius: 55px;
  box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.13);
  background-color: #44bdd0;
  font-weight: 400;
  letter-spacing: -0.4px;
  outline: none;
  transition: all 0.2s ease-in;
  border: none;
  cursor: pointer;

  @media (min-width: 768px) {
    font-size: 28px;
    height: 75px;
  }

  ${({ disabled }) =>
    disabled &&
    `
    background-color: #afbcc6;
  `}
`

export const BackButton = ({ onClick }) => (
  <StyledBackButton onClick={onClick}>
    <img src={BackArrow} alt="Go Back" />
  </StyledBackButton>
)

const StyledBackButton = styled.div`
  background-color: #afbcc6;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 16px;

  &:hover {
    background-color: darken(#afbcc6, 10%);
    cursor: pointer;
  }

  img {
    width: 33px;
    height: auto;
  }

  @media screen and (max-width: 767.99px) {
    width: 52px;
    height: 52px;

    img {
      border-radius: 30px;
      width: 15px;
      height: auto;
    }
  }
`

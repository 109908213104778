import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import HowTruepillWorks from './HowTruepillWorksSection/HowTruepillWorks'
import MedicationFAQs from './FAQSection/MedicationFAQs'
import Testimonials from './TestimonySection/Testimonials'
import RefillExperience from './RefillExperienceSection/RefillExperience'
import TruepillMap from './TruepillMapSection/TruepillMap'
import AboutTruepillPharmacy from './AboutTruepillSection/AboutTruepillPharmacy'
import { LandingImage } from '../images'
import { Button, ImageContainer } from '../styledComponents'
import { initGAPageView } from '../../core/utils'
import {
  LandingPageContainer,
  LandingPageHeroContainer,
  LandingPageHero,
  SizedImg,
  LandingPageHeader,
  LandingButtonContainer,
} from '../styledComponents/landingPage'
import { PBM_IDS, UNIQUE_EMPLOYER_NAMES_BY_PBM } from '../../constants'

const CovidLandingPage = props => {
  useEffect(() => {
    initGAPageView(props.match.path)
  }, [props.match.path])

  return (
    <>
      <LandingPageContainer>
        <LandingPageHeroContainer>
          <LandingPageHero>
            <LandingPageHeader>
              <h1>
                COVID-19 Home <br />
                Delivery Program
              </h1>
              <h2>Stay home. Pay less. Keep treatment.</h2>
              <LandingButtonContainer>
                <Button
                  as={Link}
                  to={`/employers/${encodeURIComponent(UNIQUE_EMPLOYER_NAMES_BY_PBM[PBM_IDS.COVID_19])}`}
                >
                  Enroll Today
                </Button>
              </LandingButtonContainer>
            </LandingPageHeader>
            <ImageContainer>
              <SizedImg src={LandingImage} alt="pbm-experience" />
            </ImageContainer>
          </LandingPageHero>
        </LandingPageHeroContainer>
        <StyledSmoothScrollLink href="#fold">
          <p>
            <i />
          </p>
        </StyledSmoothScrollLink>
      </LandingPageContainer>
      <div id={'fold'} />
      <HowTruepillWorks />
      <AboutTruepillPharmacy />
      <TruepillMap />
      <Testimonials />
      <RefillExperience />
      <MedicationFAQs />
    </>
  )
}

const StyledSmoothScrollLink = styled(AnchorLink)`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`

export default CovidLandingPage
